import React from 'react';
import { Row, Col, Input, Form, Button, message, Checkbox, Modal, Spin } from 'antd';
import { register, getIdentifyingCode, getAgreementInfo } from '@/services/api';
import { history } from '@/router/history';
import { setLocalUserInfo } from '@/utils/util';

const itemLayout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 6,
  },
};

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef(null);
    this.state = {
      mobile: '',
      bizid: '',
      count: 60, // 倒计时
      codeText: '获取验证码',
      isDisabled: false,
      contractVisible: false,
      contractData: {},
      loading: false,
      isRead: false,
    };
  }

  componentDidMount() {}

  getAgreementInfo(type) {
    this.setState({ loading: true, contractData: {} }, () => {
      getAgreementInfo({ id: type }).then(
        (res) => {
          this.setState({ contractData: res.data, loading: false });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    });
  }

  onFinish = (values) => {
    const { password, passwordRepeat } = values;
    if (password !== passwordRepeat) {
      message.destroy();
      return message.error('请输入相同密码');
    }
    if (!this.state.isRead) {
      message.destroy();
      return message.info('请勾选已阅读并同意');
    }
    const params = { ...values, bizid: this.state.bizid };
    register(params).then(
      (res) => {
        if (res.code === 200) {
          message.destroy();
          message.success(res.msg);
          this.inputRef.current.resetFields();
          const userInfo = res.data;
          // setLocalUserInfo(userInfo);
          history.push('/');
          window.location.reload();
        } else {
          message.destroy();
          return message.warning(res.msg);
        }
      },
      (error) => {
        message.destroy();
        message.error(error || '');
      }
    );
  };

  previewContractByLink() {
    const { contractData, loading } = this.state;
    return (
      <Spin spinning={loading}>
        <div dangerouslySetInnerHTML={{ __html: contractData.content }} />
      </Spin>
    );
  }

  render() {
    const { contractVisible } = this.state;
    return (
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Col span={20} style={{ paddingTop: '80px', backgroundColor: '#FFFFFF', minHeight: '500px' }}>
          <Form
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.inputRef}
            name="basic"
            initialValues={
              {
                // remember: true,
              }
            }
            autoComplete="off"
          >
            <Form.Item
              label="手机号"
              name="mobile"
              {...itemLayout}
              rules={[
                { required: true, message: '请输入11位数字' },
                { pattern: /^1\d{10}$/, message: '请输入11位数字' },
              ]}
            >
              <Input
                placeholder="请输入常用手机号"
                onChange={(e) => {
                  this.setState({ mobile: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item label="验证码" {...itemLayout}>
              <Input.Group compact>
                <Form.Item
                  name="sms_code"
                  rules={[
                    { required: true, message: '请输入验证码' },
                    { pattern: /^[0-9]{4}$/, message: '请输入4位有效数字' },
                  ]}
                >
                  <Input placeholder="请输入验证码" />
                </Form.Item>
                <Button
                  disabled={this.state.isDisabled}
                  type="primary"
                  onClick={() => {
                    const { mobile } = this.state;
                    console.log('mobile = ', mobile);
                    if (mobile) {
                      getIdentifyingCode({ mobile }).then(
                        (res) => {
                          this.setState({
                            bizid: res.data.BizId,
                          });
                          const timer = setInterval(() => {
                            this.setState(
                              {
                                count: this.state.count - 1,
                                // codeText: `倒计时${this.state.count}s`,
                                codeText: `${this.state.count}s后重新获取`,
                                isDisabled: true,
                              },
                              () => {
                                if (this.state.count === 0) {
                                  clearInterval(timer);
                                  this.setState({
                                    isDisabled: false,
                                    codeText: '重新获取',
                                    count: 60,
                                  });
                                }
                              }
                            );
                          }, 1000);
                        },
                        (error) => {
                          console.log('error = ', error);
                        }
                      );
                    } else {
                      message.error('请输入手机号');
                    }
                  }}
                >
                  {this.state.codeText}
                </Button>
              </Input.Group>
            </Form.Item>
            <Form.Item
              style={{ marginTop: '-20px' }}
              label="密码"
              name="password"
              {...itemLayout}
              rules={[
                { required: true, message: '请输入密码' },
                { max: 16, message: '' },
                { min: 8, message: '' },
				{pattern:'^(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!@#$%*--+/\\\\.])[\\da-zA-Z!@#$%*--+/\\\\.]{8,16}$', message: '密码长度8~16位且包含数字、字母、特殊符号'},
              ]}
            >
              <Input.Password placeholder="请输入密码" />
            </Form.Item>
            <Form.Item
              label="确认密码"
              name="passwordRepeat"
              {...itemLayout}
              rules={[
                { required: true, message: '请确认密码' },
                { max: 16, message: '密码最多16位' },
                { min: 8, message: '密码不能少于8位' },
              ]}
            >
              <Input.Password placeholder="请输入确认密码" />
            </Form.Item>
            <Form.Item
              {...itemLayout}
              name="isRead"
              wrapperCol={{
                offset: 9,
                span: 16,
              }}
            >
              <Checkbox
                onChange={(e) => {
                  this.setState({ isRead: e.target.checked });
                }}
              >
                已阅读并同意
                <a
                  onClick={() => {
                    this.setState({ contractVisible: true }, () => {
                      this.getAgreementInfo(6);
                    });
                  }}
                  href="#"
                >
                  《用户协议》
                </a>
                <a
                  onClick={() => {
                    this.setState({ contractVisible: true }, () => {
                      this.getAgreementInfo(3);
                    });
                  }}
                  href="#"
                >
                  《隐私协议》
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 11,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                注册
              </Button>
            </Form.Item>
          </Form>
        </Col>
        {contractVisible && (
          <Modal
            width={1200}
            bodyStyle={{ height: 800, overflow: 'auto' }}
            closable={false}
            centered
            maskClosable={true}
            onCancel={() => {
              this.setState({ contractVisible: false });
            }}
            visible={contractVisible}
            footer={null}
            title={null}
          >
            {this.previewContractByLink()}
          </Modal>
        )}
      </Row>
    );
  }
}
