import { Table, Row, Col, Button, Input, Form, Space, Modal, Breadcrumb } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { projectList } from '@/services/designPlatformApi';
import { getDesignLocalUserInfo, getDesignUserRole, enterprisesRole } from '@/utils/util';

const PAGE_LIMIT = 10;

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};
const columns = [
  {
    title: '项目编号',
    dataIndex: 'project_code',
  },
  {
    title: '项目名称',
    dataIndex: 'project_name',
  },
  {
    title: '所属企业',
    dataIndex: 'enterprises',
    render: (text) => {
      return <span>{enterprisesRole[text]}</span>;
    },
  },
  {
    title: '创建时间',
    dataIndex: 'created_time',
  },
  {
    title: '操作',
    dataIndex: '1',
    fixed: 'right',
    render: (text, item) => (
      <div>
        <Link to={`/cloudPlatform/projectDetail?id=${item.id}&project_status=${item.project_status}`}>查看</Link>
      </div>
    ),
  },
];

const ProjectList = () => {
  const ref = useRef();
  const [projectModalVisible, setProjectModalVisible] = useState(false);
  const [data, setData] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProjectList();
  }, []);

  const getProjectList = (search = '', pagination = { page: 1, limit: PAGE_LIMIT }) => {
    setLoading(true);
    const user = getDesignLocalUserInfo();
    if (user) {
      let params = {
        user_id: user.id,
        user_role: getDesignUserRole(),
        sort: 'asc',
        ...search,
        ...pagination,
      };
      params = Object.assign(search, params);
      projectList(params).then(
        (res) => {
          setData(res.data || []);
          setTotalCount(res.total_count);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  };
  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>{'项目列表'}</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        ref={ref}
        onFinish={(values) => {
          getProjectList(values);
        }}
      >
        <Row>
          <Col md={6} sm={24}>
            <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} label="关键字" name="search">
              <Input style={{ width: '100%' }} placeholder="单行输入" allowClear />
            </Form.Item>
          </Col>
          <Col md={12} sm={24}>
            <Form.Item {...formItemLayout}>
              <Space direction="horizontal" size={20}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    ref.current.resetFields();
                    getProjectList();
                  }}
                >
                  重置
                </Button>
                {/* <Button
                type="primary"
                onClick={() => {
                  setProjectModalVisible(true);
                }}
              >
                新建项目
              </Button> */}
              </Space>
            </Form.Item>
          </Col>
          <Col span={6} />
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          defaultPageSize: PAGE_LIMIT,
          total: totalCount,
          showTotal: (total) => `共 ${total} 条`,
          onChange: (curPage, count) => {
            const fieldsValue = ref.current.getFieldsValue();
            getProjectList({ search: fieldsValue.search }, { page: curPage, limit: count });
          },
        }}
      />
      <Modal
        title="新建项目"
        centered
        visible={projectModalVisible}
        onOk={() => {
          setProjectModalVisible(false);
        }}
        onCancel={() => {
          setProjectModalVisible(false);
        }}
        width={600}
        cancelText={'取消'}
        okText={'确定'}
      >
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
        >
          <Form.Item
            label="项目编号"
            name="accept"
            wrapperCol={{
              span: 20,
            }}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="项目名称"
            wrapperCol={{
              span: 20,
            }}
          >
            {' '}
            <Input></Input>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProjectList;
