import React, { useState, useCallback, useLayoutEffect } from 'react';
import { Divider, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { CustomerServiceOutlined, SendOutlined } from '@ant-design/icons';
import SocketComponent from './socketComponent';

const URL = 'wss://service.xqntech.com:8282?group=customer';//测试、预发布
// const URL = 'wss://kefu.xqntech.com:8283?group=customer';//正式
const SideBar = () => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [readyState, setReadyState] = useState('正在链接中');
  const [receiveData, setReceiveData] = useState({});

  const webSocketInit = useCallback(() => {
    const ws = new WebSocket(URL);
    if (ws) {
      ws.onopen = (e) => {
        console.log('onopen = ', e);
        setReadyState('已经连接并且可以通讯');
      };
      ws.onclose = (e) => {
        console.log('onclose = ', e);
        setReadyState('连接正在关闭');
      };
      ws.onerror = (e) => {
        console.log('onerror = ', e);
        setReadyState('连接失败');
      };
      ws.onmessage = (e) => {
        console.log('onmessage = ', e);
        setReceiveData(e);
      };
    }
  }, []);

  useLayoutEffect(() => {
    webSocketInit();
  }, []);

  return (
    <aside class="sidebar">
      <div>
        <Link
          to="/publish"
          state={{
            curNavIndex: 'publish',
          }}
        >
          <SendOutlined style={{ fontSize: '26px' }} />
          <h3>发布需求</h3>
        </Link>
      </div>
      <Divider />
      <Popover
        placement="leftBottom"
        content={
          popoverVisible && (
            <SocketComponent
              connectStatusText={readyState}
              receiveData={receiveData}
              onPopoverClose={() => {
                setPopoverVisible(false);
              }}
            />
          )
        }
        trigger="click"
        visible={popoverVisible}
      >
        <div>
          <Link
            to="#"
            onClick={() => {
              setPopoverVisible(true);
            }}
          >
            <CustomerServiceOutlined style={{ fontSize: '26px' }} />
            <h3>在线客服</h3>
          </Link>
        </div>
      </Popover>
    </aside>
  );
};

export default SideBar;
