import React from 'react';
import { Row, Col, Typography } from 'antd';
import { withRouter } from '@/router/withRouter';
import { getResourceDetail, getPlanDetail, getExampleDetail, getArticleDetail } from '@/services/api';
import { ResourceType,getUrlParams2,getUrlParams } from '@/utils/util';

const { Title } = Typography;

class ResourceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    const { id, type } =getUrlParams2().length>0?getUrlParams2():this.props.router.location.state// this.props.router.location.state;
    if (Number(type) === ResourceType.RESOURCE) {
      this.getResourceDetail(id);
    } else if (Number(type) === ResourceType.PLAN) {
      this.getPlanDetail(id);
    } else if (Number(type) === ResourceType.EXAMPLE) {
      this.getExampleDetail(id);
    } else if (Number(type) === ResourceType.ARTICLE) {
      this.getArticleDetail(id);
    }
	// const url2='/resourceDetail?id='+id+'&type='+type
	// window.location.href = url2;
  }

  getResourceDetail(id) {
    getResourceDetail({ id }).then(
      (res) => {
        this.setState({ data: res.data || [] });
      },
      (error) => {
        console.log('error');
      }
    );
  }

  getPlanDetail(id) {
    getPlanDetail({ id }).then(
      (res) => {
        this.setState({ data: res.data || [] });
      },
      (error) => {
        console.log('error');
      }
    );
  }

  getExampleDetail(id) {
    getExampleDetail({ id }).then(
      (res) => {
        this.setState({ data: res.data || [] });
      },
      (error) => {
        console.log('error');
      }
    );
  }

  getArticleDetail(id) {
    getArticleDetail({ id }).then(
      (res) => {
        this.setState({ data: res.data || [] });
      },
      (error) => {
        console.log('error');
      }
    );
  }

  getResourceDetailTemplate() {
    const { data } = this.state;
    return (
      <Typography style={{ paddingLeft: 20, paddingTop: 20 }}>
        <Title >{data.title || ''}</Title>
        <div dangerouslySetInnerHTML={{ __html: data.content }} style={{ paddingRight: 20}} />
      </Typography>
    );
  }

  getPlanDetailTemplate() {
    const { data } = this.state;
    return (
      <Typography style={{ paddingLeft: 20, paddingTop: 20 }}>
        <Title >{data.title || ''}</Title>
        <div dangerouslySetInnerHTML={{ __html: data.content }} style={{ paddingRight: 20}} />
      </Typography>
    );
  }

  getExampleDetailTemplate() {
    const { data } = this.state;
    return (
      <Typography style={{ paddingLeft: 20, paddingTop: 20 }}>
        <Title >{data.title || ''}</Title>
        <div dangerouslySetInnerHTML={{ __html: data.content }} style={{ paddingRight: 20 }} />
      </Typography>
    );
  }

  getArticleDetailTemplate() {
    const { data } = this.state;
    return (
      <Typography style={{ paddingLeft: 20, paddingTop: 20 }}>
        <Title >{data.name || ''}</Title>
        <div dangerouslySetInnerHTML={{ __html: data.content }} style={{ paddingRight: 20 }} />
      </Typography>
    );
  }

  render() {
    // const { type } = this.props.router.location.state;
	const { type } =getUrlParams2().length>0?getUrlParams2('type'):this.props.router.location.state
	const { data } = this.state;
    return (
      <Row style={{justifyContent:'center'}}>
        <Col
          span={20}
          style={{ backgroundColor: 'white',  overflow: 'auto', paddingBottom: 50 }}
        >
			<Typography style={{ paddingLeft: 20, paddingTop: 20 }}>
			  <Title >{data.title || ''}</Title>
			  <div dangerouslySetInnerHTML={{ __html: data.content }} style={{ paddingRight: 20 }} />
			</Typography>
        </Col>
      </Row>
    );
  }
}

export default withRouter(ResourceDetail);
