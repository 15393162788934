import { Modal, Form, Row, Card, Input, Button, List, Avatar, Skeleton, Comment, message, Typography } from 'antd';
import React, { useState, useLayoutEffect, useRef } from 'react';
import { problemList, addProblem, problemComment } from '@/services/designPlatformApi';
import { getUrlParams } from '@/utils/util';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';
import { getDesignLocalUserInfo, getDesignUserRole } from '@/utils/util';

const ContainerHeight = 800;
const UnExpandText = '查看全部';
const ExpandText = '收起';

const { Paragraph } = Typography;
const { TextArea } = Input;

const Problem = (props) => {
  const applyRef = useRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [applyModalVisible, setApplyModalVisible] = useState(false);
  const [parentId, setParentId] = useState();
  const [commentMap, setCommentMap] = useState(new Map());

  useLayoutEffect(() => {
    getProblemList();
  }, []);

  useLayoutEffect(() => {
    if (props.update) {
      getProblemList();
    }
  }, [props.update]);

  const getProblemList = () => {
    setLoading(true);
    const id = getUrlParams('id');
    const project_status = getUrlParams('project_status');
    const user = getDesignLocalUserInfo();
    const params = {
      id,
      project_status,
      user_role: getDesignUserRole(),
      page: 1,
      limit: 100,
    };
    problemList(params).then(
      (res) => {
        setLoading(false);
        setData(res.data || []);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const onScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === ContainerHeight) {
      // appendData();
    }
  };

  function getProjectItem(item) {
    return (
      <div>
        <Card style={{ marginBottom: 20 }}>
          <Comment author={item.problem_name || ''} content={item.problem_describe || ''} />
          <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginBottom: 10 }}>
            <a
              onClick={() => {
                if (item.expandText === ExpandText) {
                  item.expandText = UnExpandText;
                  item.loading = false;
                  item.visible = false;
                  setCommentMap(new Map(commentMap.set(item.id, [])));
                } else {
                  item.loading = true;
                  item.expandText = ExpandText;
                  problemComment({ id: item.id, page: 1, limit: 100 }).then(
                    (res) => {
                      if (res.data) {
                        item.visible = true;
                        item.loading = false;
                        setCommentMap(new Map(commentMap.set(item.id, res.data)));
                      } else {
                        item.loading = true;
                        item.visible = false;
                      }
                    },
                    (error) => {
                      item.loading = true;
                      item.visible = false;
                    }
                  );
                }
              }}
            >
              {item.expandText || UnExpandText}
            </a>
            {item.expandText === ExpandText ? (
              <UpOutlined style={{ color: '#1890FF', paddingTop: 3, paddingLeft: 3 }} />
            ) : (
              <DownOutlined style={{ color: '#1890FF', paddingTop: 3, paddingLeft: 3 }} />
            )}
          </Row>
          <Skeleton active={item.loading} loading={item.loading}>
            <Row>
              {item.visible && (
                <VirtualList data={commentMap.get(item.id)} itemHeight={100}>
                  {(e) => (
                    <List.Item key={e.id} style={{ marginLeft: 30 }}>
                      <Paragraph ellipsis={{ rows: 10 }} style={{ flexGrow: 1, width: '80rem', overflow: 'auto' }}>
                        {e.problem_describe || ''}
                      </Paragraph>
                    </List.Item>
                  )}
                </VirtualList>
              )}
            </Row>
          </Skeleton>

          <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              onClick={() => {
                setApplyModalVisible(true);
                setParentId(item.id);
              }}
            >
              回复
            </Button>
          </Row>
        </Card>
      </div>
    );
  }

  const applyQuestion = () => {
    const fieldsValue = applyRef.current.getFieldsValue();
    if (!fieldsValue.problem_describe) {
      message.destroy();
      return message.error('请输入问题描述');
    }
    const id = getUrlParams('id');
    const project_status = getUrlParams('project_status');
    const user = getDesignLocalUserInfo();
    const params = {
      ...fieldsValue,
      id,
      project_status,
      user_role: getDesignUserRole(),
      user_id: user.id,
      parent_id: parentId,
    };
    addProblem(params).then(
      (res) => {
        setApplyModalVisible(false);
        getProblemList();
      },
      (error) => {}
    );
  };

  return (
    <div>
      <List loading={loading}>
        <VirtualList data={data} height={ContainerHeight} itemHeight={200} itemKey="email" onScroll={onScroll}>
          {(item) => getProjectItem(item)}
        </VirtualList>
      </List>
      {applyModalVisible && (
        <Modal
          title="回复问题"
          destroyOnClose
          centered
          visible={applyModalVisible}
          onOk={() => {
            applyQuestion(1);
          }}
          onCancel={() => {
            setApplyModalVisible(false);
          }}
          width={600}
          cancelText={'取消'}
          okText={'确定'}
        >
          <Form ref={applyRef}>
            <Form.Item
              name="problem_describe"
              wrapperCol={{
                span: 24,
              }}
              rules={[
                { required: true, message: '请输入' },
                { max: 255, message: '最多255个字符' },
              ]}
            >
              <TextArea rows={4} placeholder="请输入问题描述" maxLength={255} />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default Problem;
