import React from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

export const history = createBrowserHistory();

/**
 * history without params
 */
export const HistoryRouter = ({ e, children }) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => {
    history.listen(setState);
  }, [e]);

  return React.createElement(Router, Object.assign({ children, navigator: history }, state));
};
