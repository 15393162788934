import React from 'react';
import { Row, Col, Table, Button, Pagination } from 'antd';
import * as echarts from 'echarts/core';
import china from '@/assets/map/china.json';
import hebei from '@/assets/map/hebei.json';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  GeoComponent,
} from 'echarts/components';
import { MapChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { getProvinceCount, getCityCount, getProductionList } from '@/services/api';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  GeoComponent,
  MapChart,
  CanvasRenderer,
]);

const columns = [
  {
    title: '地区省份',
    dataIndex: 'shortname',
    key: 'shortname',
    width: '150px',
  },
  {
    title: '产业数量',
    dataIndex: 'value',
    key: 'value',
    width: '150px',
  },
];

const cityIndustryColumns = [
  {
    title: '产业详情',
    dataIndex: 'name',
    key: 'name',
    width: '300px',
  },
];
export default class IndustryMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      level: 1, //点击地图层级 1-全国 2-省 3-市
      chartObj: {},
      countData: [],
      industryData: [],
      industryText: '',
      areaLoading: false,
      industryTotalCount: 0,
      isShowCityIndustryCount: false,
      industryLoading: false,
      pid: '',
      cityId: '',
    };
  }

  componentDidMount() {
    this.registerMap();
    this.loadProvinceData();
  }

  loadProvinceData() {
    this.setState({ areaLoading: true }, () => {
      getProvinceCount().then(
        (res) => {
          this.setState({ countData: res.data.countData || [], level: 1, areaLoading: false });
        },
        (error) => {
          this.setState({ countData: [], areaLoading: false });
        }
      );
    });
  }

  loadCityData(id) {
    this.setState({ areaLoading: true }, () => {
      getCityCount({ pid: id }).then(
        (res) => {
          this.setState({ countData: res.data.countData, level: 2, areaLoading: false });
        },
        (error) => {
          this.setState({ countData: [], level: 2, areaLoading: false });
        }
      );
    });
  }

  loadProductList(pagination = { page: 1, limit: 100 }) {
    this.setState({ isShowCityIndustryCount: true, industryLoading: true }, () => {
      const { pid, cityId } = this.state;
      const params = {
        province: pid,
        system_district_id: cityId,
        ...pagination,
      };
      getProductionList(params).then(
        (res) => {
          this.setState({
            industryData: res.data.enterprise,
            industryTotalCount: res.total_count,
            industryLoading: false,
          });
        },
        () => {
          this.setState({ industryData: [], industryLoading: false });
        }
      );
    });
  }

  loadMap(area, areaData) {
    const { chartObj } = this.state;
    echarts.registerMap(area, areaData);
    let option = {
      backgroundColor: '#fff',
      title: {
        top: 50,
        text: '产业区域分布',
        // subtext: '具体描述',
        x: 'center',
        textStyle: {
          color: '#000',
        },
      },
      geo: {
        type: 'map',
        map: area,
        roam: false,
        geoIndex: 1,
        zoom: area === 'china' ? 1.2 : 1, //地图的比例
        label: {
          normal: {
            show: true,
            textStyle: {
              color: '#000000', //字体颜色
            },
          },
          emphasis: {
            textStyle: {
              color: '#000000', //选中后的字体颜色
            },
          },
        },
        visualMap: {
          min: 800,
          max: 50000,
          text: ['High', 'Low'],
          realtime: false,
          calculable: true,
          inRange: {
            color: ['lightskyblue', 'yellow', 'orangered'],
          },
        },
        itemStyle: {
          normal: {
            areaColor: '#EEEEEE',
            borderColor: '#8b8b8b',
          },
          emphasis: {
            areaColor: '#FFD700',
          },
        },
      },
    };
    chartObj.setOption(option, true);
  }

  registerMap() {
    const chartDom = document.getElementById('main');
    const myChart = echarts.init(chartDom);
    myChart.showLoading();
    this.setState({ chartObj: myChart }, () => {
      this.loadMap('china', china);
    });
    myChart.hideLoading();
    myChart.on('click', (e) => {
      const curCity = this.state.countData.filter((item) => item.name === e.name)[0];
      switch (e.name) {
        case '河北省':
          this.loadMap('hebei', hebei);
          this.setState({ industryText: e.name });
          this.loadCityData(curCity.id);
          break;
        case '邯郸市':
          this.setState({ industryText: e.name, pid: curCity.pid, cityId: curCity.id }, () => {
            this.loadProductList();
          });
          break;
        default:
      }
    });
  }

  render() {
    const {
      countData,
      industryData,
      isShowCityIndustryCount,
      industryText,
      industryTotalCount,
      areaLoading,
      industryLoading,
      level,
    } = this.state;
    return (
      <Row style={{justifyContent:'center'}}>
        <Col span={20} style={{ backgroundColor: '#FFFFFF' }}>
          <div style={{ display: 'flex' }}>
            <div id="main" style={{ height: 1200, flex: 1 }} />
            <div>
              {level === 2 && (
                <Button
                  type="link"
                  style={{ width: 300, background: '#FAFAFA' }}
                  onClick={() => {
                    const { level } = this.state;
                    if (level === 2) {
                      //返回国家层级
                      this.loadMap('china', china);
                      this.setState({ isShowCityIndustryCount: false }, () => {
                        this.loadProvinceData();
                      });
                    }
                  }}
                >
                  返回上一层
                </Button>
              )}
              {isShowCityIndustryCount ? (
                <div>
                  <h2
                    style={{ background: '#FAFAFA', marginBottom: 0, marginTop: 0, textAlign: 'center' }}
                  >{`( ${industryText} ) 产业数量: ${industryTotalCount}`}</h2>
                  <div>
                    <Table
                      loading={industryLoading}
                      style={{ overflow: 'auto', maxHeight: 1000, paddingBottom: 10 }}
                      dataSource={industryData}
                      columns={cityIndustryColumns}
                      pagination={false}
                      bordered={false}
                    />
                    {industryTotalCount > 0 && (
                      <Pagination
                        style={{ lineHeight: 3 }}
                        defaultPageSize={100}
                        total={industryTotalCount}
                        onChange={(curPage, count) => {
                          this.loadProductList({ page: curPage, limit: count });
                        }}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <Table
                  loading={areaLoading}
                  style={{ maxHeight: 1150, overflow: 'auto' }}
                  dataSource={countData}
                  columns={columns}
                  pagination={false}
                  bordered={false}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
