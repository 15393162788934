import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Divider, message, Upload, Modal, Form } from 'antd';
import { projectFile, deleteFile, uploadApi, showFile } from '@/services/designPlatformApi';
import { UploadOutlined } from '@ant-design/icons';
import { getUrlParams, getDesignLocalUserInfo, getDesignUserRole, globalUrlPrefix } from '@/utils/util';

const status = {
  '1': '主项目',
  '2': '子项目',
};

const role = {
  '1': '项目经理',
  '2': '设计师',
};

const PAGE_LIMIT = 10;

const RelatedData = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fileVisible, setFileVisible] = useState(false);
  const [isPicture, setIsPicture] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [isPdf, setIsPdf] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = (pagination = { page: 1, limit: PAGE_LIMIT }) => {
    setLoading(true);
    const id = getUrlParams('id');
    const project_status = getUrlParams('project_status');
    const user = getDesignLocalUserInfo();
    const params = {
      id,
      user_id: user.id,
      project_status,
      user_role: getDesignUserRole(),
      ...pagination,
    };
    projectFile(params).then(
      (res) => {
        setLoading(false);
        const data = res.data || [];
        if (data && data.length > 0) {
          data.forEach((item) => {
            //此设置为了解决多选时重复id
            item.key = item.id;
          });
          setData(data);
          setTotalCount(res.total_count);
        } else {
          setLoading(false);
          setData([]);
        }
      },
      (error) => {
        setLoading(false);
        setData([]);
      }
    );
  };

  /**
   * 上报查看
   */
  const reportUpload = (item) => {
    const id = getUrlParams('id');
    const project_status = getUrlParams('project_status');
    const user = getDesignLocalUserInfo();
    const params = {
      id,
      file_id: item.id,
      user_id: user.id,
      project_status,
      role: 1,
      user_role: getDesignUserRole(),
    };
    showFile(params).then(
      (res) => {},
      (error) => {}
    );
  };

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: '文件名称',
      key: 'file_name',
      dataIndex: 'file_name',
    },
    {
      title: '项目类别',
      key: 'project_status',
      dataIndex: 'project_status',
      render: (text) => {
        return <span>{status[text]}</span>;
      },
    },
    {
      title: '成员类别',
      key: 'member_type',
      dataIndex: 'member_type',
      render: (text) => {
        return <span>{role[text]}</span>;
      },
    },
    {
      title: '上传用户',
      key: 'nickname',
      dataIndex: 'nickname',
    },
    {
      title: '创建时间',
      key: 'created_time',
      dataIndex: 'created_time',
    },
    {
      title: '操作',
      dataIndex: '1',
      fixed: 'right',
      render: (text, item) => {
        const id = getUrlParams('id');
        const project_status = getUrlParams('project_status');
        const user = getDesignLocalUserInfo();
        const downloadHref = `${globalUrlPrefix()}/workDesign/downloadFile?file_id=${String(
          item.id
        )}&id=${id}&project_status=${project_status}&role=2&user_id=${user.id}&user_role=${getDesignUserRole()}`;
		const downloadurl=`${globalUrlPrefix()}/workDesign/downloadFile`
		const params={file_id:`${String(item.id)}`,id:`${id}`,project_status:`${project_status}`,role:2,user_id:`${user.id}`,user_role:`${getDesignUserRole()}`}
        return (
          <div>
            <a
              href="#"
              onClick={() => {
                const params = {
                  id: item.id,
                  user_id: user.id,
                  user_role: getDesignUserRole(),
                };
                deleteFile(params).then(
                  (res) => {
                    message.destroy();
                    message.success(res.msg);
                    loadData();
                  },
                  (error) => {}
                );
              }}
            >
              删除
            </a>
            {Number(item.is_show) === 1 && (
              <div style={{ display: 'inline-block' }}>
                <Divider type="vertical" />
                <a
                  target="_blank"
                  onClick={() => {
                    reportUpload(item);
                    const reg = /\/.*?(gif|png|jpg|jpeg)/gi;
                    const isPic = item.file_url.match(reg);
                    const wordReg = /\/.*?(docx|doc|xls|xlsx|ppt|pptx|pdf)/gi;
                    const isFileCanRead = item.file_url.match(wordReg);
                    // console.log('item.file_url = ', item.file_url);
                    if (isPic !== null) {
                      setIsPicture(true);
                      setFileVisible(true);
                      setFileUrl(item.file_url);
                    } else {
                      setIsPicture(false);
                      if (isFileCanRead !== null) {
                        const pdfReg = /\/.*?(pdf)/gi;
                        const isPdfCanRead = item.file_url.match(pdfReg);
                        if (isPdfCanRead !== null) {
                          setIsPdf(true);
                        } else {
                          setIsPdf(false);
                        }
                        setFileVisible(true);
                        setFileUrl(item.file_url);
                      } else {
                        message.destroy();
                        return message.warning('此格式暂不支持查看，请下载');
                      }
                    }
                  }}
                >
                  查看
                </a>
              </div>
            )}
            {Number(item.is_download) === 1 && (
              <div style={{ display: 'inline-block' }}>
                <Divider type="vertical" />
                <a 
					onClick={() => {
						
					  let form = document.createElement('form');
					      form.style.display = 'none';
					      form.action = downloadurl;
					      form.method = 'POST';
					      document.body.appendChild(form);
					      // 动态创建input并给value赋值
					      for (var key in params) {
					        var input = document.createElement('input');
					        input.type = 'hidden';
					        input.name = key;
					        input.value = params[key];
					        form.appendChild(input);
					      }
					  
					      form.submit();
					      form.remove();
					}}
				 >
                  下载
                </a>
              </div>
            )}
            {fileVisible && (
              <Modal
                width={1200}
                bodyStyle={{ padding: 0, height: 780 }}
                visible={fileVisible}
                footer={null}
                closable={false}
                centered
                mask
                maskStyle={{ opacity: 0.3 }}
                maskClosable={true}
                onCancel={() => setFileVisible(false)}
              >
                {isPicture ? (
                  <img alt="example" style={{ width: '100%', minHeight: '100%', overflow: 'auto' }} src={fileUrl} />
                ) : (
                  <iframe
                    style={{
                      display: 'block',
                      height: '100%',
                      width: '100%',
                      textAlign: 'center',
                      border: 0,
                    }}
                    src={isPdf ? `${fileUrl}` : `https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`}
                  />
                )}
              </Modal>
            )}
          </div>
        );
      },
    },
  ];

  function getUploadProps() {
    const id = getUrlParams('id');
    const project_status = getUrlParams('project_status');
    const user = getDesignLocalUserInfo();
    const props = {
      name: 'file',
      action: uploadApi,
      headers: {
        'X-Requested-With': null,
        // token: getLocalUserInfo().token,
      },
      data: {
        user_id: user.id,
        user_role: getDesignUserRole(),
        id,
        project_status,
      },
      beforeUpload: (file) => {
        let isPass =
          file.type === 'image/jpg' ||
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/bmp' ||
          file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          file.type === 'application/msword' ||
          file.type === 'application/vnd.ms-powerpoint' ||
          file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
          file.type === 'application/vnd.ms-excel' ||
          file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'application/pdf';
        if (!isPass) {
          message.destroy();
          message.error('此文件格式不支持上传');
          isPass = false;
        }
        return isPass;
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {
          message.success('上传成功');
          setVisible(false);
          loadData();
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} 上传失败`);
        }
      },
    };
    return props;
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
      <Space direction="horizontal" size={20}>
        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
          }}
        >
          上传
        </Button>
        <Button
          type="primary"
          disabled={!hasSelected}
          onClick={() => {
            const user = getDesignLocalUserInfo();
            const params = {
              id: selectedRowKeys.join(','),
              user_id: user.id,
              user_role: getDesignUserRole(),
            };
            deleteFile(params).then(
              (res) => {
                message.destroy();
                message.success(res.msg);
                loadData();
              },
              (error) => {}
            );
          }}
        >
          删除
        </Button>
      </Space>
      <Table
        style={{ marginTop: '20px' }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          defaultPageSize: PAGE_LIMIT,
          total: totalCount,
          showTotal: (total) => `共 ${total} 条`,
          onChange: (curPage, count) => {
            loadData({ page: curPage, limit: count });
          },
        }}
      />
      {visible && (
        <Modal
          title="上传文件"
          centered
          visible={visible}
          onOk={() => {
            setVisible(false);
          }}
          onCancel={() => {
            setVisible(false);
          }}
          width={600}
          footer={null}
        >
          <Form>
            <Upload {...getUploadProps()}>
              <Button icon={<UploadOutlined />}>上传文件</Button>
            </Upload>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default RelatedData;
