import React from 'react';
import Footer from 'rc-footer';
import 'rc-footer/assets/index.css';
import { Row, Col } from 'antd';
import { globalUrlPrefix } from '@/utils/util';
import logoSrc from '@/assets/images/logo.png';
import iosqrcode from '@/assets/images/iosqrcode.jpg'
import { getHelpInfo } from '@/services/api';

export default class XqnFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      helpInfo: {},
    };
  }
  componentDidMount() {
    this.getHelpInfo();
  }

  getHelpInfo() {
    getHelpInfo().then(
      (res) => {
        this.setState({ helpInfo: res.data });
      },
      (error) => {}
    );
  }

  render() {
    const { helpInfo } = this.state;
    return (
      <div>
        <div>
          <Row
            style={{
              background: '#FFFFFF',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
              paddingTop: '20px',
              paddingBottom: '10px',
			  minWidth:1400
            }}
          >
            <Col span={20}>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
				  minWidth:1400
                }}
              >
                <Col span={4}>
                  <img src={logoSrc} alt="" style={{ width: '150px' }} />
                </Col>
                <Col span={16}>
                  <Row
                    gutter={60}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Col>
                      <p>{`电话: ${helpInfo.phone || ''}`}</p>
                    </Col>
                    <Col>
                      <p>{`邮箱: ${helpInfo.email || ''}`}</p>
                    </Col>
                    <Col>
                      <p>{`地址: ${helpInfo.address || ''}`}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ border: '0.2px solid #F0F0F0' }}></div>
        </div>
        <Footer
          backgroundColor="#FFFFFF"
          theme={'light'}
          maxColumnsPerRow={9}
          columns={[
            {
              title: '我是需求方',
              items: [
                {
                  title: '用户注册',
                },
                {
                  title: '发布需求',
                },
                {
                  title: '关于我们',
                },
              ],
            },
            {
              title: '我是服务商',
              items: [
                {
                  title: '设计师入驻',
                },
                {
                  title: '企业入驻',
                },
                {
                  title: '项目承接',
                },
                {
                  title: '入驻流程',
                },
              ],
            },
            {
              title: '交易保障',
              items: [
                {
                  title: '服务保障',
                },
                {
                  title: '网站协议',
                },
                {
                  title: '知识产权',
                },
              ],
            },

            {
              title: '帮助中心',
              items: [
                {
                  title: '新手指南',
                },
                {
                  title: '资料站介绍',
                },
                {
                  title: '问答介绍',
                },
                {
                  title: '教程介绍',
                },
              ],
            },
            {
              title: '平台社区',
              items: [{ title: '问问看' }, { title: '资料学习' }, { title: '教程学习' }, { title: '新闻资讯' }],
            },
            {},
            // {
            //   icon: (
            //     <div style={{ width: '160px', height: '160px', padding: '10px' }}>
            //       <img
            //         alt=""
            //         src={`${globalUrlPrefix()}/appVersion/appUrlQrcode`}
            //         style={{ width: '150px', height: '150px' }}
            //       />
            //       <p style={{ textAlign: 'center' }}>笑千年Android</p>
            //     </div>
            //   ),
            // },
            // {
            //   icon: (
            //     <div style={{ width: '160px', height: '160px', padding: '10px', marginLeft: 20 }}>
            //       <img
            //         alt=""
            //         src={iosqrcode}
            //         style={{ width: '150px', height: '150px' }}
            //       />
            //       <p style={{ textAlign: 'center' }}>笑千年IOS</p>
            //     </div>
            //   ),
            // },
          ]}
        />
        <div style={{textAlign: 'center',height:'35px','lineHeight':'35px'}}><a href='https://beian.miit.gov.cn/#/Integrated/index' style={{color:'#333',fontSize:'12px'}}>冀ICP备2021026531号-2</a></div>
      </div>
    );
  }
}
