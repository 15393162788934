import React from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Tabs,
  Input,
  Upload,
  Breadcrumb,
  Descriptions,
  Card,
  Steps,
  Space,
  Avatar,
  Alert,
  Tooltip,
  message,
  Checkbox,
  Spin,Typography
} from 'antd';
import { UploadOutlined, UserOutlined, AntDesignOutlined } from '@ant-design/icons';
import {
  getProjectDetail,
  getSubProjectList,
  getProjectTeam,
  addCollection,
  cancelCollection,
  acceptProject,
  uploadApi,
  checkUser,
  getAgreementInfo,
} from '@/services/api';
import { withRouter } from '@/router/withRouter';
import { history } from '@/router/history';
import { getLocalUserInfo, getUrlParams,formatPhone,formatName } from '@/utils/util';
import logoSrc from '@/assets/images/logo.png';
import detailBanner from '@/assets/images/detailBanner.png';
import UndertakerList from './undertakerList';
import SubProjectList from './subProjectList';

const { Step } = Steps;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Title, Paragraph, Text, Link } = Typography;
const statusText = [
  { key: '1', value: '待审核' },
  { key: '2', value: '报名中' },
  { key: '3', value: '筛选中' },
  { key: '4', value: '待签约' },
  { key: '5', value: '设计阶段' },
  { key: '6', value: '交付阶段' },
  { key: '7', value: '已完成' },
];

class AcceptDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      projectDetailInfo: {},
      subProjectInfo: [],
      subProjectCount: 0,
      subProjectLoading: false,
      projectTeamInfo: [],
      projectTeamCount: 0,
      projectTeamLoading: false,
      uploadFileInfo: [],
      collectionType: 1, // 1.添加收藏 2.取消收藏
      contractVisible: false,
      contractData: {},
      contractLoading: false,
      isRead: false,
    };
  }

  componentDidMount() {
    this.getProjectDetail();
    this.getSubProjectList();
    this.getProjectTeam();
  }

  getUploadProps = () => {
    const that = this;
    const props = {
      name: 'file',
      action: uploadApi,
      headers: {
        'X-Requested-With': null,
        token: getLocalUserInfo().token,
      },
      beforeUpload: (file) => {
        let isPass =
          file.type === 'image/jpg' ||
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/bmp' ||
          file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          file.type === 'application/msword' ||
          file.type === 'application/vnd.ms-powerpoint' ||
          file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
          file.type === 'application/vnd.ms-excel' ||
          file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'application/pdf';
        if (!isPass) {
          message.destroy();
          message.error('此文件格式不支持上传');
          isPass = false;
        }
        return isPass;
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          // console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          message.success('上传成功');
          const res = info.file.response.data || {};
          const { uploadFileInfo } = that.state;
          uploadFileInfo.push({ file_name: res.fileOriginName, file_url: res.fileUrl });
          that.setState({ uploadFileInfo: uploadFileInfo });
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} 上传失败`);
        }
      },
    };
    return props;
  };

  getProjectDetail() {
    let params = {
      project_id: getUrlParams('id'),
      project_status: getUrlParams('project_status'),
    };
    const user = getLocalUserInfo();
    if (user) {
      params = { ...params, user_id: user.id };
    }
    getProjectDetail(params).then(
      (res) => {
        console.log('res.data = ', res.data);
		if(res.data&&res.data.phone){
			res.data.phone=formatPhone(res.data.phone)
		}
		if(res.data&&res.data.owner){
			res.data.owner=formatName(res.data.owner)
		}
        this.setState({ projectDetailInfo: res.data, collectionType: res.data.is_my });
      },
      (error) => {}
    );
  }

  getSubProjectList(pagination = { page: 1, limit: 10 }) {
    this.setState({ subProjectLoading: true });
    getSubProjectList({ project_id: getUrlParams('id'), ...pagination }).then(
      (res) => {
        this.setState({ subProjectInfo: res.data, subProjectCount: res.total_count, subProjectLoading: false });
      },
      (error) => {
        this.setState({ subProjectInfo: [], subProjectLoading: false });
      }
    );
  }

  getProjectTeam(pagination = { page: 1, limit: 10 }) {
    this.setState({ projectTeamLoading: true });
    const params = {
      project_id: getUrlParams('id'),
      project_status: getUrlParams('project_status'),
      ...pagination,
    };
    getProjectTeam(params).then(
      (res) => {
        this.setState({ projectTeamInfo: res.data, projectTeamCount: res.total_count, projectTeamLoading: false });
      },
      (error) => {
        this.setState({ projectTeamInfo: [], projectTeamLoading: false });
      }
    );
  }

  /**
   * 添加或取消收藏
   */
  addOrCancelCollection(collectionType = 0) {
    console.log('collectionType aaa = ', collectionType);
    const user = getLocalUserInfo();
    if (user && user.id && user.id !== '') {
      const user = getLocalUserInfo();
      const params = {
        user_id: user.id,
        project_id: getUrlParams('id'),
        project_status: getUrlParams('project_status'),
      };
      if (Number(collectionType) === 0) {
        //未收藏过 -> 收藏
        addCollection(params).then(
          (res) => {
            if (res.code === 200) {
              message.destroy();
              message.success('收藏成功');
              this.props.router.navigate(0);
            } else {
              message.destroy();
              message.success(res.msg);
            }
          },
          (error) => {}
        );
      } else if (Number(collectionType) === 1) {
        //已收藏过 -> 取消
        cancelCollection(params).then(
          (res) => {
            if (res.code === 200) {
              message.destroy();
              message.success('取消成功');
              this.props.router.navigate(0);
            } else {
              message.destroy();
              message.success(res.msg);
            }
          },
          (error) => {}
        );
      }
    } else {
		const url=window.location.href
		window.location.href='/login?preurl='+url
		// this.props.router.navigate(`/login`, {
		//   state: { preurl: window.location.href },
		// });
      // this.props.router.navigate(`/login`);
    }
  }

  onAcceptClick = (id) => {
    //判断是否登录，如果未登录则不可承接,走登录流程
    const user = getLocalUserInfo();
    if (user && user.id && user.id !== '') {
      const params = {
        // type: 0,
        user_id: user.id,
      };
      checkUser(params).then(
        (res) => {
          if (res.data && Number(res.data.is_auth) === 1) {
            //0：未认证 1：已认证
            this.setState({ userId: user.id, visible: true });
          } else {
            message.destroy();
            message.warn('请先进行认证');
			this.props.router.navigate(`/personalCenter/verifiedAuth`);
          }
        },
        (error) => {}
      );
    } else {
		console.log(22)
		const url=window.location.href
		window.location.href='/login?preurl='+url
		// this.props.router.navigate(`/login`, {
		//   state: { preurl: window.location.href },
		// });
    }
  };

  renderDetailTitleLayout() {
    const { projectDetailInfo, collectionType } = this.state;
    console.log('collectionType = ', collectionType);
    return (
      <Row style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Col span={12}>
          <span>{projectDetailInfo.project_name}</span>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            onClick={() => {
              this.addOrCancelCollection(collectionType);
            }}
          >
            {collectionType === 0 ? '收藏' : '取消收藏'}
          </Button>
        </Col>
      </Row>
    );
  }

  getAgreementInfo(type) {
    this.setState({ contractLoading: true, contractData: {} }, () => {
      getAgreementInfo({ id: type }).then(
        (res) => {
          this.setState({ contractData: res.data, contractLoading: false });
        },
        (error) => {
          this.setState({ contractLoading: false });
        }
      );
    });
  }

  previewContractByLink() {
    const { contractData, contractLoading } = this.state;
    return (
      <Spin spinning={contractLoading}>
        {contractData && contractData.content && <div dangerouslySetInnerHTML={{ __html: contractData.content }} />}
      </Spin>
    );
  }

  render() {
    const {
      projectDetailInfo,
      subProjectInfo,
      subProjectCount,
      subProjectLoading,
      projectTeamInfo,
      projectTeamCount,
      projectTeamLoading,
      contractVisible,
    } = this.state;
    const project_status = getUrlParams('project_status');
    const disable = Number(projectDetailInfo.is_sign) === 0 && Number(projectDetailInfo.is_status) === 2;
    return (
      <div>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col span={20}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>首页</Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => {
                  history.go(-1);
                }}
              >
                项目
              </Breadcrumb.Item>
              <Breadcrumb.Item>承接详情</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col span={20}>
            <Card>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '300px', height: '300px', backgroundColor: '#D9D9D9' }}>
                  <img
                    src={projectDetailInfo.img_url}
                    alt=""
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Row style={{ height: '65px', display: 'flex', justifyContent: 'end', alignItems: 'flex-end' }}>
                    <Col>
                      <Descriptions title={this.renderDetailTitleLayout()}>
                        <Descriptions.Item label="发布时间">{projectDetailInfo.created_time}</Descriptions.Item>
                        <Descriptions.Item label="项目号">{projectDetailInfo.project_code}</Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                  <Row style={{ height: '110px', paddingTop: '40px' }}>
                    <Steps
                      current={
                        (projectDetailInfo.is_status && Number(projectDetailInfo.is_status)) > 1
                          ? projectDetailInfo.is_status - 1
                          : 1
                      }
                      size={'small'}
                    >
                      {statusText.map((item, index) => {
                        return <Step title={item.value} />;
                      })}
                    </Steps>
                  </Row>
                  <Row style={{ height: '30px', paddingTop: '30px' }}>
                    <Alert message={`项目预算: ${projectDetailInfo.ways_cooperation || ''}`} type="error" />
                  </Row>
                  <Row style={{ height: '95px', display: 'flex', placeContent: 'flex-end' }}>
                    <Col span={16}>
                      <Space direction="horizontal" size={20}>
                        <span>{`${projectDetailInfo.visits_num || 0}人浏览过`}</span>
                      </Space>
                    </Col>
                    <Col span={8} style={{ textAlign: 'end' }}>
                      <Button type="primary" onClick={this.onAcceptClick} disabled={!disable}>
                        承接项目
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Col span={20}>
            <Row gutter={20}>
              <Col span={19}>
                <Card style={{ minHeight: '820px' }}>
                  <Tabs defaultActiveKey={'1'}>
                    <TabPane tab="项目详情" key="1">
                      <Space direction="vertical">
					  <Typography >
					  	<Typography.Title level={5} >基本信息</Typography.Title>
						<div dangerouslySetInnerHTML={{__html: projectDetailInfo.project_information|| ''}}></div>
					  </Typography>

                        <Typography title="需求详情" style={{position:'relative'}}>
							 
							<Typography.Title level={5} style={{position:'relative'}}>需求详情 {projectDetailInfo.enclosure_url&&(<Link target="_blank" href={'https://view.officeapps.live.com/op/view.aspx?src='+projectDetailInfo.enclosure_url || '#'} className="blue">查看全案实施明细表</Link>)}</Typography.Title>
							
						  <div dangerouslySetInnerHTML={{__html: projectDetailInfo.project_desc|| ''}}></div>
                        </Typography>
                      </Space>
                    </TabPane>
                    {Number(project_status) === 1 && (
                      <TabPane tab="项目分布构成" key="2">
                        <SubProjectList
                          data={subProjectInfo}
                          totalCount={subProjectCount}
                          loading={subProjectLoading}
                          onPageChanged={(pagination) => {
                            this.getSubProjectList(pagination);
                          }}
                        />
                      </TabPane>
                    )}
                    <TabPane tab="承接方" key="3">
                      <UndertakerList
                        data={projectTeamInfo}
                        totalCount={projectTeamCount}
                        loading={projectTeamLoading}
                        onPageChanged={(pagination) => {
                          this.getProjectTeam(pagination);
                        }}
                      />
                    </TabPane>
                  </Tabs>
                </Card>
                {/* <Card style={{ marginTop: '20px', height: '300px' }}>
                  <Descriptions title="评论" />
                  <TextArea rows={4} placeholder="请输入您的评论" maxLength={6} />
                  <div style={{ textAlign: 'end', marginTop: '20px' }}>
                    <Button type="primary">登录并发布</Button>
                  </div>
                  <p>全部评论</p>
                </Card> */}
              </Col>
              <Col span={5}>
                <Card style={{ height: '200px', textAlign: 'center', marginBottom: '20px' }}>
                  <Space direction={'vertical'} size={20}>
                    <img src={logoSrc} alt="" width="130" style={{ objectFit: 'cover' }} />
                    <div>{(projectDetailInfo && projectDetailInfo.owner) || ''}</div>
                    <div>{(projectDetailInfo && projectDetailInfo.phone) || ''}</div>
                  </Space>
                </Card>
                <img src={detailBanner} alt="" width="100%" height="600px" style={{ objectFit: 'fill' }} />
              </Col>
            </Row>
          </Col>
        </Row>

        {this.state.visible && (
          <Modal
            title="报名承接项目"
            centered
            visible={this.state.visible}
            onOk={() => {
              this.setState({ visible: false });
            }}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            width={600}
            footer={
              <Row style={{ display: 'flex', justifyContent: 'center' }} gutter={20}>
                <Col>
                  <Button
                    onClick={() => {
                      this.setState({ visible: false });
                    }}
                  >
                    放弃机会
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      const project_status = getUrlParams('project_status');
                      const { projectDetailInfo, projectDesc, uploadFileInfo, isRead } = this.state;
                      if (!isRead) {
                        message.destroy();
                        return message.info('请勾选已阅读并同意');
                      }
                      if (!projectDesc) {
                        message.destroy();
                        return message.error('请输入擅长描述');
                      }
                      if (project_status) {
                        const user = getLocalUserInfo();
                        const params = {
                          project_id: projectDetailInfo.id,
                          project_status,
                          user_id: user.id,
                          undertake_type: 1,
                          direction: projectDesc,
                          file: JSON.stringify(uploadFileInfo),
                        };
                        acceptProject(params).then(
                          (res) => {
                            message.success('承接成功');
                            this.setState({ visible: false }, () => {
                              this.props.router.navigate(0);
                            });
                          },
                          (error) => {}
                        );
                      }
                    }}
                  >
                    立即报名
                  </Button>
                </Col>
              </Row>
            }
          >
            <Form>
              <p style={{ color: 'grey', marginBottom: '20px', fontSize: '13px' }}>
                请完善擅长描述、能力证明文件信息。报名成功后，平台项目经理会在3个工作日内联系你
              </p>
              <Form.Item
                label="擅长描述"
                name="direction"
                wrapperCol={{
                  span: 20,
                }}
                rules={[{ required: true }]}
              >
                <TextArea
                  rows={10}
                  placeholder="请输入擅长描述"
                  onChange={(e) => {
                    this.setState({ projectDesc: e.target.value });
                  }}
                />
              </Form.Item>

              <Upload {...this.getUploadProps()}>
                <Button icon={<UploadOutlined />}>上传文件</Button>
              </Upload>
              <p style={{ color: 'grey', marginTop: 10, marginBottom: 20, fontSize: '13px' }}>
                {`共${this.state.uploadFileInfo.length}个，每个文件限制200MB，项目资源文件不限于行业通用方案，类似于项目案例，投标资质文件等，上传的文件会作为项目经理选择报名的参考依据`}
              </p>
              <Form.Item
                wrapperCol={{
                  span: 16,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({ isRead: e.target.checked });
                  }}
                >
                  已阅读并同意
                  <a
                    onClick={() => {
                      this.setState({ contractVisible: true }, () => {
                        this.getAgreementInfo(9);
                      });
                    }}
                    href="#"
                  >
                    《合作协议》
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ contractVisible: true }, () => {
                        this.getAgreementInfo(4);
                      });
                    }}
                    href="#"
                  >
                    《保密协议》
                  </a>
                </Checkbox>
              </Form.Item>
            </Form>
            {contractVisible && (
              <Modal
                width={1200}
                bodyStyle={{ height: 800, overflow: 'auto' }}
                destroyOnClose
                closable={false}
                centered
                maskClosable={true}
                onCancel={() => {
                  this.setState({ contractVisible: false });
                }}
                visible={contractVisible}
                footer={null}
                title={null}
              >
                {this.previewContractByLink()}
              </Modal>
            )}
          </Modal>
        )}
      </div>
    );
  }
}

export default withRouter(AcceptDetail);
