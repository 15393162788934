import React from 'react';
import { Layout, Card, Table, Row, Col, Statistic, Space } from 'antd';
import { history } from '@/router/history';
import { Link } from 'react-router-dom';
import { projectTotal, projectList } from '@/services/designPlatformApi';
import { getDesignLocalUserInfo, getDesignUserRole, enterprisesRole } from '@/utils/util';

const PAGE_LIMIT = 10;

const columns = [
  {
    title: '项目编号',
    dataIndex: 'project_code',
  },
  {
    title: '项目名称',
    dataIndex: 'project_name',
  },

  {
    title: '所属企业',
    dataIndex: 'enterprises',
    render: (text) => {
      return <span>{enterprisesRole[text]}</span>;
    },
  },

  {
    title: '创建时间',
    dataIndex: 'created_time',
  },
  {
    title: '操作',
    dataIndex: '1',
    fixed: 'right',
    render: (text, item) => (
      <div>
        <Link to={`/cloudPlatform/projectDetail?id=${item.id}&project_status=${item.project_status}`}>查看</Link>
      </div>
    ),
  },
];
export default class DesignHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, data: {}, projectList: [] };
  }

  componentDidMount() {
    this.getHomeData();
    this.getProjectList();
  }

  getProjectList(pagination = { page: 1, limit: PAGE_LIMIT }) {
    this.setState({ loading: true });
    const user = getDesignLocalUserInfo();
    if (user && user.id) {
      let params = {
        user_id: user.id,
        user_role: getDesignUserRole(),
        sort: 'desc',
        ...pagination,
      };
      projectList(params).then(
        (res) => {
          // const targetData = res && res.data && res.data.slice(0, 10);
          this.setState({ projectList: res.data || [], loading: false });
        },
        (error) => {
          this.setState({ projectList: [], loading: false });
        }
      );
    }
  }

  getHomeData() {
    const user = getDesignLocalUserInfo();
    if (user && user.id) {
      const params = { user_id: user.id, user_role: getDesignUserRole() };
      projectTotal(params).then(
        (res) => {
          this.setState({ data: res.data });
        },
        (error) => {}
      );
    }
  }

  render() {
    const { data, projectList, loading } = this.state;
    return (
      <Layout className="layout">
        <Space direction="vertical" size={20}>
          <Card>
            <Row gutter={20}>
              <Col
                style={{ cursor: 'pointer' }}
                span={8}
                onClick={() => {
                  history.push('/cloudPlatform/projectList');
                }}
              >
                <Statistic title="项目总数" value={data.project_sum} />
              </Col>
              <Col span={8}>
                <Statistic title="进行中的项目" value={data.have_project_sum} />
              </Col>
              <Col span={8}>
                <Statistic title="已完成的项目" value={data.complete_project_num} />
              </Col>
            </Row>
          </Card>
          <Card>
            <Table dataSource={projectList} columns={columns} pagination={false} loading={loading} />
          </Card>
        </Space>
      </Layout>
    );
  }
}
