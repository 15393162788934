import './App.css';
import { history, HistoryRouter } from './router/history';
import xqnRouter from '@/router/xqnRouter';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <HistoryRouter history={history}>
      <Routes>
        {xqnRouter.map((route) => {
          return (
            <Route path={route.path} key={route.path} element={route.element}>
              {route.children.map((r) => {
                return <Route path={r.path} key={r.path} element={r.element} />;
              })}
            </Route>
          );
        })}
      </Routes>
    </HistoryRouter>
  );
}

export default App;
