import React from 'react';
import { Row, Col, Card, Typography, Pagination, List } from 'antd';
import { withRouter } from '@/router/withRouter';

const { Title, Paragraph } = Typography;
const projectStatus = { '1': '主项目', '2': '子项目' };

class ProjectList extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  getProjectItem = (item) => {
    return (
      <Card
        bodyStyle={{ borderBottom: '1px solid #F5F5F5', paddingLeft: 0 }}
        bordered={false}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          this.props.router.navigate(`/acceptDetail?id=${item.id}&project_status=${item.project_status}`, {
            state: { curNavIndex: 'accept' },
          });
        }}
      >
        <div style={{ display: 'flex' }}>
          <img src={item.img_url} alt="" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
          <div className="bbb" style={{ flex: 1, marginLeft: 20 }}>
            <Typography style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <div style={{ display: 'flex', flexShrink: 0 }}>
                <Title level={3}>{item.project_name}</Title>
                <span
                  style={{
                    marginLeft: 10,
                    backgroundColor: 'red',
                    color: 'white',
                    padding: 5,
                    height: 33,
                    borderRadius: '8px 2px 8px 2px',
                  }}
                >
                  {projectStatus[item.project_status]}
                </span>
              </div>
              <Paragraph  className='aaaa' ellipsis={{ rows: 5 }}>
                {item.introduction}
              </Paragraph>
              <Row style={{ flexShrink: 0 }}>
                <Col span={8}>
                  招募人数: <span style={{ color: '#1890FF' }}>{item.number_people || ''}</span>
                </Col>
                <Col span={8}>
                  招募金额: <span style={{ color: '#1890FF' }}>{item.ways_cooperation || ''}</span>
                </Col>
                <Col span={8}>
                  截止时间: <span style={{ color: '#1890FF' }}>{item.created_time || ''}</span>
                </Col>
              </Row>
            </Typography>
          </div>
        </div>
      </Card>
    );
  };

  render() {
    const { data, loading, totalCount } = this.props;
    return (
      <div>
        <List loading={loading} bordered={false} dataSource={data} renderItem={(item) => this.getProjectItem(item)} />
        <Pagination
          defaultPageSize={10}
          style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}
          total={totalCount === undefined ? 0 : totalCount}
          onChange={(curPage, count) => {
            this.props.onPageChanged({ page: curPage, limit: count });
          }}
        />
      </div>
    );
  }
}

// eslint-disable-next-line
export default withRouter(ProjectList);
