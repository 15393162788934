import React from 'react';
import { Row, Col, Button, List } from 'antd';
import 'moment/locale/zh-cn';
import { getLocalUserInfo } from '@/utils/util';
import {
  queryEducationList,
  queryWorkExperienceList,
  queryProjectExperienceList,
  queryPatentList,
  queryPrizeList,
  queryProjectShowList,
  queryCertificateList,
} from '@/services/api';
import EducationTemplate from '@/pages/components/educationTemplate';
import WorkExperienceTemplate from '@/pages/components/workExperienceTemplate';
import ProjectExperienceTemplate from '@/pages/components/projectExperienceTemplate';
import PatentTemplate from '@/pages/components/patentTemplate';
import PrizeTemplate from '@/pages/components/prizeTemplate';
import CertificateTemplate from '@/pages/components/certificateTemplate';
import ProjectShowTemplate from '@/pages/components/projectShowTemplate';

export default class SeniorityInfo extends React.Component {
  constructor(props) {
    super(props);
    this.educationRef = React.createRef(null);
    this.state = {
      educationList: [],
      educationLoading: true,
      workExperienceList: [],
      workExperienceLoading: true,
      projectExperienceList: [],
      projectExperienceLoading: true,
      patentList: [],
      patentLoading: true,
      prizeList: [],
      prizeLoading: true,
      certificateList: [],
      certificateLoading: true,
      projectShowList: [],
      projectShowLoading: true,
      buttonText: '编辑',
      buttonType: 1,
    };
  }

  componentDidMount() {
    this.queryEducationList();
    this.queryWorkExperienceList();
    this.queryProjectExperienceList();
    this.queryPatentList();
    this.queryPrizeList();
    this.queryProjectShowList();
    this.queryCertificateList();
  }

  queryCertificateList() {
    this.setState({ certificateLoading: true });
    const params = { user_id: getLocalUserInfo().id };
    queryCertificateList(params).then(
      (res) => {
        const list = res.data;
        if (list.length > 0) {
          list.forEach((item, index) => {
            //buttonType：1-> 新增 2-> 编辑
            item.buttonType = 2;
          });
          this.setState({ certificateList: list, certificateLoading: false });
        } else {
          this.initCertificateTemplate();
        }
      },
      (error) => {
        this.initCertificateTemplate();
      }
    );
  }

  queryProjectShowList() {
    this.setState({ projectShowLoading: true });
    const params = { user_id: getLocalUserInfo().id };
    queryProjectShowList(params).then(
      (res) => {
        const list = res.data;
        if (list.length > 0) {
          list.forEach((item, index) => {
            //buttonType：1-> 新增 2-> 编辑
            item.buttonType = 2;
          });
          this.setState({ projectShowList: list, projectShowLoading: false });
        } else {
          this.initProjectShowTemplate();
        }
      },
      (error) => {
        this.initProjectShowTemplate();
      }
    );
  }

  queryPrizeList() {
    this.setState({ prizeLoading: true });
    const params = { user_id: getLocalUserInfo().id };
    queryPrizeList(params).then(
      (res) => {
        const list = res.data;
        if (list.length > 0) {
          list.forEach((item, index) => {
            //buttonType：1-> 新增 2-> 编辑
            item.buttonType = 2;
          });
          this.setState({ prizeList: list, prizeLoading: false });
        } else {
          this.initPrizeTemplate();
        }
      },
      (error) => {
        this.initPrizeTemplate();
      }
    );
  }

  queryPatentList() {
    this.setState({ patentLoading: true });
    const params = { user_id: getLocalUserInfo().id };
    queryPatentList(params).then(
      (res) => {
        const list = res.data;
        if (list.length > 0) {
          list.forEach((item, index) => {
            //buttonType：1-> 新增 2-> 编辑
            item.buttonType = 2;
          });
          this.setState({ patentList: list, patentLoading: false });
        } else {
          this.initPatentTemplate();
        }
      },
      (error) => {
        this.initPatentTemplate();
      }
    );
  }

  queryWorkExperienceList() {
    this.setState({ workExperienceLoading: true });
    const params = { user_id: getLocalUserInfo().id };
    queryWorkExperienceList(params).then(
      (res) => {
        const list = res.data;
        if (list.length > 0) {
          list.forEach((item, index) => {
            //buttonType：1-> 新增 2-> 编辑
            item.buttonType = 2;
          });
          this.setState({ workExperienceList: list, workExperienceLoading: false });
        } else {
          this.initWorkExperienceTemplate();
        }
      },
      (error) => {
        this.initWorkExperienceTemplate();
      }
    );
  }

  initWorkExperienceTemplate() {
    const { workExperienceList } = this.state;
    workExperienceList.push({ buttonType: 1 });
    this.setState({ workExperienceList, workExperienceLoading: false });
  }

  queryEducationList() {
    this.setState({ educationLoading: true });
    const params = { user_id: getLocalUserInfo().id };
    queryEducationList(params).then(
      (res) => {
        const list = res.data;
        if (list.length > 0) {
          list.forEach((item, index) => {
            //buttonType：1-> 新增 2-> 编辑 3->保存
            item.buttonType = 2;
          });
          this.setState({ educationList: list, educationLoading: false });
        } else {
          this.initEducationTemplate();
        }
      },
      (error) => {
        this.initEducationTemplate();
      }
    );
  }

  initEducationTemplate() {
    const { educationList } = this.state;
    educationList.push({ buttonType: 1 });
    this.setState({ educationList, educationLoading: false });
  }

  queryProjectExperienceList() {
    this.setState({ projectExperienceLoading: true });
    const params = { user_id: getLocalUserInfo().id };
    queryProjectExperienceList(params).then(
      (res) => {
        const list = res.data;
        if (list.length > 0) {
          list.forEach((item, index) => {
            //buttonType：1-> 新增 2-> 编辑
            item.buttonType = 2;
          });
          this.setState({ projectExperienceList: list, projectExperienceLoading: false });
        } else {
          this.initProjectExperienceTemplate();
        }
      },
      (error) => {
        this.initProjectExperienceTemplate();
      }
    );
  }

  initProjectExperienceTemplate() {
    const { projectExperienceList } = this.state;
    projectExperienceList.push({ buttonType: 1 });
    this.setState({ projectExperienceList, projectExperienceLoading: false });
  }

  initPatentTemplate() {
    const { patentList } = this.state;
    patentList.push({ buttonType: 1 });
    this.setState({ patentList, patentLoading: false });
  }

  initCertificateTemplate() {
    const { certificateList } = this.state;
    certificateList.push({ buttonType: 1 });
    this.setState({ certificateList, certificateLoading: false });
  }

  initPrizeTemplate() {
    const { prizeList } = this.state;
    prizeList.push({ buttonType: 1 });
    this.setState({ prizeList, prizeLoading: false });
  }

  initProjectShowTemplate() {
    const { projectShowList } = this.state;
    projectShowList.push({ buttonType: 1 });
    this.setState({ projectShowList, projectShowLoading: false });
  }

  render() {
    const {
      educationList,
      educationLoading,
      workExperienceList,
      workExperienceLoading,
      projectExperienceList,
      projectExperienceLoading,
      patentList,
      patentLoading,
      prizeList,
      prizeLoading,
      projectShowList,
      projectShowLoading,
      certificateLoading,
      certificateList,
    } = this.state;
    return (
      <div style={{ height: '600px', overflowY: 'auto' }}>
        <Row type="flex" justify="space-between" style={{ marginBottom: 6 }}>
          <Col span={20}>
            <h3>教育经历</h3>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              onClick={() => {
                this.initEducationTemplate();
              }}
            >
              添加
            </Button>
          </Col>
        </Row>
        <List
          loading={educationLoading}
          dataSource={educationList}
          renderItem={(item, index) => (
            <List.Item>
              <EducationTemplate element={item} />
            </List.Item>
          )}
        />
        <Row type="flex" justify="space-between" style={{ marginBottom: 6 }}>
          <Col span={20}>
            <h3>工作经历</h3>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              onClick={() => {
                this.initWorkExperienceTemplate();
              }}
            >
              添加
            </Button>
          </Col>
        </Row>
        <List
          loading={workExperienceLoading}
          dataSource={workExperienceList}
          renderItem={(item, index) => (
            <List.Item>
              <WorkExperienceTemplate element={item} />
            </List.Item>
          )}
        />
        <Row type="flex" justify="space-between" style={{ marginBottom: 6 }}>
          <Col span={20}>
            <h3>项目经验</h3>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              onClick={() => {
                this.initProjectExperienceTemplate();
              }}
            >
              添加
            </Button>
          </Col>
        </Row>
        <List
          loading={projectExperienceLoading}
          dataSource={projectExperienceList}
          renderItem={(item, index) => (
            <List.Item>
              <ProjectExperienceTemplate element={item} />
            </List.Item>
          )}
        />
        <Row type="flex" justify="space-between" style={{ marginBottom: 6 }}>
          <Col span={20}>
            <h3>专利证书</h3>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              onClick={() => {
                this.initPatentTemplate();
              }}
            >
              添加
            </Button>
          </Col>
        </Row>
        <List
          loading={patentLoading}
          dataSource={patentList}
          renderItem={(item, index) => <List.Item>{<PatentTemplate element={item} />}</List.Item>}
        />
        <Row type="flex" justify="space-between" style={{ marginBottom: 6 }}>
          <Col span={20}>
            <h3>获奖和证书</h3>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              onClick={() => {
                this.initPrizeTemplate();
              }}
            >
              添加
            </Button>
          </Col>
        </Row>
        <List
          loading={prizeLoading}
          dataSource={prizeList}
          renderItem={(item, index) => <List.Item>{<PrizeTemplate element={item} />}</List.Item>}
        />
        <Row type="flex" justify="space-between" style={{ marginBottom: 6 }}>
          <Col span={20}>
            <h3>作品展示</h3>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              onClick={() => {
                this.initProjectShowTemplate();
              }}
            >
              添加
            </Button>
          </Col>
        </Row>
        <List
          loading={projectShowLoading}
          dataSource={projectShowList}
          renderItem={(item, index) => <List.Item>{<ProjectShowTemplate element={item} />}</List.Item>}
        />

        <Row type="flex" justify="space-between" style={{ marginBottom: 6 }}>
          <Col span={20}>
            <h3>专业证书</h3>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              onClick={() => {
                this.initCertificateTemplate();
              }}
            >
              添加
            </Button>
          </Col>
        </Row>
        <List
          loading={certificateLoading}
          dataSource={certificateList}
          renderItem={(item, index) => <List.Item>{<CertificateTemplate element={item} />}</List.Item>}
        />
      </div>
    );
  }
}
