import React from 'react';
import { Row, Col, Button, Input, Space } from 'antd';
import { Link } from 'react-router-dom';
import { withRouter } from '@/router/withRouter';
import logoSrc from '@/assets/images/logo.png';
import { history } from '@/router/history';
import { getLocalUserInfo, clearLocalUserInfo ,formatPhone} from '@/utils/util';
import Nav from './xqnNav';
import XqnTop from './xqnTop';
import './index.less';

const { Search } = Input;

class XqnHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '', //用户id
      isLogin: false, //是否登陆
      current: 'index', //当前点击的导航索引
    };
  }

  componentWillReceiveProps(nextProps) {
    const { curNavIndex } = nextProps.router.location.state || 'index';
    this.setState({ current: curNavIndex });
  }

  componentWillMount() {
    if (this.props.router.location.state) {
      const { curNavIndex } = this.props.router.location.state;
      this.setState({ current: curNavIndex || 'index' });
    }
    /**
     * 组件加载之前，判断其localStorage是否有值,如果有值，设置state中的用户名和用户id
     * 设置登陆状态为true
     * 此时显示用户名和退出按钮，即Logout组件
     */
    const user = getLocalUserInfo();
    if (user && user.id && user.id !== '') {
		var  mobile=''
		if(user.mobile){
			mobile=formatPhone(user.mobile)
		}
      this.setState({ isLogin: true, userAvatar: user.avatar, userName: user.nickname || mobile });
    }
  }

  onMenuItemClick(e) {
    this.setState({ current: e.key });
  }

  /**
   * 点击登录表单中的登录按钮,直接设置为登录状态
   */
  login(user) {
	  var mobile=''
	  if(user.mobile){
	  	mobile=formatPhone(user.mobile)
	  }
    this.setState({
      isLogin: true,
      userId: user.userId,
      userAvatar: user.avatar,
      userName: user.nickname || mobile,
    });
  }

  /**
   * 退出
   */
  logout() {
    clearLocalUserInfo();
    this.setState({ isLogin: false }, () => {
		window.location.href='/'
      // history.push('/');
      // window.location.reload();
    });
  }

  render() {
    const { current, userName, userMobile, userAvatar, isLogin } = this.state;
    return (
      <header>
        <XqnTop
          userName={userName}
          userMobile={userMobile}
          userAvatar={userAvatar}
          hasLogin={isLogin}
          logout={() => {
            this.logout();
          }}
        />
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          <Col span={20} style={{minWidth:1400}}>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Col span={10}>
                <Link to="/">
                  <img src={logoSrc} alt="" style={{ width: '150px' }} />
                </Link>
              </Col>
              <Col
                span={14}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                <Space direction={'horizontal'} size={20}>
                  <Search
                    style={{ width: '330px' }}
                    placeholder="请搜索你想要找的项目，如机器人"
                    allowClear
                    enterButton="搜索"
                    size="default"
                    onSearch={(e) => {
                      history.push(`/accept?keyWord=${e}`, { curNavIndex: 'accept' });
                      // this.props.router.navigate(`/accept`, { state: { keyWord: e, curNavIndex: 'accept' } });
                    }}
                  />
                  <Button
                    type="primary"
                    onClick={() => {
                      history.push('/publish', { curNavIndex: 'publish' });
                    }}
                  >
                    免费发布需求
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{justifyContent:'center'}}>
          <Col span={20} style={{minWidth:1400}}>
            <Nav
              logout={this.logout.bind(this)}
              userName={userName}
              userAvatar={userAvatar}
              current={current}
              menuItemClick={this.onMenuItemClick.bind(this)}
            />
          </Col>
        </Row>
      </header>
    );
  }
}

export default withRouter(XqnHeader);
