import React from 'react';
import { Row, Col, Card } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
  getLocalUserInfo,
  setDesignLocalUserInfo,
  clearDesignLocalUserInfo,
  UserRole,
  setDesignUserRole,
} from '@/utils/util';

/**
 * 云设计平台入口
 */
export default class Entrance extends React.Component {
  render() {
    return (
      <Row style={{justifyContent:'center'}}>
        <Col span={20} style={{ marginTop: 0 }}>
          <Row justify="space-between">
            <Col span={12} style={{ textAlign: 'center', cursor: 'pointer' }}>
              <Card
                style={{ height: 500, textAlign: 'center', cursor: 'pointer' }}
                bordered={false}
                onClick={() => {
                  clearDesignLocalUserInfo();
                  setDesignUserRole(UserRole.projectManager);
                  window.open(`/cloudPlatform`, '_projectManager'); //打开新窗口
                }}
              >
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #D3D3D3',
                    borderRadius: 10,
                  }}
                >
                  <div style={{ flexShrink: 0, height: 200, paddingTop: 80 }}>
                    <UserOutlined style={{ fontSize: 100, color: '#1890FF' }} />
                  </div>
                  <h1 style={{ flexGrow: 1, overflow: 'auto', height: 100, fontWeight: 'bold', color: '#1890FF' }}>
                    我是项目经理
                  </h1>
                  <a style={{ flexShrink: 0, height: 100 }}>点击进入</a>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                style={{ height: 500, cursor: 'pointer', textAlign: 'center' }}
                bordered={false}
                onClick={() => {
                  let localUser = getLocalUserInfo();
                  if (localUser) {
                    setDesignUserRole(UserRole.designer);
                    setDesignLocalUserInfo(localUser);
                    window.open(`/cloudPlatform`, '_designer'); //打开新窗口
                  } else {
                    window.open(`/login?systemType=2`, '_self');
                  }
                }}
              >
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #D3D3D3',
                    borderRadius: 10,
                  }}
                >
                  <div style={{ flexShrink: 0, height: 200, paddingTop: 80 }}>
                    <UserOutlined style={{ fontSize: 100, color: '#1890FF' }} />
                  </div>
                  <h1 style={{ flexGrow: 1, overflow: 'auto', height: 100, fontWeight: 'bold', color: '#1890FF' }}>
                    我是设计师
                  </h1>
                  <a style={{ flexShrink: 0, height: 100 }}>点击进入</a>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
