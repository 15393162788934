import { Tabs, Button, Modal, Form, Input, Breadcrumb, message } from 'antd';
import React from 'react';
import { addProblem } from '@/services/designPlatformApi';
import { withRouter } from '@/router/withRouter';
import { getUrlParams, getDesignLocalUserInfo, getDesignUserRole } from '@/utils/util';
import RelatedData from './relatedData';
import RelatedMember from './relatedMember';
import Detail from './detail';
import ProjectLog from './projectLog';
import Problem from './problem';

const { TabPane } = Tabs;
const { TextArea } = Input;

class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      questionVisible: false,
      update: [],
      activeKey: '1',
      refreshRelatedData: false,
    };
  }

  addQuestion = () => {
    const fieldsValue = this.ref.current.getFieldsValue();
    if (!fieldsValue.problem_name) {
      message.destroy();
      return message.error('请输入标题');
    }
    if (!fieldsValue.problem_describe) {
      message.destroy();
      return message.error('请输入问题描述');
    }
    const id = getUrlParams('id');
    const project_status = getUrlParams('project_status');
    const user = getDesignLocalUserInfo();
    const params = {
      ...fieldsValue,
      id,
      project_status,
      user_role: getDesignUserRole(),
      user_id: user.id,
    };
    addProblem(params).then(
      (res) => {
        this.setState({ questionVisible: false, update: true });
      },
      (error) => {}
    );
  };

  render() {
    const { questionVisible, activeKey, update } = this.state;
    return (
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>{'项目详情'}</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs
          onChange={(key) => {
            this.setState({ activeKey: key });
            console.log(key);
            if (key === '1') {
              console.log(555666);
              this.setState({ refreshRelatedData: true });
            }
          }}
          tabBarExtraContent={
            Number(activeKey) === 3 && (
              <Button
                type="primary"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.setState({ questionVisible: true });
                }}
              >
                创建问题
              </Button>
            )
          }
        >
          <TabPane tab="相关数据" key="1">
            <RelatedData />
          </TabPane>
          <TabPane tab="相关成员" key="2">
            <RelatedMember />
          </TabPane>
          <TabPane tab="项目问答" key="3">
            <Problem update={update} />
          </TabPane>
          <TabPane tab="属性" key="4">
            <Detail {...this.props} />
          </TabPane>
          <TabPane tab="日志" key="5">
            <ProjectLog />
          </TabPane>
        </Tabs>
        {questionVisible && (
          <Modal
            title="创建问题"
            destroyOnClose
            centered
            visible={questionVisible}
            onOk={() => {
              this.addQuestion();
            }}
            onCancel={() => {
              this.setState({ questionVisible: false });
            }}
            width={600}
            cancelText={'取消'}
            okText={'确定'}
          >
            <Form ref={this.ref}>
              <Form.Item
                label="问题标题"
                name="problem_name"
                wrapperCol={{
                  span: 20,
                }}
                rules={[
                  { required: true, message: '请输入' },
                  { max: 255, message: '最多255个字符' },
                ]}
              >
                <Input></Input>
              </Form.Item>
              <Form.Item
                label="问题描述"
                name="problem_describe"
                wrapperCol={{
                  span: 20,
                }}
                rules={[
                  { required: true, message: '请输入' },
                  { max: 255, message: '最多255个字符' },
                ]}
              >
                <TextArea rows={4} placeholder="请输入问题描述" maxLength={255} />
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}

export default withRouter(ProjectDetail);
