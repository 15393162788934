import React from 'react';
import { Button, Input, message, Select, Form, Row, Col, Card, DatePicker, Space } from 'antd';
import { patentAdd, patentEdit, patentDelete } from '@/services/api';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { getLocalUserInfo, setPersonalCenterTabIndexAndFresh } from '@/utils/util';
import UploadComponent from '@/pages/components/upload';

const { Option } = Select;
const { TextArea } = Input;

export default class PatentTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      isDisabled: Number(props.element.buttonType) === 1 ? false : true,
      buttonText: Number(props.element.buttonType) === 1 ? '保存' : '编辑',
      buttonType: props.element.buttonType || 2,
    };
  }

  componentDidMount() {
    const item = this.props.element;
    if (item) {
      this.ref.current.setFieldsValue({
        name: item.name || '',
        certificate_number: item.certificate_number || '',
        sort: item.sort || '',
        remark: item.remark || '',
        patent_number: item.patent_number || '',
        time:
          item.buttonType && Number(item.buttonType === 1)
            ? null
            : item.start_date
            ? moment(item.start_date, 'YYYY-MM-DD')
            : null,
        type: String(item.type || 0),
      });
    }
  }

  render() {
    const { isDisabled, buttonType, buttonText } = this.state;
    return (
      <Form
        style={{ width: '100%' }}
        ref={this.ref}
        onFinish={(values) => {
          const user = getLocalUserInfo();
          const start_date = moment(values.time).format('YYYY-MM-DD');
          delete values.time;
          const { images, type } = this.state;
          let params = { ...values, user_id: user.id, start_date, images, type };
          // console.log('params = ', params);
          if (buttonText === '保存') {
            if (Number(buttonType) === 2) {
              //编辑
              params = Object.assign({ id: this.props.element.id }, params); //注assign的source(即params)无需展开
              patentEdit(params).then(
                (res) => {
                  message.success('保存成功');
                  setPersonalCenterTabIndexAndFresh('52');
                },
                (error) => {}
              );
            } else if (Number(buttonType) === 1) {
              //新增
              patentAdd(params).then(
                (res) => {
                  message.success('保存成功');
                  setPersonalCenterTabIndexAndFresh('52');
                },
                (error) => {}
              );
            }
          }
          this.setState({ buttonText: '保存', isDisabled: false });
        }}
      >
        <Row type="flex" justify="space-between">
          <Col span={22}>
            <Card style={{ marginBottom: '20px' }}>
              <Row gutter={24} style={{ marginRight: '16px' }}>
                <Col span={20}>
                  <Form.Item
                    label="专利类型"
                    name="type"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请选择专业类型' }]}
                  >
                    <Select
                      disabled={isDisabled}
                      showSearch
                      placeholder="请选择专利类型"
                      onChange={(e) => {
                        this.setState({ type: e });
                      }}
                    >
                      <Option value="0">发明</Option>
                      <Option value="1">实用新型</Option>
                      <Option value="2">外观</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="日期"
                    name="time"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请选择日期' }]}
                  >
                    <DatePicker locale={locale} disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="专利发明"
                    name="name"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请输入' }]}
                  >
                    <Input placeholder="请输入专利发明" disabled={isDisabled} />
                  </Form.Item>

                  <Form.Item
                    label="专利号"
                    name="patent_number"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请输入' }]}
                  >
                    <Input placeholder="请输入专利号" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="位次"
                    name="sort"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请输入' }]}
                  >
                    <Input placeholder="请输入位次" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="证书号"
                    name="certificate_number"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请输入' }]}
                  >
                    <Input placeholder="请输入证书号" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="备注"
                    name="remark"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <TextArea rows={4} placeholder="请输入备注信息" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="证书图片"
                    name="images"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                  >
                    <UploadComponent
                      disabled={isDisabled}
                      picUrl={this.props.element.images}
                      callback={(uploadUrl) => {
                        this.setState({ images: uploadUrl });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={2}>
            <Space direction="vertical" size={15} style={{ marginTop: 65, marginLeft: 15 }}>
              <Button type="primary" htmlType="submit">
                {this.state.buttonText}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  patentDelete({ id: this.props.element.id }).then(
                    (res) => {
                      message.success('删除成功');
                      setPersonalCenterTabIndexAndFresh('52');
                    },
                    (error) => {}
                  );
                }}
              >
                删除
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
