import React from 'react';
import { Table } from 'antd';
import { myPublishList } from '@/services/api';
import { getLocalUserInfo } from '@/utils/util';

const PAGE_LIMIT = 10;
const columns = [
  {
    title: '提交日期',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '需求内容',
    dataIndex: 'content',
    key: 'content',
  },
];

export default class MyPublish extends React.Component {
  constructor(props) {
    super(props);
    this.state = { list: [], totalCount: 0, loading: false };
  }

  componentDidMount() {
    this.getMyPublishList();
  }

  getMyPublishList(pagination = { page: 1, limit: PAGE_LIMIT }) {
    this.setState({ loading: true });
    const user = getLocalUserInfo();
    const params = {
      user_id: user.id,
      ...pagination,
    };
    myPublishList(params).then(
      (res) => {
        this.setState({ list: res.data, totalCount: res.total_count, loading: false });
      },
      (error) => {
        this.setState({ list: [], loading: false });
      }
    );
  }

  render() {
    const { list, loading, totalCount } = this.state;
    return (
      <Table
        dataSource={list || []}
        columns={columns}
        bordered={true}
        loading={loading}
        locale={{ emptyText: list.length === 0 && '暂无数据' }}
        pagination={{
          defaultPageSize: PAGE_LIMIT,
          total: totalCount,
          showTotal: (total) => `共 ${total} 条`,
          onChange: (curPage, count) => {
            this.getMyPublishList({ page: curPage, limit: count });
          },
        }}
      />
    );
  }
}
