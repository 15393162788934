import React from 'react';
import { Row, Col, Card, Pagination, List } from 'antd';
import { withRouter } from '@/router/withRouter';
import { getExampleList } from '@/services/api';
import { ResourceType, getUrlParams } from '@/utils/util';

const PAGE_LIMIT = 24;

class ResourceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      totalCount: 0,
      loading: false,
    };
  }

  componentWillReceiveProps() {
    //切换不同菜单时需要先清空下state保存的data数据
    this.setState({ data: [] }, () => {
      this.getExampleList();
    });
  }

  componentDidMount() {
    this.getExampleList();
  }

  getExampleList(pagination = { page: 1, limit: PAGE_LIMIT }) {
    this.setState({ loading: true });
    const params = {
      case_category: getUrlParams('id'),
      ...pagination,
    };
    getExampleList(params).then(
      (res) => {
        this.setState({ data: res.data, totalCount: res.total_count, loading: false });
      },
      (error) => {
        this.setState({ data: [], loading: false });
      }
    );
  }

  /**
   * 列表形式
   */
  // getProjectItem = (item) => {
  //   return (
  //     <Card
  //       bodyStyle={{ borderBottom: '1px solid #F5F5F5' }}
  //       bordered={false}
  //       style={{ cursor: 'pointer' }}
  //       onClick={() => {
  //         this.props.router.navigate(`/resourceDetail`, {
  //           state: { id: item.id, type: ResourceType.EXAMPLE, curNavIndex: 'projectCase' },
  //         });
  //       }}
  //     >
  //       <div style={{ display: 'flex' }}>
  //         <img src={item.img} alt="" style={{ width: '240px', height: '150px', objectFit: 'cover' }} />
  //         <div style={{ flex: 1, marginLeft: 20 }}>
  //           <Typography style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
  //             <Title level={3} style={{ flexShrink: 0 }}>
  //               {item.title}
  //             </Title>
  //             <Paragraph style={{ flexGrow: 1 }}>{item.description}</Paragraph>
  //             <div style={{ flexShrink: 0 }}>
  //               {item.tag_list.length > 0 &&
  //                 item.tag_list.map((item, index) => {
  //                   return (
  //                     <Tag color="magenta" style={{ flexShrink: 0 }}>
  //                       {item.name}
  //                     </Tag>
  //                   );
  //                 })}
  //             </div>
  //           </Typography>
  //         </div>
  //       </div>
  //     </Card>
  //   );
  // };

  renderCover(item) {
    return (
      <img
        style={{
          width: '100%',
          height: '230px',
          objectFit: 'fill',
        }}
        src={item.img}
        onClick={() => {
			// window.location.href='/resourceDetail?id='+item.id+'&type='+ResourceType.EXAMPLE+'&curNavIndex=projectCase'
			// this.props.router.navigate(`/resourceDetail`, {
			//   state: { id: item.id, type: ResourceType.EXAMPLE, curNavIndex: 'projectCase' },
			// });
			this.props.router.navigate(`/resourceDetail?id=${item.id}&type=${ResourceType.EXAMPLE}&curNavIndex=projectCase`, {
			  state: { id: item.id, type: ResourceType.EXAMPLE, curNavIndex: 'projectCase' },
			});
          // this.props.router.navigate(`/resourceDetail`, {
          //   state: { id: item.id, type: ResourceType.EXAMPLE, curNavIndex: 'projectCase' },
          // });
        }}
      />
    );
  }

  render() {
    const { data, totalCount, loading } = this.state;
    return (
      <Row style={{justifyContent:'center'}}>
        
        <Col span={20}>
          <List
            dataSource={data}
            loading={loading}
            grid={{ gutter: 30, column: 4 }}
            style={{ minHeight: 600, backgroundColor: 'white', padding: 25 }}
            renderItem={(item) => (
              <List.Item>
                <Card hoverable bordered={false} bodyStyle={{ borderRadius: 50 }} cover={this.renderCover(item)}>
                  {item.title}
                </Card>
              </List.Item>
            )}
            // renderItem={(item) => this.getProjectItem(item)}
          >
            {totalCount > PAGE_LIMIT && (
              <Pagination
                defaultPageSize={PAGE_LIMIT}
                style={{ marginTop: 20, paddingBottom: 20, textAlign: 'center' }}
                total={totalCount}
                onChange={(curPage, count) => {
                  this.getExampleList({ page: curPage, limit: count });
                }}
              />
            )}
          </List>
        </Col>
      </Row>
    );
  }
}

export default withRouter(ResourceList);
