import React from 'react';
import { Layout, Menu, Modal, Row, Col, Form, Input, Button, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import {
  getLocalUserInfo,
  setDesignLocalUserInfo,
  getDesignLocalUserInfo,
  clearDesignLocalUserInfo,
  UserRole,
  getDesignUserRole,
  setDesignUserRole,
} from '@/utils/util';
import { login } from '@/services/designPlatformApi';
import logoSrc from '@/assets/images/managerLogin.png';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

export default class DesignPlatform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModalVisible: false,
      userName: '',
    };
  }

  componentDidMount() {
    this.initBrowserTitle();
    const localUserRole = getDesignUserRole();
    if (Number(localUserRole) === UserRole.projectManager) {
      if (getDesignLocalUserInfo() === null) {
        this.setState({ loginModalVisible: true }, () => {
          clearDesignLocalUserInfo();
        });
      } else {
        const user = getDesignLocalUserInfo();
        this.setState({ userName: user.username });
      }
    } else if (Number(localUserRole) === UserRole.designer) {
      const user = getDesignLocalUserInfo();
      this.setState({ userName: user.username }, () => {
        setDesignUserRole(UserRole.designer);
        setDesignLocalUserInfo(getLocalUserInfo());
      });
    }
  }

  initBrowserTitle() {
    document.title = '工作设计平台';
    const links = document.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      const temp = link.rel.toLowerCase();
      if (temp.indexOf('icon') > -1) {
        link.href = logoSrc;
        break;
      }
    }
  }

  onFinish = (values) => {
    login({ ...values }).then(
      (res) => {
        if (res.code === 200) {
          message.destroy();
          message.success(res.msg);
          this.setState({ loginModalVisible: false, userName: res.data.username }, () => {
            setDesignUserRole(UserRole.projectManager);
            setDesignLocalUserInfo(res.data);
            window.open(`/cloudPlatform`, '_self');
          });
        } else {
          message.destroy();
          message.warning(res.msg);
        }
      },
      (error) => {}
    );
  };

  render() {
    const { loginModalVisible, userName } = this.state;

    return (
      <Layout>
        {loginModalVisible && (
          <Modal
            style={{ marginTop: '12%' }}
            visible={loginModalVisible}
            footer={null}
            closable={false}
            maskClosable={false}
            width={800}
            onCancel={() => {
              this.setState({ loginModalVisible: false });
            }}
          >
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Col span={12}>
                <img src={logoSrc} alt="" style={{ width: '90%' }} />
              </Col>
              <Col span={12} style={{ paddingTop: '50px', backgroundColor: '#FFFFFF', height: '300px' }}>
                <Form
                  onFinish={this.onFinish}
                  initialValues={{
                    remember: true,
                  }}
                  autoComplete="off"
                >
                  <h2 style={{ textAlign: 'center' }}>项目经理</h2>
                  <Form.Item
                    label="帐号"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: '请输入帐号',
                      },
                    ]}
                  >
                    <Input placeholder="请输入帐号" />
                  </Form.Item>
                  <Form.Item
                    label="密码"
                    name="password"
                    rules={[
                      { required: true, message: '请输入密码' },
                      { max: 50, message: '密码最多50位' },
                      { min: 6, message: '密码不能少于6位' },
                    ]}
                  >
                    <Input.Password placeholder="请输入密码" />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 11,
                      span: 16,
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      登录
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>
        )}

        <Header style={{ background: '#1890FF' }}>
          <div>
            {/* <img
              src={logoSrc}
              alt=""
              style={{
                float: 'left',
                width: '120px',
                height: '31px',
                margin: '16px 24px 16px 0',
                cursor: 'pointer',
              }}
              onClick={() => {
                history.push('/');
              }}
            /> */}
            <div
              style={{ float: 'right', width: '400px', textAlign: 'right', cursor: 'pointer' }}
              onClick={() => {
                const uerRole = getDesignUserRole();
                if (Number(uerRole) === UserRole.projectManager) {
                  clearDesignLocalUserInfo();
                  window.close();
                } else if (Number(uerRole) === UserRole.designer) {
                  window.close();
                }
              }}
            >
              <span style={{ color: 'white', fontSize: '18px', fontWeight: 'bold', marginRight: 10 }}>{userName}</span>
              <Button style={{ width: 80, borderRadius: 50, backgroundColor: '#0470E4', border: 'none' }}>
                <span style={{ fontSize: '15px', color: 'white', paddingBottom: 20, fontWeight: 'bold' }}>退出</span>
              </Button>
            </div>
          </div>
        </Header>
        <Layout>
          <Sider width={200} className="site-layout-background">
            <Menu mode="inline" defaultOpenKeys={['10']} style={{ height: '100%', borderRight: 0 }}>
              <SubMenu key="10" icon={<UserOutlined />} title="工作设计平台">
                <Menu.Item key="11">
                  <Link to="/cloudPlatform">首页</Link>
                </Menu.Item>
                <Menu.Item key="12">
                  <Link to={`/cloudPlatform/projectList`}>项目列表</Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Layout style={{ padding: '0 24px 24px' }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 2,
                margin: 0,
                minHeight: '800px',
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}
