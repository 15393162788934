import XqnRoot from '@/containers/app-layout/xqnRoot';
import XqnHome from '@/pages/home/home';
import ResourceDetail from '@/pages/resource/detail';
import ResourceList from '@/pages/resource/list';
import CloudEntrance from '@/pages/cloudEntrance/entrance';
import Login from '@/pages/login/login';
import Register from '@/pages/register/register';
import Publish from '@/pages/publish/publish';
import Accept from '@/pages/accept/accept';
import AcceptDetail from '@/pages/accept/acceptDetail';
import IndustryMap from '@/pages/map/industryMap';
import AboutUs from '@/pages/about/aboutUs';

/**
 * 个人中心
 */
import XqnPersonalCenter from '@/containers/app-layout/xqnPersonalCenter';
import VerifiedAuth from '@/pages/personalCenter/verifiedAuth';
import CompanyAuth from '@/pages/personalCenter/companyAuth';
import PersonalAuth from '@/pages/personalCenter/personalAuth';
import Settled from '@/pages/personalCenter/settled';
import DesignerAuth from '@/pages/personalCenter/designerEnter';
import ServiceProviderAuth from '@/pages/personalCenter/serviceProviderEnter';
import BasePersonInfo from '@/pages/personalCenter/basePersonalInfo';
import SeniorityInfo from '@/pages/personalCenter/seniorityInfo';
import MyProject from '@/pages/personalCenter/myProject';
import ParticipateProject from '@/pages/personalCenter/participateProject';
import AttentionProject from '@/pages/personalCenter/attentionProject';
import MyPublish from '@/pages/personalCenter/myPublish';
import MyContract from '@/pages/personalCenter/myContract';
import WorkPlatform from '@/pages/personalCenter/workPlatform';
import AccountSecurity from '@/pages/personalCenter/accountSecurity';
/**
 * 工业平台
 */
import DesignPlatform from '@/containers/app-layout/designPlatform';
import DesignHome from '@/pages/designPlatform/designHome/index.js';
import DesignProjectList from '@/pages/designPlatform/designList/projectList';
import DesignProjectDetail from '@/pages/designPlatform/designDetail/projectDetail';
import RelatedMembers from '@/pages/designPlatform/relatedMembers/index.js';

export default [
  {
    path: '/',
    element: <XqnRoot />,
    children: [
      {
        path: '/',
        element: <XqnHome />,
      },
      {
        path: '/cloudEntrance',
        element: <CloudEntrance />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/register',
        element: <Register />,
      },
      {
        path: '/publish',
        element: <Publish />,
      },
      {
        path: '/accept',
        element: <Accept />,
      },
      {
        path: '/acceptDetail',
        element: <AcceptDetail />,
      },
      {
        path: '/resourceDetail',
        element: <ResourceDetail />,
      },
      {
        path: '/resourceList',
        element: <ResourceList />,
      },
      {
        path: '/industryMap',
        element: <IndustryMap />,
      },
      {
        path: '/aboutUs',
        element: <AboutUs />,
      },
    ],
  },
  {
    path: '/personalCenter',
    element: <XqnPersonalCenter />,
    children: [
      {
        path: '/personalCenter',
        element: <WorkPlatform />,
      },
      {
        path: '/personalCenter/verifiedAuth',
        element: <VerifiedAuth />,
      },
      {
        path: '/personalCenter/companyAuth',
        element: <CompanyAuth />,
      },
      {
        path: '/personalCenter/personalAuth',
        element: <PersonalAuth />,
      },
      {
        path: '/personalCenter/settled',
        element: <Settled />,
      },
      {
        path: '/personalCenter/designerAuth',
        element: <DesignerAuth />,
      },
      {
        path: '/personalCenter/serviceProviderAuth',
        element: <ServiceProviderAuth />,
      },
      {
        path: '/personalCenter/basePersonalInfo',
        element: <BasePersonInfo />,
      },
      {
        path: '/personalCenter/seniorityInfo',
        element: <SeniorityInfo />,
      },
      {
        path: '/personalCenter/myProject',
        element: <MyProject />,
      },
      {
        path: '/personalCenter/participate',
        element: <ParticipateProject />,
      },
      {
        path: '/personalCenter/attention',
        element: <AttentionProject />,
      },
      {
        path: '/personalCenter/myPublish',
        element: <MyPublish />,
      },
      {
        path: '/personalCenter/myContract',
        element: <MyContract />,
      },
      {
        path: '/personalCenter/accountSecurity',
        element: <AccountSecurity />,
      },
    ],
  },
  {
    path: '/cloudPlatform',
    element: <DesignPlatform />,
    children: [
      {
        path: '/cloudPlatform',
        element: <DesignHome />,
      },
      {
        path: '/cloudPlatform/projectList',
        element: <DesignProjectList />,
      },
      {
        path: '/cloudPlatform/projectDetail',
        element: <DesignProjectDetail />,
      },
      {
        path: '/cloudPlatform/relatedMembers',
        element: <RelatedMembers />,
      },
    ],
  },
];
