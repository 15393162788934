import React from 'react';
import { Row, Col, Card, Form, Button, Input, Checkbox, Spin, Modal, message } from 'antd';
import { authentication, getAgreementInfo } from '@/services/api';
import { getLocalUserInfo } from '@/utils/util';
import UploadComponent from '@/pages/components/upload';
import SmsCodeComponent from '@/pages/components/smsCode';
import { history } from '@/router/history';

const formItemLayout = {
  style: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 14,
  },
};
export default class PersonalAuth extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      loading: false,
      mobile: '',
      bizid: '',
      smsCode: '',
      IDcard_front: '',
      IDcard_back: '',
      user_front: '',
      user_back: '',
      contractVisible: false,
      contractData: {},
      contractLoading: false,
      isRead: false,
    };
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  onFinish = (values) => {
    const { isRead, smsCode, bizid, IDcard_front, IDcard_back, user_front, user_back } = this.state;
    if (!isRead) {
      message.destroy();
      return message.info('请勾选已阅读并同意');
    }
    if (!smsCode) {
      message.destroy();
      return message.info('请输入验证码');
    }
    const user = getLocalUserInfo();
    const params = {
      ...values,
      user_id: user.id,
      bizid,
      sms_code: smsCode,
      IDcard_front,
      IDcard_back,
      user_front,
      user_back,
    };
    authentication(params).then(
      (res) => {
        if (res.code === 200) {
          this.ref.current.resetFields();
          message.destroy();
          message.success(res.msg);
          history.push('/personalCenter/verifiedAuth');
        } else {
          message.destroy();
          message.warning(res.msg);
        }
      },
      (error) => {}
    );
  };

  getAgreementInfo(type) {
    this.setState({ contractLoading: true, contractData: {} }, () => {
      getAgreementInfo({ id: type }).then(
        (res) => {
          this.setState({ contractData: res.data, contractLoading: false });
        },
        (error) => {
          this.setState({ contractLoading: false });
        }
      );
    });
  }

  previewContractByLink() {
    const { contractData, contractLoading } = this.state;
    return (
      <Spin spinning={contractLoading}>
        {contractData && contractData.content && <div dangerouslySetInnerHTML={{ __html: contractData.content }} />}
      </Spin>
    );
  }

  render() {
    const { contractVisible } = this.state;
    return (
      <Card>
        <Form onFinish={this.onFinish} ref={this.ref}>
          <Row gutter={24} style={{ marginRight: '16px' }}>
            <Col span={20} className="cancle">
              <Form.Item
                label="姓名"
                name="name"
                {...formItemLayout}
                rules={[
                  {
                    required: true,
                    message: '请输入16位以内的中文字符',
                  },
                  { max: 16, message: '最多16个字符' },
                ]}
              >
                <Input placeholder="请输入10位以内的中文字符" />
              </Form.Item>
              <Form.Item
                label="身份证号"
                name="idnumber"
                {...formItemLayout}
                rules={[
                  {
                    required: true,
                    message: '请输入您的身份证号',
                  },
                  { max: 20, message: '最多20个字符' },
                ]}
              >
                <Input placeholder="请输入您的身份证号" />
              </Form.Item>
              <Form.Item
                label="手机号"
                name="mobile"
                {...formItemLayout}
                rules={[
                  { required: true, message: '请输入11手机号' },
                  { pattern: /^1\d{10}$/, message: '手机号格式错误！' },
                ]}
              >
                <Input
                  placeholder="请输入手机号"
                  onChange={(e) => {
                    this.setState({ mobile: e.target.value });
                  }}
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label="验证码"
                name="sms_code"
				required
                rules={[{ message: '请输入验证码' }, { pattern: /^[0-9]{4}$/, message: '请输入4位有效数字' }]}
              >
                <SmsCodeComponent
                  mobile={this.state.mobile}
                  onSmsChanged={(val) => {
                    this.setState({
                      smsCode: val,
                    });
                  }}
                  callback={(res) => {
                    this.setState({
                      bizid: res,
                    });
                  }}
                />
              </Form.Item>
              <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Col span={4} />
                <Col span={11}>
                  <Form.Item label="身份证正面" name="IDcard_front" required >
                    <UploadComponent
                      callback={(uploadUrl) => {
                        this.setState({ IDcard_front: uploadUrl });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item label="身份证反面" name="IDcard_back" required>
                    <UploadComponent
                      callback={(uploadUrl) => {
                        this.setState({ IDcard_back: uploadUrl });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='margina' style={{ display: 'flex', justifyContent: 'center' }}>
                <Form.Item>
                  <Checkbox
                    onChange={(e) => {
                      this.setState({ isRead: e.target.checked });
                    }}
                  >
                    已阅读并同意
                    <a
                      onClick={() => {
                        this.setState({ contractVisible: true }, () => {
                          this.getAgreementInfo(5);
                        });
                      }}
                      href="#"
                    >
                      《用户实名认证协议》
                    </a>
                  </Checkbox>
                </Form.Item>
              </Row>
              <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  style={{ marginRight: 20 }}
                  onClick={() => {
                    history.go(-1);
                  }}
                >
                  返回上一步
                </Button>
                <Button type="primary" htmlType={'submit'}>
                  提交审核
                </Button>
              </Row>
            </Col>
            {contractVisible && (
              <Modal
                width={1200}
                bodyStyle={{ height: 800, overflow: 'auto' }}
                closable={false}
                centered
                maskClosable={true}
                onCancel={() => {
                  this.setState({ contractVisible: false });
                }}
                visible={contractVisible}
                footer={null}
                title={null}
              >
                {this.previewContractByLink()}
              </Modal>
            )}
          </Row>
        </Form>
      </Card>
    );
  }
}
