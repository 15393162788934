/**
 * 网络请求配置
 */
import axios from 'axios';
import { getLocalUserInfo, getDesignLocalUserInfo, globalUrlPrefix } from '@/utils/util';
import { history } from '@/router/history';

axios.defaults.timeout = 100000;
axios.defaults.baseURL = globalUrlPrefix();

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config) => {
    config.data = JSON.stringify(config.data);
    //登录或注册请求时不需要携带token
    if (
      config.url.endsWith('login') ||
      config.url.endsWith('register') ||
      config.url.includes('/projectInfo/index') ||
      config.url.includes('/projectInfo/info') ||
      config.url.includes('/banner/index') ||
      config.url.includes('/resource/list') ||
      config.url.includes('/plan/list') ||
      config.url.includes('/article/list') ||
      config.url.includes('/resource/info') ||
      config.url.includes('/plan/info') ||
      config.url.includes('/example/list') ||
      config.url.includes('/example/info') ||
      config.url.includes('/article/info') ||
      config.url.includes('/projectClassification/index') ||
      config.url.includes('/projectCategory/index') ||
      config.url.includes('/user/getIdentifyingCode') ||
      config.url.includes('/example/categoryList') ||
      config.url.includes('/adminUser/onLineServer') ||
      config.url.includes('/chatRecord/sendToService') ||
      config.url.includes('/singlePage/info') ||
      config.url.includes('/systemDistrict/getProvinceCount') ||
      config.url.includes('/systemDistrict/getCount') ||
      config.url.includes('/xqnEnterprise/list') ||
      config.url.includes('/helpCenter/info') ||
      config.url.includes('/agreement/info') ||
	  config.url.includes('/need/add') ||
	  config.url.includes('/projectInfo/selectSonList') ||
	  config.url.includes('/projectInfo/selectTeam')
    ) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    } else {
      const localUser = getDesignLocalUserInfo();
      if (localUser && localUser.token) {
        config.headers = {
          'Content-Type': 'application/json',
          token: localUser.token,
        };
      } else {
        config.headers = {
          'Content-Type': 'application/json',
          token: getLocalUserInfo().token || '',
        };
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  (response) => {
    if (response.data.errCode === 2) {
      console.log('过期');
    }
    return response;
  },
  (error) => {
    console.log('请求出错：', error);
  }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        msg(err);
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        msg(err);
        reject(err);
      }
    );
  });
}

/**
 * 统一接口处理，返回数据
 */
export default function request(fetch, url, param) {
  // let _data = '';
  return new Promise((resolve, reject) => {
    switch (fetch) {
      case 'get':
        // console.log('begin a get request,and url:', url);
        get(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            // console.log('get request GET failed.', error);
            reject(error);
          });
        break;
      case 'post':
        post(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log('get request POST failed.', error);
            reject(error);
          });
        break;
      default:
        break;
    }
  });
}

/**
 * 失败提示
 */
function msg(err) {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        alert(err.response.data.error.details);
        break;
      case 401:
        // 401代表token失效，直接重新登录
        history.push(`/login`);
        break;
      case 403:
        alert('拒绝访问');
        break;
      case 404:
        alert('请求地址出错');
        break;
      case 408:
        alert('请求超时');
        break;
      case 500:
        alert('服务器内部错误');
        break;
      case 501:
        alert('服务未实现');
        break;
      case 502:
        alert('网关错误');
        break;

      case 503:
        alert('服务不可用');
        break;
      case 504:
        alert('网关超时');
        break;
      case 505:
        alert('HTTP版本不受支持');
        break;
      default:
    }
  }
}
