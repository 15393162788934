import React from 'react';
import { Tabs, Avatar, Space, Row, Col } from 'antd';
import { getLocalUserInfo } from '@/utils/util';
import { myProject, collectionList, queryUserInfo } from '@/services/api';
import MyProjectTable from '@/pages/components/myProjectTable';
import ParticipateProjectTable from '@/pages/components/participateProjectTable';
import AttentionProjectTable from '@/pages/components/attentionTable';

const { TabPane } = Tabs;

export default class WorkPlatform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectLoading: false,
      participateLoading: false,
      attentionLoading: false,
      list: [],
      participateList: [],
      attentionList: [],
      userAvatar: '',
    };
  }

  componentDidMount() {
    this.queryUserInfo();
    this.getMyProjectList();
    this.getParticipateList();
    this.getAttentionList();
  }

  queryUserInfo() {
    const user = getLocalUserInfo();
    const params = { id: user.id };
    queryUserInfo(params).then(
      (res) => {
        const userInfo = res.data;
        if (userInfo) {
          this.setState({ userAvatar: userInfo.avatar || '' });
        }
      },
      (error) => {}
    );
  }

  getAttentionList() {
    const user = getLocalUserInfo();
    const params = {
      user_id: user.id,
      page: 1,
      limit: 10,
    };
    collectionList(params).then(
      (res) => {
        this.setState({ attentionList: res.data.my_collection });
      },
      (error) => {
        this.setState({ attentionList: [] });
      }
    );
  }

  getParticipateList() {
    const user = getLocalUserInfo();
    const params = {
      user_id: user.id,
      examine_status: 2,
      page: 1,
      limit: 10,
    };
    myProject(params).then(
      (res) => {
        this.setState({ participateList: res.data.my_project });
      },
      (error) => {
        this.setState({ participateList: [] });
      }
    );
  }

  getMyProjectList() {
    this.setState({ projectLoading: true });
    const user = getLocalUserInfo();
    const params = {
      user_id: user.id,
      examine_status: 1,
      page: 1,
      limit: 10,
    };
    myProject(params).then(
      (res) => {
        this.setState({ list: res.data.my_project, projectLoading: false });
      },
      (error) => {
        this.setState({ list: [], projectLoading: false });
      }
    );
  }

  render() {
    const { list, participateList, attentionList, projectLoading, participateLoading, attentionLoading, userAvatar } =
      this.state;
    const user = getLocalUserInfo();
    return (
      <div>
        <Row>
          <Col span={12}>
            <Space direction="horizontal" size={20} style={{ marginBottom: '20px', marginTop: '20px' }}>
              <Avatar size={70} src={userAvatar || ''} />
              <span>{user.nickname || ''}</span>
            </Space>
          </Col>
          {/* <Col span={12}>
            <Space direction="vertical" size={20} style={{ marginTop: '10px', width: '100%' }}>
              <span>我的合同</span>
              <Row gutter={20}>
                <Col span={12}>
                  <Card>待签约(0)</Card>
                </Col>
                <Col span={12}>
                  <Card>已签约(0)</Card>
                </Col>
              </Row>
            </Space>
          </Col> */}
        </Row>
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="我参与的项目" key="1">
            <ParticipateProjectTable
              dataSource={participateList}
              loading={participateLoading}
              pagination={false}
              maxHeight={480}
            />
          </TabPane>
          <TabPane tab="我报名的项目" key="2">
            <MyProjectTable dataSource={list} loading={projectLoading} pagination={false} maxHeight={480} />
          </TabPane>
          <TabPane tab="我收藏的项目" key="3">
            <AttentionProjectTable
              dataSource={attentionList}
              loading={attentionLoading}
              pagination={false}
              maxHeight={480}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
