import http from '@/utils/request';
import { globalUrlPrefix } from '@/utils/util';

/**
 * 项目经理手机号、密码登录
 */
function login(params) {
  return new Promise((resolve, reject) => {
    http('post', '/adminUser/login', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~', error);
        reject(error);
      }
    );
  });
}

/**
 * 项目统计
 */
function projectTotal(params) {
  return new Promise((resolve, reject) => {
    http('get', '/workDesign/projectNum', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 项目列表
 */
function projectList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/workDesign/index', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 相关数据
 */
function projectFile(params) {
  return new Promise((resolve, reject) => {
    http('get', '/workDesign/projectFile', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 删除文件
 */
function deleteFile(params) {
  return new Promise((resolve, reject) => {
    http('get', '/workDesign/deleteFile', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 上传文件
 */
function uploadFile(params) {
  return new Promise((resolve, reject) => {
    http('post', '/workDesign/uploadFile', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~', error);
        reject(error);
      }
    );
  });
}

/**
 * 相关成员
 */
function projectTeam(params) {
  return new Promise((resolve, reject) => {
    http('get', '/workDesign/projectTeam', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 下载文件
 */
function downloadFile(params) {
  return new Promise((resolve, reject) => {
    http('post', '/workDesign/downloadFile', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 查看文件
 */
function showFile(params) {
  return new Promise((resolve, reject) => {
    http('post', '/workDesign/showFile', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 项目详情属性
 */
function projectInfo(params) {
  return new Promise((resolve, reject) => {
    http('get', '/workDesign/projectInfo', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 创建/回复问题
 */
function addProblem(params) {
  return new Promise((resolve, reject) => {
    http('get', '/workDesign/addProblem', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 项目问题列表
 */
function problemList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/workDesign/problemList', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 问题评论列表
 */
function problemComment(params) {
  return new Promise((resolve, reject) => {
    http('post', '/workDesign/problemComment', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 日志列表
 */
function projectLog(params) {
  return new Promise((resolve, reject) => {
    http('post', '/workDesign/projectLog', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 修改权限
 */
function updateRole(params) {
  return new Promise((resolve, reject) => {
    http('get', '/workDesign/updateRole', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

const uploadApi = `${globalUrlPrefix()}/workDesign/uploadFile`;

export {
  login,
  projectTotal,
  projectList,
  projectFile,
  deleteFile,
  uploadFile,
  projectTeam,
  downloadFile,
  showFile,
  projectInfo,
  addProblem,
  problemList,
  problemComment,
  projectLog,
  updateRole,
  uploadApi,
};
