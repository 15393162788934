import React from 'react';
import { Table } from 'antd';

const columns = [
  {
    title: '提交日期',
    dataIndex: 'created_time',
    key: 'created_time',
  },
  {
    title: '项目名称',
    dataIndex: 'project_name',
    key: 'project_name',
  },
  {
    title: '项目编号',
    dataIndex: 'project_code',
    key: 'project_code',
  },
  {
    title: '项目阶段',
    dataIndex: 'project_message',
    key: 'project_message',
  },
];

export default class ParticipateProjectTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, search: props.search || '' };
  }

  render() {
    const { dataSource, loading, pagination, maxHeight } = this.props;
    return (
      <Table
        style={{ maxHeight: maxHeight, overflow: 'auto' }}
        dataSource={dataSource}
        columns={columns}
        bordered={true}
        pagination={pagination}
        loading={loading}
        locale={{ emptyText: dataSource && dataSource.length === 0 && '暂无数据' }}
      />
    );
  }
}
