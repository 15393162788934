import React from 'react';
import { Row, Col, Avatar, Card, Space, Button, Modal, Input, Form, message } from 'antd';
import { editMobile, editPassword, queryUserInfo } from '@/services/api';
import SmsCodeComponent from '@/pages/components/smsCode';
import { getLocalUserInfo, desensitization } from '@/utils/util';

export default class AccountSecurity extends React.Component {
  constructor(props) {
    super(props);
    this.changeMobileRef = React.createRef();
    this.changePwdRef = React.createRef();
    this.state = { loading: false, mobileVisible: false, pwdVisible: false, userAvatar: '', userInfo: {} };
  }

  componentDidMount() {
    this.queryUserInfo();
  }

  queryUserInfo() {
    const user = getLocalUserInfo();
    const params = { id: user.id };
    queryUserInfo(params).then(
      (res) => {
        const userInfo = res.data;
        if (userInfo) {
          this.setState({ userAvatar: userInfo.avatar || '', userInfo });
        }
      },
      (error) => {}
    );
  }

  editMobile = (values) => {
    const user = getLocalUserInfo();
    const { mobile, bizid, sms_code } = this.state;
    const params = {
      id: user.id,
      mobile,
      bizid,
      sms_code,
      ...values,
    };
    editMobile(params).then(
      (res) => {
        if (res.code === 200) {
          message.destroy();
          message.success(res.msg);
          this.changeMobileRef.current.resetFields();
          this.setState({ mobileVisible: false });
        } else {
          message.destroy();
          message.warning(res.msg);
        }
      },
      (error) => {}
    );
  };

  editPassword() {
    const user = getLocalUserInfo();
    const { mobile, bizid, sms_code, password } = this.state;
    const params = {
      id: user.id,
      mobile,
      bizid,
      sms_code,
      password,
    };
    editPassword(params).then(
      (res) => {
        if (res.code === 200) {
          message.destroy();
          message.success(res.msg);
          this.changePwdRef.current.resetFields();
          this.setState({ pwdVisible: false });
        } else {
          message.destroy();
          message.warning(res.msg);
        }
      },
      (error) => {}
    );
  }

  render() {
    const { mobileVisible, pwdVisible, userInfo } = this.state;
    return (
      <div>
        <Space direction="horizontal" size={20} style={{ marginBottom: '50px', marginTop: '20px' }}>
          <Avatar size={70} src={userInfo.avatar || ''} />
          <span>{userInfo.nickname || ''}</span>
        </Space>
        <Space direction="vertical" size={20} style={{ width: '100%' }}>
          <Card>
            <Row>
              <Col span={3}>等级:无</Col>
              <Col span={4}>登录密码</Col>
              <Col span={11}>安全性高的密码可以使用帐号更安全，建议您定期更换密码以保证安全</Col>
              <Col span={6} style={{ textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    this.setState({ pwdVisible: true });
                  }}
                  type="primary"
                >
                  修改
                </Button>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row>
              <Col span={3}>已绑定</Col>
              <Col span={4}>手机绑定</Col>
              <Col span={11}>{`您已成功绑定${desensitization(userInfo.mobile)}`}</Col>
              <Col span={6} style={{ textAlign: 'center' }}>
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ mobileVisible: true });
                  }}
                >
                  修改
                </Button>
              </Col>
            </Row>
          </Card>
          {/* <Card>
            <Row>
              <Col span={3}>
                <IssuesCloseOutlined />
              </Col>
              <Col span={4}>注销帐号</Col>
              <Col span={11}>彻底关闭不再使用CDS系统帐号</Col>
              <Col span={6} style={{ textAlign: 'center' }}>
                <Button onClick={() => {}} type="ghost">
                  注销
                </Button>
              </Col>
            </Row>
          </Card> */}
          {/* <Card>
            <Row>
              <Col span={4}>未绑定</Col>
              <Col span={4}>邮箱绑定</Col>
              <Col span={10}>您还没绑定邮箱</Col>
              <Col span={6} style={{ textAlign: 'center' }}>
                <Button>绑定</Button>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row>
              <Col span={4}>未绑定</Col>
              <Col span={4}>微信绑定</Col>
              <Col span={10}>绑定微信后可以使微信登录</Col>
              <Col span={6} style={{ textAlign: 'center' }}>
                <Button>绑定</Button>
              </Col>
            </Row>
          </Card> */}
        </Space>
        {pwdVisible && (
          <Modal
            title="修改密码"
            centered
            visible={pwdVisible}
            onOk={() => {
              this.setState({ pwdVisible: false });
            }}
            onCancel={() => {
              this.setState({ pwdVisible: false });
            }}
            width={500}
            footer={
              <Row style={{ display: 'flex', justifyContent: 'center' }} gutter={20}>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.editPassword();
                    }}
                  >
                    确定
                  </Button>
                </Col>
              </Row>
            }
          >
            <Form
              ref={this.changePwdRef}
              onFinish={this.editPassword}
              name="basic"
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
            >
              <Form.Item
                label="手机号"
                name="mobile"
                labelCol={{
                  span: 5,
                }}
                wrapperCol={{
                  span: 16,
                }}
                rules={[
                  { required: true, message: '请输入11手机号' },
                  { pattern: /^1\d{10}$/, message: '请输入11位数字' },
                ]}
              >
                <Input
                  maxLength={11}
                  placeholder="请输入手机号"
                  onChange={(e) => {
                    this.setState({ mobile: e.target.value });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="验证码"
                name="sms_code"
                labelCol={{
                  span: 5,
                }}
                wrapperCol={{
                  span: 16,
                }}
                rules={[
                  { required: true, message: '请输入验证码' },
                  { pattern: /^[0-9]{4}$/, message: '请输入4位有效数字' },
                ]}
              >
                <SmsCodeComponent
                  mobile={this.state.mobile}
                  onSmsChanged={(val) => {
                    this.setState({
                      sms_code: val,
                    });
                  }}
                  callback={(res) => {
                    this.setState({
                      bizid: res,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="密码"
                name="password"
                labelCol={{
                  span: 5,
                }}
                wrapperCol={{
                  span: 16,
                }}
                rules={[
                  { required: true, message: '请输入密码' },
                  { max: 50, message: '密码最多50位' },
                  { min: 6, message: '密码不能少于6位' },
                ]}
              >
                <Input.Password
                  placeholder="请输入密码"
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                />
              </Form.Item>
            </Form>
          </Modal>
        )}
        {mobileVisible && (
          <Modal
            title="修改手机号"
            centered
            visible={mobileVisible}
            onOk={() => {
              this.setState({ mobileVisible: false });
            }}
            onCancel={() => {
              this.setState({ mobileVisible: false });
            }}
            width={500}
            footer={
              <Row style={{ display: 'flex', justifyContent: 'center' }} gutter={20}>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.editMobile();
                    }}
                  >
                    确定
                  </Button>
                </Col>
              </Row>
            }
          >
            <Form
              ref={this.changeMobileRef}
              onFinish={this.editMobile}
              name="basic"
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
            >
              <Form.Item
                label="手机号"
                name="mobile"
                labelCol={{
                  span: 5,
                }}
                wrapperCol={{
                  span: 16,
                }}
                rules={[
                  { required: true, message: '请输入11手机号' },
                  { pattern: /^1\d{10}$/, message: '手机号格式错误！' },
                ]}
              >
                <Input
                  placeholder="请输入手机号"
                  onChange={(e) => {
                    this.setState({ mobile: e.target.value });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="验证码"
                name="sms_code"
                labelCol={{
                  span: 5,
                }}
                wrapperCol={{
                  span: 16,
                }}
                rules={[
                  { required: true, message: '请输入验证码' },
                  { pattern: /^[0-9]{4}$/, message: '请输入4位有效数字' },
                ]}
              >
                <SmsCodeComponent
                  mobile={this.state.mobile}
                  onSmsChanged={(val) => {
                    this.setState({
                      sms_code: val,
                    });
                  }}
                  callback={(res) => {
                    this.setState({
                      bizid: res,
                    });
                  }}
                />
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}
