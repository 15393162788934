import http from '@/utils/request';
import { globalUrlPrefix } from '@/utils/util';

/**
 *
 * @returns
 */
function getIdentifyingCode(params) {
  return new Promise((resolve, reject) => {
    http('get', '/user/getIdentifyingCode', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~ ', error);
        reject(error);
      }
    );
  });
}

/**
 * 手机号、密码登录
 */
function login(params) {
  return new Promise((resolve, reject) => {
    http('post', '/user/login', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~', error);
        reject(error);
      }
    );
  });
}

/**
 * 手机号、密码注册
 */
function register(params) {
  return new Promise((resolve, reject) => {
    http('post', '/user/register', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log('网络异常~', error);
        reject(error);
      }
    );
  });
}

/**
 * 发需求
 */
function publish(params) {
  return new Promise((resolve, reject) => {
    http('post', '/need/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 发布的需求列表
 */
function myPublishList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/need/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 身份认证提交
 */
function authenticationSubmit(params) {
  return new Promise((resolve, reject) => {
    http('post', '/authentication/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 轮播图列表
 */
function getBannerList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/banner/index', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 项目列表
 */
function getProjectList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/projectInfo/index', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 项目详情
 */
function getProjectDetail(params) {
  return new Promise((resolve, reject) => {
    http('get', '/projectInfo/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 项目详情里的子项目列表(即项目分部构成)
 */
function getSubProjectList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/projectInfo/selectSonList', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 项目详情里的承接方
 */
function getProjectTeam(params) {
  return new Promise((resolve, reject) => {
    http('get', '/projectInfo/selectTeam', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 首页-产业列表-陈体志
 */
function projectCategory(params) {
  return new Promise((resolve, reject) => {
    http('get', '/projectCategory/index', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 首页-行业列表-陈体志
 */
function projectClassification(params) {
  return new Promise((resolve, reject) => {
    http('get', '/projectClassification/index', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 首页-资源列表
 */
function getResourceList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/resource/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 首页-资源详情
 */
function getResourceDetail(params) {
  return new Promise((resolve, reject) => {
    http('get', '/resource/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 首页-方案列表
 */
function getPlanList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/plan/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 首页-方案详情
 */
function getPlanDetail(params) {
  return new Promise((resolve, reject) => {
    http('get', '/plan/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 案例分类列表
 */
function getExampleCategoryList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/example/categoryList', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 首页-案例列表
 */
function getExampleList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/example/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 首页-案例详情
 */
function getExampleDetail(params) {
  return new Promise((resolve, reject) => {
    http('get', '/example/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 首页-资讯列表
 */
function getArticleList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/article/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 首页-资讯详情
 */
function getArticleDetail(params) {
  return new Promise((resolve, reject) => {
    http('get', '/article/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 个人认证提交
 */
function authentication(params) {
  return new Promise((resolve, reject) => {
    http('post', '/authentication/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 企业认证提交
 */
function companyAuth(params) {
  return new Promise((resolve, reject) => {
    http('post', '/companyAuth/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 企业认证详情
 */
function checkCompanyAuth(params) {
  return new Promise((resolve, reject) => {
    http('get', '/companyAuth/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 个人认证详情
 */
function checkPersonalAuth(params) {
  return new Promise((resolve, reject) => {
    http('get', '/authentication/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 资历信息新增
 */
function educationAdd(params) {
  return new Promise((resolve, reject) => {
    http('post', '/education/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 资历信息编辑
 */
function educationEdit(params) {
  return new Promise((resolve, reject) => {
    http('post', '/education/edit', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 教育经历列表
 */
function queryEducationList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/education/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 教育经历删除
 */
function deleteEducationById(params) {
  return new Promise((resolve, reject) => {
    http('post', '/education/del', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 工作经历列表
 */
function queryWorkExperienceList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/userWorkHistory/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 工作经历删除
 */
function deleteWorkExperienceById(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userWorkHistory/del', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 工作经历添加
 */
function workExperienceAdd(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userWorkHistory/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 工作经历修改
 */
function workExperienceEdit(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userWorkHistory/edit', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 项目经验列表
 */
function queryProjectExperienceList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/userProjectExperience/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 专利证书列表
 */
function queryPatentList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/userPatent/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 作品展示列表
 */
function queryProjectShowList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/userProjectShow/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 获奖和证书列表
 */
function queryPrizeList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/userPrize/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 专业证书列表
 */
function queryCertificateList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/userCertificate/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 项目经验添加
 */
function projectExperienceAdd(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userProjectExperience/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 专利证书添加
 */
function patentAdd(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userPatent/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 专利证书修改
 */
function patentEdit(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userPatent/edit', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 专利证书删除
 */
function patentDelete(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userPatent/del', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 获奖和证书添加
 */
function prizeAdd(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userPrize/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 获奖和证书修改
 */
function prizeEdit(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userPrize/edit', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 获奖和证书删除
 */
function prizeDelete(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userPrize/del', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 作品展示添加
 */
function projectShowAdd(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userProjectShow/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 作品展示修改
 */
function projectShowEdit(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userProjectShow/edit', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 作品展示删除
 */
function projectShowDelete(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userProjectShow/del', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 专业证书添加
 */
function userCertificateAdd(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userCertificate/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 专业证书修改
 */
function userCertificateEdit(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userCertificate/edit', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 专业证书删除
 */
function userCertificateDelete(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userCertificate/del', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 项目经验修改
 */
function projectExperienceEdit(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userProjectExperience/edit', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 项目经验删除
 */
function deleteProjectExperienceById(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userProjectExperience/del', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 文章分类列表
 */
function articleCategory(params) {
  return new Promise((resolve, reject) => {
    http('get', '/articleCategory/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 我的项目
 */
function myProject(params) {
  return new Promise((resolve, reject) => {
    http('get', '/undertakeProject/myProject', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 承接项目
 */
function acceptProject(params) {
  return new Promise((resolve, reject) => {
    http('get', '/undertakeProject/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 检测用户认证
 */
function checkUser(params) {
  return new Promise((resolve, reject) => {
    http('get', '/undertakeProject/checkUser', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 收藏列表
 */
function collectionList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/myCollection/index', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 新增收藏
 */
function addCollection(params) {
  return new Promise((resolve, reject) => {
    http('get', '/myCollection/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 取消收藏
 */
function cancelCollection(params) {
  return new Promise((resolve, reject) => {
    http('get', '/myCollection/del', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 用户入驻(设计师或服务商能力认证)
 */
function userAuth(params) {
  return new Promise((resolve, reject) => {
    http('post', '/userEnter/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 用户入驻详情
 */
function settledDetailInfo(params) {
  return new Promise((resolve, reject) => {
    http('get', '/userEnter/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 我的合同
 */
function projectContract(params) {
  return new Promise((resolve, reject) => {
    http('get', '/projectContract/index', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 上传文件
 */
function upload() {
  return new Promise((resolve, reject) => {
    http('post', '/file/upload').then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 用户基本信息和专业信息修改
 */
function editUserBasicInfo(params) {
  return new Promise((resolve, reject) => {
    http('post', '/user/editInfo', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 查询用户信息
 */
function queryUserInfo(params) {
  return new Promise((resolve, reject) => {
    http('get', '/user/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 修改用户信息
 */
function editUserInfo(params) {
  return new Promise((resolve, reject) => {
    http('post', '/user/edit', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 修改用户手机号
 */
function editMobile(params) {
  return new Promise((resolve, reject) => {
    http('post', '/user/editMobile', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 登录后修改用户密码
 */
function editPassword(params) {
  return new Promise((resolve, reject) => {
    http('post', '/user/editPassword', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 客户发消息给客服
 */
function sendToService(params) {
  return new Promise((resolve, reject) => {
    http('post', '/chatRecord/sendToService', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 获取客户组连接列表
 */
function onLineServer(params) {
  return new Promise((resolve, reject) => {
    http('get', '/adminUser/onLineServer', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 协议相关
 */
function getAgreementInfo(params) {
  return new Promise((resolve, reject) => {
    http('get', '/agreement/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 各省产业-企业数量
 */
function getProvinceCount(params) {
  return new Promise((resolve, reject) => {
    http('get', '/systemDistrict/getProvinceCount', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 各市产业-企业数量
 */
function getCityCount(params) {
  return new Promise((resolve, reject) => {
    http('get', '/systemDistrict/getCount', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 产业-企业列表
 */
function getProductionList(params) {
  return new Promise((resolve, reject) => {
    http('get', '/xqnEnterprise/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 帮助中心数据
 */
function getHelpInfo(params) {
  return new Promise((resolve, reject) => {
    http('get', '/helpCenter/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 关于我们
 */
function getAboutInfo(params) {
  return new Promise((resolve, reject) => {
    http('get', '/singlePage/info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

const uploadApi = `${globalUrlPrefix()}/file/upload`;

export {
  login,
  register,
  getIdentifyingCode,
  publish,
  authenticationSubmit,
  getBannerList,
  getProjectList,
  getProjectDetail,
  getSubProjectList,
  getProjectTeam,
  getResourceList,
  getResourceDetail,
  getPlanList,
  getPlanDetail,
  getExampleCategoryList,
  getExampleList,
  getExampleDetail,
  getArticleList,
  getArticleDetail,
  authentication,
  companyAuth,
  checkCompanyAuth,
  checkPersonalAuth,
  queryEducationList,
  educationAdd,
  educationEdit,
  deleteEducationById,
  queryWorkExperienceList,
  deleteWorkExperienceById,
  workExperienceAdd,
  workExperienceEdit,
  queryProjectExperienceList,
  projectExperienceAdd,
  projectExperienceEdit,
  deleteProjectExperienceById,
  queryPatentList,
  patentAdd,
  patentEdit,
  patentDelete,
  queryPrizeList,
  prizeAdd,
  prizeEdit,
  prizeDelete,
  queryProjectShowList,
  projectShowAdd,
  projectShowEdit,
  projectShowDelete,
  queryCertificateList,
  userCertificateAdd,
  userCertificateEdit,
  userCertificateDelete,
  articleCategory,
  projectCategory,
  projectClassification,
  acceptProject,
  checkUser,
  myProject,
  collectionList,
  addCollection,
  cancelCollection,
  userAuth,
  settledDetailInfo,
  upload,
  projectContract,
  myPublishList,
  editUserBasicInfo,
  queryUserInfo,
  editUserInfo,
  editMobile,
  editPassword,
  sendToService,
  onLineServer,
  getAgreementInfo,
  getProvinceCount,
  getCityCount,
  getProductionList,
  getHelpInfo,
  getAboutInfo,
  uploadApi,
};
