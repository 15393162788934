import React from 'react';
import { Row, Col, Input, Form, Button, Checkbox, Modal, message, Spin } from 'antd';
import { login, getAgreementInfo } from '@/services/api';
import { history } from '@/router/history';
import { getUrlParams,getUrlParams1, setLocalUserInfo, setDesignLocalUserInfo, UserRole, setDesignUserRole } from '@/utils/util';

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractVisible: false,
      contractData: {},
      loading: false,
      isRead: false,
    };
  }
  onFinish = (values) => {
	  const type = getUrlParams('systemType');
	  let preurl='/';
	  if(!type){
	  	preurl = getUrlParams1()
	  }
    if (!this.state.isRead) {
      message.destroy();
      return message.info('请勾选已阅读并同意');
    }
    login({ ...values }).then(
      (res) => {
        if (res.code === 200) {
          message.destroy();
          message.success(res.msg);
          const userInfo = res.data;
          setLocalUserInfo(userInfo);
          setDesignUserRole(UserRole.designer);
          setDesignLocalUserInfo(userInfo);
          const type = getUrlParams('systemType');
          if (Number(type) === 2) {
            history.push('/');
            window.open(`/cloudPlatform`, '_blank'); //打开新窗口
            window.location.reload();
          } else {
            history.push(preurl);
            window.location.reload();
          }
        } else {
          message.destroy();
          message.warning(res.msg);
        }
      },
      (error) => {}
    );
  };

  getAgreementInfo(type) {
    this.setState({ loading: true, contractData: {} }, () => {
      getAgreementInfo({ id: type }).then(
        (res) => {
          this.setState({ contractData: res.data, loading: false });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    });
  }

  previewContractByLink() {
    const { contractData, loading } = this.state;
    return (
      <Spin spinning={loading}>
        <div dangerouslySetInnerHTML={{ __html: contractData.content }} />
      </Spin>
    );
  }

  render() {
    const { contractVisible } = this.state;
    return (
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Col span={20} style={{ paddingTop: '100px', backgroundColor: '#FFFFFF', height: '500px' }}>
          <Form
            onFinish={this.onFinish}
            initialValues={
              {
                // remember: true,
              }
            }
            autoComplete="off"
          >
            <Form.Item
              label="手机号"
              name="mobile"
              labelCol={{
                span: 9,
              }}
              wrapperCol={{
                span: 6,
              }}
              rules={[
                { required: true, message: '请输入11位数字' },
                { pattern: /^1\d{10}$/, message: '请输入11位数字' },
              ]}
            >
              <Input placeholder="请输入手机号" maxLength={11} />
            </Form.Item>
            <Form.Item
              label="密码"
              name="password"
              labelCol={{
                span: 9,
              }}
              wrapperCol={{
                span: 6,
              }}
              rules={[
                { required: true, message: '密码不能为空' },
                // { max: 50,message:'密码长度6~50位' },
                // { min: 6,message:'密码长度6~50位'},
				// {pattern:'^(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!@#$%*--+/\\\\.])[\\da-zA-Z!@#$%*--+/\\\\.]{8,16}$', message: '密码长度8~16位且包含数字、字母、特殊符号'},
                // {
                //   pattern:
                //     /[`~!@#$%^&*()_\-+=<>?:"{}|,.\\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im,
                //   message: '不允许非法字符',
                // },
              ]}
            >
              <Input.Password placeholder="请输入密码" />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 9,
                span: 16,
              }}
            >
              <Checkbox
                onChange={(e) => {
                  this.setState({ isRead: e.target.checked });
                }}
              >
                已阅读并同意
                <a
                  onClick={() => {
                    this.setState({ contractVisible: true }, () => {
                      this.getAgreementInfo(6);
                    });
                  }}
                  href="#"
                >
                  《用户协议》
                </a>
                <a
                  onClick={() => {
                    this.setState({ contractVisible: true }, () => {
                      this.getAgreementInfo(3);
                    });
                  }}
                  href="#"
                >
                  《隐私协议》
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 11,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                登录
              </Button>
            </Form.Item>
          </Form>
        </Col>
        {contractVisible && (
          <Modal
            width={1200}
            bodyStyle={{ height: 800, overflow: 'auto' }}
            closable={false}
            centered
            maskClosable={true}
            onCancel={() => {
              this.setState({ contractVisible: false });
            }}
            visible={contractVisible}
            footer={null}
            title={null}
          >
            {this.previewContractByLink()}
          </Modal>
        )}
      </Row>
    );
  }
}
