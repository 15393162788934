import { Table, Divider, Modal, Checkbox, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { projectTeam, updateRole } from '@/services/designPlatformApi';
import { getUrlParams, getDesignLocalUserInfo, UserRole, getDesignUserRole } from '@/utils/util';

const role = {
  '1': '项目经理',
  '2': '设计师',
};

const plainOptions = [
  { label: '查看', value: '1' },
  { label: '下载', value: '2' },
];
const defaultCheckedList = [];

const PAGE_LIMIT = 10;

const CheckboxGroup = Checkbox.Group;

const RelatedMember = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [visible, setVisible] = useState();
  const [id, setId] = useState();

  useEffect(() => {
    loadData();
  }, []);

  function loadData(pagination = { page: 1, limit: PAGE_LIMIT }) {
    setLoading(true);
    const id = getUrlParams('id');
    const project_status = getUrlParams('project_status');
    const user = getDesignLocalUserInfo();
    const params = {
      id,
      project_status,
      user_id: user.id,
      user_role: getDesignUserRole(),
      ...pagination,
    };
    projectTeam(params).then(
      (res) => {
        setLoading(false);
        setData(res.data || []);
        setTotalCount(res.total_count);
      },
      (error) => {
        setLoading(false);
      }
    );
  }

  let memberColumns = [
    {
      title: '手机号',
      dataIndex: 'mobile',
    },
    {
      title: '成员类别',
      dataIndex: 'member_type',
      render: (text) => {
        return <span>{role[text]}</span>;
      },
    },
    {
      title: '权限',
      dataIndex: 'nickname',
    },
    {
      title: '操作',
      dataIndex: '1',
      fixed: 'right',
      render: (text, item) => {
        if (item.member_type === 2) {
          return (
            <a
              onClick={() => {
                const target = item.role ? item.role.split(',') : [];
                setCheckedList(target);
                setVisible(true);
                setId(item.id);
              }}
            >
              编辑权限
            </a>
          );
        }
      },
    },
  ];

  const user = getDesignLocalUserInfo();
  if (user && getDesignUserRole() === UserRole.designer) {
    memberColumns = memberColumns.slice(0, -1);
  }

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    // setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    const arr = [];
    plainOptions.forEach((item, index) => {
      arr.push(item.value);
    });
    setCheckedList(e.target.checked ? arr : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <div>
      <Table
        columns={memberColumns}
        dataSource={data}
        loading={loading}
        pagination={{
          defaultPageSize: PAGE_LIMIT,
          total: totalCount,
          showTotal: (total) => `共 ${total} 条`,
          onChange: (curPage, count) => {
            loadData({ page: curPage, limit: count });
          },
        }}
      />
      {visible && (
        <Modal
          title="编辑权限"
          destroyOnClose
          centered
          visible={visible}
          okText={'确定'}
          onOk={() => {
            const user = getDesignLocalUserInfo();
            const params = {
              id: id,
              role: checkedList.join(','),
              user_id: user.id,
              user_role: getDesignUserRole(),
            };
            updateRole(params).then(
              (res) => {
                if (res.code === 200) {
                  message.success(res.msg);
                  loadData();
                  setVisible(false);
                } else {
                  message.warning(res.msg);
                  setVisible(false);
                }
              },
              (error) => {}
            );
          }}
          cancelText={'取消'}
          onCancel={() => {
            setVisible(false);
          }}
          width={600}
        >
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            全选
          </Checkbox>
          <Divider />
          <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
        </Modal>
      )}
    </div>
  );
};

export default RelatedMember;
