import React from 'react';
import { Row, Col, Tabs, Carousel, Card, Typography, Space, Tag,Button } from 'antd';
import { withRouter } from '@/router/withRouter';
import { history } from '@/router/history';
import { ResourceType } from '@/utils/util';
import lefticon from '@/assets/images/left.png';
import {
  getBannerList,
  getProjectList,
  getResourceList,
  getPlanList,
  getExampleList,
  getArticleList,
} from '@/services/api';
import './home.less';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const operations = (
  <span
    style={{ cursor: 'pointer' }}
    onClick={() => {
      history.push('/accept', { curNavIndex: 'accept' });
    }}
  >
    查看更多
  </span>
);

const exampleOperations = () => (
  <span
    style={{ cursor: 'pointer' }}
    onClick={() => {
      history.push(`/resourceList`, { curNavIndex: 'projectCase' });
    }}
  >
    查看更多
  </span>
);

const projectStatus = { '1': '主项目', '2': '子项目' };

class XqnHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerList: [],
      projectList: [],
      resourceList: [],
      planList: [],
      exampleList: [],
      articleList: [],
    };
  }

  componentDidMount() {
    this.getProjectList();
    this.getBannerCollection();
    this.getResourceList();
    this.getPlanList();
    this.getExampleList();
    this.getArticleList();
  }

  getBannerCollection() {
    getBannerList().then(
      (res) => {
        this.setState({ bannerList: res.data || [] });
      },
      (error) => {
        console.log('error');
      }
    );
  }

  getProjectList() {
    const params = {
      son_category_one_id: '',
      son_category_two_id: '',
      son_industry_one_id: '',
      son_industry_two_id: '',
      cooperation_status: '',
      sort_status: '',
    };
    getProjectList(params).then(
      (res) => {
        this.setState({ projectList: res.data.project_info || [] });
      },
      (error) => {
        console.log('error');
      }
    );
  }

  getResourceList() {
    getResourceList().then(
      (res) => {
        this.setState({ resourceList: res.data || [] });
      },
      (error) => {
        console.log('error');
      }
    );
  }

  getPlanList() {
    getPlanList().then(
      (res) => {
        this.setState({ planList: res.data || [] });
      },
      (error) => {
        console.log('error');
      }
    );
  }

  getExampleList() {
    getExampleList().then(
      (res) => {
        this.setState({ exampleList: res.data || [] });
      },
      (error) => {
        console.log('error');
      }
    );
  }

  getArticleList() {
    getArticleList().then(
      (res) => {
        this.setState({ articleList: res.data || [] });
      },
      (error) => {
        console.log('error');
      }
    );
  }

  getProjectItem(item) {
    return (
      <Card
        bordered={false}
        style={{ marginBottom: 20, cursor: 'pointer' }}
        onClick={() => {
          this.props.router.navigate(`/acceptDetail?id=${item.id}&project_status=${item.project_status}`, {
            state: { curNavIndex: 'accept' },
          });
        }}
      >
        <Space direction="vertical" size={20} style={{ width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <img src={item.img_url} alt="" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
            <div className="bbb" style={{ flex: 1, marginLeft: 20 }}>
              <Typography style={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
                <div style={{ display: 'flex', flexShrink: 0 }}>
                  <Title level={3} style={{ flex: 1 }} ellipsis={{ rows: 1 }}>
                    {item.project_name}
                  </Title>
                  <span
                    style={{
                      height: 30,
                      backgroundColor: 'red',
                      color: 'white',
                      padding: 5,
                      borderRadius: '8px 2px 8px 2px',
					  marginLeft:'auto'
                    }}
                  >
                    {projectStatus[item.project_status]}
                  </span>
                </div>
                <Paragraph className='aaaa'  ellipsis={{ rows: 5 }}>
					{item.introduction}
                </Paragraph>
                <Text mark={false} style={{ flexShrink: 0 }}>
                  <span style={{ color: '#1890FF' }}>{item.visits_num || '0'}</span> 已查看
                </Text>
              </Typography>
            </div>
          </div>
          <Row>
            <Col span={8}>
              招募人数: <span style={{ color: '#1890FF' }}>{item.number_people || ''}</span>
            </Col>
            <Col span={8}>
              招募金额: <span style={{ color: '#1890FF' }}>{item.ways_cooperation || ''}</span>
            </Col>
            <Col span={8}>
              项目创建时间: <span style={{ color: '#1890FF' }}>{item.created_time || ''}</span>
            </Col>
          </Row>
        </Space>
      </Card>
    );
  }

  render() {
    const resourceData = this.state.resourceList.slice(0, 4);
    const planData = this.state.planList.slice(0, 1);
    const exampleData = this.state.exampleList.slice(0, 1);
    const articleData = this.state.articleList.slice(0, 2);
    const projectData = this.state.projectList.slice(0, 4);
    return (
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 600 }}>
        <Col span={20}>
          
		  <Row style={{ position:'relative'}}>
			  <Button
			    ghost
				className='leftButton'
				style={{ left: 0,position: 'absolute',top: '115px',zIndex:33,border:'none' }}
				onClick={() => {
				// 通过获取走马灯dom，调用Carousel的prev()方法
				  this.card.prev();
				}}
			  >
			<img src={lefticon} style={{ width: '60px'}} />
			  </Button>
			  <Button ghost
				className='rightButton'
				style={{ right: 0,position: 'absolute' ,zIndex:33,top: '115px',border:'none'}}
				onClick={() => {
				// 通过获取走马灯dom，调用Carousel的next()方法
				  this.card.next();
				}}
			  >
				<img src={lefticon} style={{ width: '60px',transform:'rotate(180deg)'}} />
			  </Button>
			  <Carousel autoplay ref={e => {
          // 走马灯dom名card
            this.card = e;
          }}>
			    {this.state.bannerList.map((item, index) => {
			      return (
			        <div>
			          <a 
href={item.link} 
target="_blank" 
rel="noopener noreferrer"><img src={item.url} height="330" alt="" width="100%" /></a>
			        </div>
			      );
			    })}
			  </Carousel>
			</Row>
          {projectData.length > 0 && (
            <Tabs tabBarExtraContent={operations}>
              <TabPane tab="找项目" key="1">
                <Row gutter={20}>
                  {projectData.map((item, index) => {
                    return <Col span={12}>{this.getProjectItem(item)}</Col>;
                  })}
                </Row>
              </TabPane>
            </Tabs>
          )}
          {resourceData.length > 0 && (
            <Tabs>
              <TabPane tab="寻资源" key="2">
                <Row gutter={20}>
                  {resourceData.map((item, index) => {
                    return (
                      <Col span={6}>
                        <Card
                          bordered={false}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.props.router.navigate(`/resourceDetail?id=${item.id}&type=${ResourceType.RESOURCE}&curNavIndex=projectCase`, {
                              state: { id: item.id, type: ResourceType.RESOURCE },
                            });
                          }}
                        >
                          <Space direction="vertical" size={20} style={{ width: '100%' }}>
                            <div style={{ display: 'flex' }}>
                              <img
                                src={item.thumb}
                                alt=""
                                style={{ width: '120px', height: '120px', objectFit: 'cover' }}
                              />
                              <div style={{ flex: 1, marginLeft: 20 }}>
                                <Typography
                                  style={{ display: 'flex', flexDirection: 'column', height: '100%', width: 150 }}
                                >
                                  <Title level={3} style={{ flexGrow: 1 }} ellipsis={{ rows: 1 }}>
                                    {item.title}
                                  </Title>
                                  <Paragraph ellipsis={{ rows: 4 }} style={{ flexShrink: 0, overflow: 'auto' }}>
                                    {item.description}
                                  </Paragraph>
                                </Typography>
                              </div>
                            </div>
                          </Space>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </TabPane>
            </Tabs>
          )}
          {planData.length > 0 && (
            <Row gutter={20}>
              {planData.map((item, index) => {
                return (
                  <Col span={12}>
                    <Tabs>
                      <TabPane tab="搜方案" key="3">
                        {planData && (
                          <Card
                            bordered={false}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.props.router.navigate(`/resourceDetail?id=${item.id}&type=${ResourceType.PLAN}&curNavIndex=projectCase`, {
                                state: { id: item.id, type: ResourceType.PLAN },
                              });
                            }}
                          >
                            <Space direction="vertical" size={20} style={{ width: '100%' }}>
                              <div style={{ display: 'flex' }}>
                                <img
                                  src={item.thumb}
                                  alt=""
                                  style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                                />
                                <div style={{ flex: 1, marginLeft: 20 }}>
                                  <Typography
                                    style={{ display: 'flex', flexDirection: 'column', height: '100%', width: 400 }}
                                  >
                                    <Row type="flex" justify="space-between" style={{ flexShrink: 0 }}>
                                      <Col>
                                        <Title level={3} ellipsis={{ rows: 1 }}>
                                          {item.title}
                                        </Title>
                                      </Col>
                                    </Row>
                                    <Paragraph ellipsis={{ rows: 6 }} style={{ flexShrink: 0, overflow: 'auto' }}>
                                      {item.description}
                                    </Paragraph>
                                  </Typography>
                                </div>
                              </div>
                            </Space>
                          </Card>
                        )}
                      </TabPane>
                    </Tabs>
                  </Col>
                );
              })}
              {exampleData.map((item, index) => {
                return (
                  <Col span={12}>
                    <Tabs tabBarExtraContent={exampleOperations()}>
                      <TabPane tab="看案例" key="4">
                        {exampleData && (
                          <Card
                            bordered={false}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.props.router.navigate(`/resourceDetail?id=${item.id}&type=${ResourceType.EXAMPLE}&curNavIndex=projectCase`, {
                                state: { id: item.id, type: ResourceType.EXAMPLE },
                              });
                            }}
                          >
                            <Space direction="vertical" size={20} style={{ width: '100%' }}>
                              <div style={{ display: 'flex' }}>
                                <img
                                  src={item.img}
                                  alt=""
                                  style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                                />
                                <div style={{ flex: 1, marginLeft: 20 }}>
                                  <Typography
                                    style={{ display: 'flex', flexDirection: 'column', height: '100%', width: 400 }}
                                  >
                                    <Row type="flex" justify="space-between" style={{ flexShrink: 0 }}>
                                      <Col>
                                        <Title level={3} ellipsis={{ rows: 1 }}>
                                          {item.title}
                                        </Title>
                                      </Col>
                                    </Row>
                                    <Paragraph style={{ flexGrow: 1 }} ellipsis={{ rows: 5 }}>
                                      {item.description}
                                    </Paragraph>
                                    <div>
                                      {item.tag_list.length > 0 &&
                                        item.tag_list.map((item, index) => {
                                          return (
                                            <Tag color="magenta" style={{ flexShrink: 0 }}>
                                              {item.name}
                                            </Tag>
                                          );
                                        })}
                                    </div>
                                  </Typography>
                                </div>
                              </div>
                            </Space>
                          </Card>
                        )}
                      </TabPane>
                    </Tabs>
                  </Col>
                );
              })}
            </Row>
          )}
          {articleData && articleData.length > 0 && (
            <Tabs>
              <TabPane tab="资讯信息" key="5">
                <Row gutter={20}>
                  {articleData.map((item, i) => {
                    return (
                      <Col span={12}>
                        <Card
                          bordered={false}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.props.router.navigate(`/resourceDetail?id=${item.id}&type=${ResourceType.ARTICLE}&curNavIndex=projectCase`, {
                              state: { id: item.id, type: ResourceType.ARTICLE },
                            });
                          }}
                        >
                          <Space direction="vertical" size={20} style={{ width: '100%' }}>
                            <div style={{ display: 'flex' }}>
                              <img
                                src={item.img}
                                alt=""
                                style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                              />
                              <div style={{ flex: 1, marginLeft: 20 }}>
                                <Typography style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                  <Row type="flex" justify="space-between" style={{ flexShrink: 0 }}>
                                    <Col>
                                      <Title level={3} ellipsis={{ rows: 1 }} style={{ width: 200 }}>
                                        {item.name}
                                      </Title>
                                    </Col>
                                    <Col>{item.create_time}</Col>
                                  </Row>
                                  <Paragraph style={{ flexGrow: 1 }} ellipsis={{ rows: 5 }}>
                                    {item.description}
                                  </Paragraph>
                                  <div>
                                    {item.tag_list.length > 0 &&
                                      item.tag_list.map((item, index) => {
                                        return (
                                          <Tag color="magenta" style={{ flexShrink: 0 }}>
                                            {item.name}
                                          </Tag>
                                        );
                                      })}
                                  </div>
                                </Typography>
                              </div>
                            </div>
                          </Space>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </TabPane>
            </Tabs>
          )}
        </Col>
      </Row>
    );
  }
}

export default withRouter(XqnHome);
