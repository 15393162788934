import React from 'react';
import { Button, Input, message, Form, Row, Col, Card, DatePicker, Space } from 'antd';
import { prizeAdd, prizeEdit, prizeDelete } from '@/services/api';
import UploadComponent from '@/pages/components/upload';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { getLocalUserInfo, setPersonalCenterTabIndexAndFresh } from '@/utils/util';

const { TextArea } = Input;

export default class PrizeTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      isDisabled: Number(props.element.buttonType) === 1 ? false : true,
      buttonText: Number(props.element.buttonType) === 1 ? '保存' : '编辑',
      buttonType: props.element.buttonType || 2,
      thumbs: '',
    };
  }

  componentDidMount() {
    const item = this.props.element;
    if (item) {
      this.ref.current.setFieldsValue({
        name: item.name || '',
        newspaper: item.newspaper || '',
        order: item.order || '',
        remark: item.remark || '',
        time: Number(item.buttonType === 1 || 0)
          ? null
          : item.start_time
          ? moment(item.start_time, 'YYYY-MM-DD')
          : null,
      });
    }
  }

  render() {
    const { isDisabled, buttonType, buttonText } = this.state;
    return (
      <Form
        style={{ width: '100%' }}
        ref={this.ref}
        onFinish={(values) => {
          const user = getLocalUserInfo();
          const start_time = moment(values.time).format('YYYY-MM-DD');
          delete values.time;
          const { thumbs } = this.state;

          if (buttonText === '保存') {
            if (Number(buttonType) === 2) {
              //编辑
              const params = { id: this.props.element.id, ...values, start_time, thumbs };
              prizeEdit(params).then(
                (res) => {
                  message.success('保存成功');
                  setPersonalCenterTabIndexAndFresh('52');
                },
                (error) => {}
              );
            } else if (Number(buttonType) === 1) {
              // 新增
              const params = { ...values, user_id: user.id, start_time, thumbs };
              prizeAdd(params).then(
                (res) => {
                  message.success('保存成功');
                  setPersonalCenterTabIndexAndFresh('52');
                },
                (error) => {}
              );
            }
          }
          this.setState({ buttonText: '保存', isDisabled: false });
        }}
      >
        <Row type="flex" justify="space-between">
          <Col span={22}>
            <Card style={{ marginBottom: '20px' }}>
              <Row gutter={24} style={{ marginRight: '16px' }}>
                <Col span={20}>
                  <Form.Item
                    label="开始日期"
                    name="time"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请输入' }]}
                  >
                    <DatePicker locale={locale} disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="题目"
                    name="name"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请输入' }]}
                  >
                    <Input placeholder="请输入题目" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="报刊或出版社"
                    name="newspaper"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请输入' }]}
                  >
                    <Input placeholder="请输入报刊或出版社" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="位次"
                    name="order"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请输入' }]}
                  >
                    <Input placeholder="请输入题目" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="备注"
                    name="remark"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <TextArea rows={4} placeholder="请输入项目描述" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="证书图片"
                    name="thumbs"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                  >
                    <UploadComponent
                      disabled={isDisabled}
                      picUrl={this.props.element.images}
                      callback={(uploadUrl) => {
                        this.setState({ thumbs: uploadUrl });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={2}>
            <Space direction="vertical" size={15} style={{ marginTop: 65, marginLeft: 15 }}>
              <Button type="primary" htmlType="submit">
                {this.state.buttonText}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  prizeDelete({ id: this.props.element.id }).then(
                    (res) => {
                      message.success('删除成功');
                      setPersonalCenterTabIndexAndFresh('52');
                    },
                    (error) => {}
                  );
                }}
              >
                删除
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
