import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { onLineServer, sendToService } from '@/services/api';
import { getLocalUserInfo } from '@/utils/util';
import Chat, { Bubble, useMessages } from '@chatui/core'; // 引入组件
import '@chatui/core/es/styles/index.less';
import '@chatui/core/dist/index.css'; // 引入样式
import './sidebar.css';

const serviceAvatar = '//gw.alicdn.com/tfs/TB1U7FBiAT2gK0jSZPcXXcKkpXa-108-108.jpg';
const initialMessages = [
  {
    type: 'text',
    content: { text: '主人好，我是笑千年智能助理，你的贴心小助手~' },
    user: { avatar: serviceAvatar },
  },
];

const SocketComponent = (props) => {
  // const [webSocket, setWebSocket] = useState({});
  const [adminUserId, setAdminUserId] = useState('');
  const [clientId, setClientId] = useState('');
  const { messages, appendMsg } = useMessages(initialMessages);

  useEffect(() => {
	  const adminId=window.sessionStorage.getItem('socketadminid');
	  if(adminId!=null){
		  collect(adminId)
		  return 
	  }
    onLineServer({ role: 2 }).then(
      (res) => {
        try {
			const resData = res.data || {};
			const adminId = resData.id;
			collect(adminId)
			window.sessionStorage.setItem('socketadminid',adminId);
        } catch (e) {}
      },
      (error) => {}
    );
  }, [props.receiveData]);
  
  
	function collect(adminId){
		console.log(adminId)
		try {
		  const e = props.receiveData;
		  const messageData = JSON.parse(e.data);
		  const msg_type = messageData.msg_type;
		  setAdminUserId(adminId);
		  const cId = messageData.client_id;
		  setClientId(cId);
		  if (msg_type === 'init') {
		    if (adminId && cId) {
		      send(adminId, cId, 'contact', ''); //第一次发送失败和后续发送消息做个区分
		    }
		  } else if (msg_type === 'message') {
		    const content = JSON.parse(e.data).content;
		    appendMsg({
		      type: 'text',
		      content: { text: content || '' },
		      user: { avatar: serviceAvatar },
		    });
		  }
		} catch (e) {}
	}
  function send(admin_user_id, client_id, msg_type, content) {
    try {
      const localUser = getLocalUserInfo();
      let params = {
        client_id,
        admin_user_id,
        msg_type,
        content,
      };
      if (localUser && localUser.id) {
        params = { ...params, user_id: localUser.id };
      }
      sendToService(params).then(
        (res) => {},
        (error) => {}
      );
    } catch (e) {}
  }

  function renderMessageContent(msg) {
    const { content } = msg;
    return <Bubble content={content.text} />;
  }

  function handleSend(type, val) {
    try {
      if (type === 'text' && val.trim()) {
        appendMsg({
          type: 'text',
          content: { text: val },
          position: 'right',
        });
        send(adminUserId, clientId, 'message', val);
      }
    } catch (e) {}
  }

  return (
    <div style={{ width: 400, height: 360 }}>
      <Row style={{ display: 'flex', justifyContent: 'space-around', backgroundColor: '#1890FF' }}>
        <Col span={21} style={{ textAlign: 'left' }}>
          <span style={{ fontSize: '15px', color: 'white', paddingLeft: 5 }}>{props.connectStatusText}</span>
        </Col>
        <Col span={3} style={{ textAlign: 'right' }}>
          <CloseCircleOutlined
            style={{ fontSize: '18px', padding: '5px', color: 'white' }}
            onClick={() => {
              props.onPopoverClose();
            }}
          />
        </Col>
      </Row>
      <Chat messages={messages} renderMessageContent={renderMessageContent} onSend={handleSend} />
    </div>
  );
};

export default SocketComponent;
