import React from 'react';
import { List, Avatar, Pagination } from 'antd';

/**
 * 承接方列表
 */
const UndertakerList = (props) => {
  return (
    <div>
      <List
        loading={props.loading}
        bordered={false}
        dataSource={props.data}
        renderItem={(item) => {
          return (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={<Avatar src={item.avatar || ''} />}
                title={item.username || ''}
                description={item.direction || ''}
              />
            </List.Item>
          );
        }}
      />
      {props.totalCount > 10 && (
        <Pagination
          defaultPageSize={10}
          style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}
          total={props.totalCount}
          onChange={(curPage, count) => {
            this.props.onPageChanged({ page: curPage, limit: count });
          }}
        />
      )}
    </div>
  );
};
// eslint-disable-next-line
export default UndertakerList;
