import React from 'react';
import { Button, Input, message, Form, Row, Col, Card, DatePicker, Space } from 'antd';
import { workExperienceAdd, workExperienceEdit, deleteWorkExperienceById } from '@/services/api';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { getLocalUserInfo, setPersonalCenterTabIndexAndFresh } from '@/utils/util';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

export default class WorkExperienceTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      isDisabled: Number(props.element.buttonType) === 1 ? false : true,
      buttonText: Number(props.element.buttonType) === 1 ? '保存' : '编辑',
      buttonType: props.element.buttonType || 2,
    };
  }

  componentDidMount() {
    const item = this.props.element;
    this.ref.current.setFieldsValue({
      time: Number(item.buttonType === 1)
        ? []
        : [
            item.create_time ? moment(item.create_time, 'YYYY-MM-DD') : [],
            item.end_time ? moment(item.end_time, 'YYYY-MM-DD') : [],
          ],
      company: item.company || '',
      job: item.job || '',
      description: item.description || '',
    });
  }

  render() {
    const { isDisabled, buttonType, buttonText } = this.state;
    return (
      <Form
        style={{ width: '100%' }}
        ref={this.ref}
        onFinish={(values) => {
          const user = getLocalUserInfo();
          let start_time = '';
          let end_time = '';
          if (values.time) {
            start_time = moment(values.time[0]).format('YYYY-MM-DD');
            end_time = moment(values.time[1]).format('YYYY-MM-DD');
            delete values.time;
          }
          let params = { ...values, user_id: user.id, start_time, end_time };
          if (buttonText === '保存') {
            if (Number(buttonType) === 2) {
              //编辑
              params = Object.assign({ id: this.props.element.id }, params); //注assign的source(即params)无需展开
              workExperienceEdit(params).then(
                (res) => {
                  message.success('保存成功');
                  setPersonalCenterTabIndexAndFresh('52');
                },
                (error) => {}
              );
            } else if (Number(buttonType) === 1) {
              //新增
              workExperienceAdd(params).then(
                (res) => {
                  message.success('保存成功');
                  setPersonalCenterTabIndexAndFresh('52');
                },
                (error) => {}
              );
            }
          }
          this.setState({ buttonText: '保存', isDisabled: false });
        }}
      >
        <Row type="flex" justify="space-between">
          <Col span={22}>
            <Card style={{ marginBottom: '20px' }}>
              <Row gutter={24} style={{ marginRight: '16px' }}>
                <Col span={20}>
                  <Form.Item
                    label="开始日期"
                    name="time"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请选择日期' }]}
                  >
                    <RangePicker locale={locale} disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="公司名称"
                    name="company"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <Input placeholder="请输入公司名称" disabled={isDisabled} />
                  </Form.Item>

                  <Form.Item
                    label="职位"
                    name="job"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 32, message: '最多32个字符' },
                    ]}
                  >
                    <Input placeholder="请输入职位" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="描述"
                    name="description"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <TextArea rows={4} placeholder="请输入描述信息" disabled={isDisabled} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={2}>
            <Space direction="vertical" size={15} style={{ marginTop: 65, marginLeft: 15 }}>
              <Button type="primary" htmlType="submit">
                {this.state.buttonText}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  deleteWorkExperienceById({ id: this.props.element.id }).then(
                    (res) => {
                      message.success('删除成功');
                      setPersonalCenterTabIndexAndFresh('52');
                    },
                    (error) => {}
                  );
                }}
              >
                删除
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
