import React from 'react';
import { Row, Col, Card, Form, Button, Input, Checkbox, Select, Modal, Spin, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getLocalUserInfo } from '@/utils/util';
import UploadComponent from '@/pages/components/upload';
import { history } from '@/router/history';
import { companyAuth, getAgreementInfo } from '@/services/api';

const { Option } = Select;
const formItemLayout = {
  style: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 14,
  },
};
export default class CompanyAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, contractVisible: false, contractData: {}, contractLoading: false, isRead: false };
  }

  getUploadButton() {
    const { loading } = this.state;
    return (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传</div>
      </div>
    );
  }

  getAgreementInfo(type) {
    this.setState({ contractLoading: true, contractData: {} }, () => {
      getAgreementInfo({ id: type }).then(
        (res) => {
          this.setState({ contractData: res.data, contractLoading: false });
        },
        (error) => {
          this.setState({ contractLoading: false });
        }
      );
    });
  }

  previewContractByLink() {
    const { contractData, contractLoading } = this.state;
    return (
      <Spin spinning={contractLoading}>
        {contractData && contractData.content && <div dangerouslySetInnerHTML={{ __html: contractData.content }} />}
      </Spin>
    );
  }

  onFinish = (values) => {
    const { isRead } = this.state;
    if (!isRead) {
      message.destroy();
      return message.info('请勾选已阅读并同意');
    }
    const user = getLocalUserInfo();
    const params = {
      ...values,
      user_id: user.id,
    };
    companyAuth(params).then(
      (res) => {
        if (res.code === 200) {
          message.destroy();
          message.success(res.msg);
          history.push('/personalCenter/verifiedAuth');
        } else {
          message.destroy();
          message.warning(res.msg);
        }
      },
      (error) => {}
    );
  };
  render() {
    const { contractVisible } = this.state;
    return (
      <Card style={{ maxHeight: 670, overflowY: 'auto' }}>
        <Form onFinish={this.onFinish}>
          <Row gutter={24} style={{ marginRight: '16px' }}>
            <Col span={20}>
              <Form.Item
                label="企业名称"
                name="name"
                {...formItemLayout}
                rules={[
                  {
                    required: true,
                    message: '请输入营业执照上的单位全称',
                  },
                  { max: 255, message: '最多255个字符' },
                ]}
              >
                <Input placeholder="请输入营业执照上的单位全称" />
              </Form.Item>
              <Form.Item
                label="证照号"
                name="company_no"
                {...formItemLayout}
                rules={[
                  {
                    required: true,
                    message: '请输入统一社会信用代码/其他证件号',
                  },
                  { max: 255, message: '最多255个字符' },
                ]}
              >
                <Input placeholder="请输入统一社会信用代码/其他证件号" />
              </Form.Item>
              <Form.Item
                label="法人姓名"
                name="company_entity"
                {...formItemLayout}
                rules={[
                  {
                    required: true,
                    message: '请输入法人姓名',
                  },
                  { max: 32, message: '最多32个字符' },
                ]}
              >
                <Input placeholder="若为个体工商户，请填写证照所属人姓名/其他证照号" />
              </Form.Item>
              <Form.Item
                label="注册地址"
                name="company_address"
                {...formItemLayout}
                rules={[
                  {
                    required: true,
                  },
                  { max: 255, message: '最多255个字符' },
                ]}
              >
                <Input placeholder="请输入企业/机构/组织的注册地址" />
              </Form.Item>
              <Form.Item
                label="联系人"
                name="contact"
                {...formItemLayout}
                rules={[
                  {
                    required: true,
                    message: '请输入姓名',
                  },
                  { max: 32, message: '最多32个字符' },
                ]}
              >
                <Input placeholder="请输入您的姓名,您将作为企业联系人" />
              </Form.Item>
              <Form.Item
                label="职位"
                name="contact_job"
                {...formItemLayout}
                rules={[
                  {
                    required: true,
                    message: '请输入',
                  },
                  { max: 255, message: '最多255个字符' },
                ]}
              >
                <Input placeholder="请输入您在该公司的职位" />
              </Form.Item>
              <Form.Item
                label="证件类型"
                name="contact_card_type"
                {...formItemLayout}
                rules={[
                  {
                    required: true,
                    message: '请选择证件类型',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="请选择证件类型"
                  optionFilterProp="children"
                  onChange={(e) => {
                    this.setState({ contact_card_type: e });
                  }}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="0">身份证</Option>
                  <Option value="1">护照</Option>
                  <Option value="2">港澳通行证 </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="证件号"
                name="contact_card_number"
                {...formItemLayout}
                rules={[
                  {
                    required: true,
                    message: '请输入证件号',
                  },
                  { max: 58, message: '最多58个字符' },
                ]}
              >
                <Input placeholder="请输入您的证件号" />
              </Form.Item>
              <Form.Item
                label="营业执照图片"
                name="license_photo"
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{
                  span: 14,
                }}
              >
                <div style={{ color: 'grey' }}>1.请上传营业执照原件照片(复印件加盖公章)，确保照片清晰</div>
                <div style={{ marginBottom: '10px', color: 'grey' }}>2.支持jpg、jpeg、bmp、png格式</div>
                <UploadComponent
                  callback={(uploadUrl) => {
                    this.setState({ license_photo: uploadUrl });
                  }}
                />
              </Form.Item>
              <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Form.Item
                  onChange={(e) => {
                    this.setState({ isRead: e.target.checked });
                  }}
                >
                  <Checkbox>
                    已阅读并同意
                    <a
                      onClick={() => {
                        this.setState({ contractVisible: true }, () => {
                          this.getAgreementInfo(5);
                        });
                      }}
                      href="#"
                    >
                      《用户实名认证协议》
                    </a>
                  </Checkbox>
                </Form.Item>
              </Row>
              <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  style={{ marginRight: 20 }}
                  onClick={() => {
                    history.go(-1);
                  }}
                >
                  返回上一步
                </Button>
                <Button type="primary" htmlType="submit">
                  提交审核
                </Button>
              </Row>
            </Col>
            {contractVisible && (
              <Modal
                width={1200}
                bodyStyle={{ height: 800, overflow: 'auto' }}
                closable={false}
                centered
                maskClosable={true}
                onCancel={() => {
                  this.setState({ contractVisible: false });
                }}
                visible={contractVisible}
                footer={null}
                title={null}
              >
                {this.previewContractByLink()}
              </Modal>
            )}
          </Row>
        </Form>
      </Card>
    );
  }
}
