import React from 'react';
import { Button, Input, message, Form, Row, Col, Card, Space } from 'antd';
import { userCertificateAdd, userCertificateEdit, userCertificateDelete } from '@/services/api';
import UploadComponent from '@/pages/components/upload';
import { getLocalUserInfo, setPersonalCenterTabIndexAndFresh } from '@/utils/util';

const { TextArea } = Input;

export default class CertificateTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      isDisabled: Number(props.element.buttonType) === 1 ? false : true,
      buttonText: Number(props.element.buttonType) === 1 ? '保存' : '编辑',
      buttonType: props.element.buttonType || 2,
    };
  }

  componentDidMount() {
    const item = this.props.element;
    if (item) {
      this.ref.current.setFieldsValue({
        name: item.name || '',
        thumbs: item.thumbs || '',
        background: item.background || '',
        remark: item.remark || '',
      });
    }
  }

  render() {
    const { isDisabled, buttonType, buttonText } = this.state;
    return (
      <Form
        style={{ width: '100%' }}
        ref={this.ref}
        onFinish={(values) => {
          const user = getLocalUserInfo();
          let params = { ...values, user_id: user.id, thumbs: this.state.thumbs };
          if (buttonText === '保存') {
            if (Number(buttonType) === 2) {
              //编辑
              params = Object.assign({ id: this.props.element.id }, params); //注assign的source(即params)无需展开
              userCertificateEdit(params).then(
                (res) => {
                  message.success('保存成功');
                  setPersonalCenterTabIndexAndFresh('52');
                },
                (error) => {}
              );
            } else if (Number(buttonType) === 1) {
              //新增
              userCertificateAdd(params).then(
                (res) => {
                  message.success('保存成功');
                  setPersonalCenterTabIndexAndFresh('52');
                },
                (error) => {}
              );
            }
          }
          this.setState({ buttonText: '保存', isDisabled: false });
        }}
      >
        <Row type="flex" justify="space-between">
          <Col span={22}>
            <Card style={{ marginBottom: '20px' }} locale={{ emptyText: '加载中 ...' }}>
              <Row gutter={24} style={{ marginRight: '16px' }}>
                <Col span={20}>
                  <Form.Item
                    label="名称"
                    name="name"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <Input placeholder="请输入项目名称" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="备注"
                    name="remark"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <TextArea rows={4} placeholder="请输入项目描述" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="图片内容"
                    name="thumbs"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                  >
                    <UploadComponent
                      disabled={isDisabled}
                      picUrl={this.props.element.thumbs}
                      callback={(uploadUrl) => {
                        this.setState({ thumbs: uploadUrl });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={2}>
            <Space direction="vertical" size={15} style={{ marginTop: 65, marginLeft: 15 }}>
              <Button type="primary" htmlType="submit">
                {this.state.buttonText}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  userCertificateDelete({ id: this.props.element.id }).then(
                    (res) => {
                      message.success('删除成功');
                      setPersonalCenterTabIndexAndFresh('52');
                    },
                    (error) => {}
                  );
                }}
              >
                删除
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
