import React from 'react';
import { Form, Input, Row, Col } from 'antd';
import { myProject } from '@/services/api';
import { getLocalUserInfo } from '@/utils/util';
import ParticipateProjectTable from '@/pages/components/participateProjectTable';

const PAGE_LIMIT = 10;
const { Search } = Input;

export default class Participate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { list: [], loading: false, totalCount: 0 };
  }

  componentDidMount() {
    this.getMyProjectList();
  }

  getMyProjectList(search = '', pagination = { page: 1, limit: PAGE_LIMIT }) {
    this.setState({ loading: true, search });
    const user = getLocalUserInfo();
    const params = {
      user_id: user.id,
      examine_status: 2,
      search,
      ...pagination,
    };
    myProject(params).then(
      (res) => {
        this.setState({ list: res.data.my_project, totalCount: res.total_count, loading: false });
      },
      (error) => {
        this.setState({ list: [], loading: false });
      }
    );
  }

  render() {
    const { list, loading, totalCount, search } = this.state;
    return (
      <div style={{ height: '0px' }}>
        <Form style={{ textAlign: 'end' }}>
          <Form.Item name="search">
            <Search
              style={{ width: '300px' }}
              placeholder="请输入项目名称"
              allowClear
              enterButton="查询"
              size="default"
              onSearch={(e) => {
                this.getMyProjectList(e);
              }}
            />
          </Form.Item>
        </Form>
        <ParticipateProjectTable
          maxHeight={600}
          dataSource={list}
          loading={loading}
          pagination={{
            defaultPageSize: PAGE_LIMIT,
            total: totalCount,
            showTotal: (total) => `共 ${total} 条`,
            onChange: (curPage, count) => {
              this.getMyProjectList(search, { page: curPage, limit: count });
            },
          }}
        />
      </div>
    );
  }
}
