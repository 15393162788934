import React from 'react';
import { Row, Col, Card, Space, message } from 'antd';
import { history } from '@/router/history';
import { UserOutlined } from '@ant-design/icons';
import { checkCompanyAuth, checkPersonalAuth } from '@/services/api';

export default class VerifiedAuth extends React.Component {
  render() {
    return (
      <Space direction={'vertical'} size={25} style={{ width: '100%' }}>
        <h4>选择身份认证类型</h4>
        <Card
          style={{ height: 200, textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            checkCompanyAuth().then(
              (res) => {
                const status = res.data && Number(res.data.company_auth_status);
                if (status === 0) {
                  message.destroy();
                  return message.info('当前处于待审核状态');
                } else if (status === 1) {
                  message.destroy();
                  return message.info('认证已通过，无需再认证');
                } else {
                  history.push('/personalCenter/companyAuth');
                }
              },
              (error) => {}
            );
          }}
        >
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col>
              <div style={{ display: 'flex', marginTop: 30 }}>
                <UserOutlined style={{ color: '#1890FF', fontSize: 70 }} />
                <div style={{ marginLeft: 20 }}>
                  <h2>企业认证</h2>
                  <div>获得企业认证标识，提高您的可信度</div>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
        <Card
          style={{ height: 200, textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            checkPersonalAuth().then(
              (res) => {
                const status = res.data && Number(res.data.auth_status);
                if (status === 0) {
                  message.destroy();
                  return message.info('当前处于待审核状态');
                } else if (status === 1) {
                  message.destroy();
                  return message.info('认证已通过，无需再认证');
                } else {
                  history.push('/personalCenter/personalAuth');
                }
              },
              (error) => {}
            );
          }}
        >
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col>
              <div style={{ display: 'flex', marginTop: 30 }}>
                <UserOutlined style={{ color: '#1890FF', fontSize: 70 }} />
                <div style={{ marginLeft: 20 }}>
                  <h2>个人认证</h2>
                  <div>获得个人认证标识，提高您的可信度</div>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Space>
    );
  }
}
