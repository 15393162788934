import React from 'react';
import ImgCrop from 'antd-img-crop';
import { Card, Button, Form, Input, Row, Col, Select, DatePicker, message, Spin, Avatar, Space, Upload } from 'antd';
import { queryUserInfo, editUserBasicInfo, editUserInfo, uploadApi } from '@/services/api';
import { getLocalUserInfo, setLocalUserInfo, setPersonalCenterTabIndexAndFresh } from '@/utils/util';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 12,
  },
};
export default class BasePersonInfo extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef(null);
    this.state = { loading: false, isDisabled: true, buttonText: '编辑', buttonType: 1, userAvatar: '' };
  }

  componentDidMount() {
    this.queryUserInfo();
  }

  queryUserInfo() {
    this.setState({ loading: true });
    const user = getLocalUserInfo();
    const params = { id: user.id };
    queryUserInfo(params).then(
      (res) => {
        this.setState({ loading: false });
        const userInfo = res.data;
        if (userInfo) {
          this.ref.current.setFieldsValue({
            nickname: userInfo.nickname || '',
            fullName: userInfo.fullName || '',
            zone: userInfo.zone || '',
            gender: userInfo.gender || '',
            birthday: userInfo.birthday ? moment(userInfo.birthday) : null,
            workStartDate: userInfo.workStartDate ? moment(userInfo.workStartDate, 'YYYY-MM-DD') : null,
            user_introduction: userInfo.user_introduction || '',
            tags: userInfo.tags || '',
            companyName: userInfo.companyName || '',
            study_direction: userInfo.study_direction || '',
            job: userInfo.job || '',
            businessArea: userInfo.businessArea || '',
            goodAt: userInfo.goodAt || '',
            address: userInfo.address || '',
          });
          this.setState({ userAvatar: userInfo.avatar || '' });
        }
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  onSave = () => {
    if (Number(this.state.buttonType) === 2) {
      const fieldsValue = this.ref.current.getFieldsValue();
      const user = getLocalUserInfo();
      const startData = moment(new Date(fieldsValue.workStartDate)).format('YYYY-MM-DD');
      const birthday = moment(new Date(fieldsValue.birthday)).format('YYYY-MM-DD');
      const params = { ...fieldsValue, id: user.id, workStartDate: startData, birthday };
	  if(!params.nickname){
		  message.destroy();
		  return message.info('请输入昵称');
	  }
	  if(!params.fullName){
	  		message.destroy();
	  		return message.info('请输入姓名');
	  }
	  var that=this
      editUserBasicInfo(params).then(
        (res) => {
			message.success(res.msg);
			const params1 = { id: user.id };
			queryUserInfo(params1).then(
			  (res) => {
			    setLocalUserInfo(res.data)
				setPersonalCenterTabIndexAndFresh('51');
			  },
			  (error) => {
			  }
			);
          
        },
        (error) => {}
      );
    }
    this.setState({ buttonText: '保存', buttonType: 2, isDisabled: false });
  };

  queryUserInfoForAvatar() {
    const user = getLocalUserInfo();
    const params = { id: user.id };
    queryUserInfo(params).then(
      (res) => {
        const userInfo = res.data;
        this.setState({ userAvatar: userInfo.avatar || '' });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  beforeUpload = (file) => {
    let isPNG =
      file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp';
    if (!isPNG) {
      message.destroy();
      message.error('请上传图片格式');
      isPNG = false;
    }
    return isPNG || Upload.LIST_IGNORE;
  };

  handleChange = ({ fileList }) => {
    const file = fileList[0];
    if (file) {
      if (file.status !== 'uploading') {
      }
      if (file.status === 'done') {
        const res = file.response.data || {};
        const user = getLocalUserInfo();
        // 覆盖本地存储的user信息中的avatar字段
        user.avatar = res.fileUrl;
        setLocalUserInfo(user);
        editUserInfo({ avatar: res.fileUrl }).then(
          (res) => {
            message.success('修改成功');

            setPersonalCenterTabIndexAndFresh('51');
          },
          (error) => {}
        );
      } else if (file.status === 'error') {
        message.error(`${file.name} 上传失败`);
      }
    }
  };

  render() {
    const { buttonText, isDisabled, loading, userAvatar } = this.state;

    const user = getLocalUserInfo();
    return (
      <Spin spinning={loading}>
        <div style={{ height: '600px', overflowY: 'auto' }}>
          <Form ref={this.ref}>
            <Space direction="horizontal" size={20} style={{ marginBottom: '30px', marginTop: '20px' }}>
              <ImgCrop rotate>
			  <Upload
                className="avatar-uploader"
                showUploadList={false}
                action={uploadApi}
                headers={{ 'X-Requested-With': null, token: getLocalUserInfo().token }}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
              >
                <Avatar gap={10} size={60} src={userAvatar || ''} style={{ cursor: 'pointer' }}>
                  修改头像
                </Avatar>
              </Upload>
			  </ImgCrop>
              <span style={{ color: 'gray' }}>修改头像</span>
            </Space>
            <Row type="flex" justify="space-between" style={{ marginBottom: 6 }}>
              <Col>
                <h3>基本信息</h3>
              </Col>
              <Col>
                {!isDisabled && (
                  <Button
                    type="primary"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      this.setState({ isDisabled: true, buttonType: 1, buttonText: '编辑' });
                    }}
                  >
                    取消
                  </Button>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    this.onSave();
                  }}
                >
                  {buttonText}
                </Button>
              </Col>
            </Row>
            <Card style={{ marginBottom: '20px' }}>
              <Row gutter={24} style={{ marginRight: '16px' }}>
                <Col span={12}>
                  <Form.Item
                    label="昵称"
                    {...formItemLayout}
                    name="nickname"
                    rules={[
                      { required: true, message: '请输入昵称' },
                      { max: 20, message: '最多输入20位' },
                    ]}
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="姓名"
                    {...formItemLayout}
                    name="fullName"
                    rules={[
                      { required: true, message: '请输入姓名' },
                      { max: 36, message: '最多36位' },
                    ]}
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="城市" {...formItemLayout} name="zone">
                    <Input disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="性别" {...formItemLayout} name="gender">
                    <Select disabled={isDisabled} placeholder="请选择性别">
                      <Option value="0">未知</Option>
                      <Option value="1">男</Option>
                      <Option value="2">女</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="生日" {...formItemLayout} name="birthday">
                    <DatePicker locale={locale} disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="开始工作时间" {...formItemLayout} name="workStartDate">
                    <DatePicker locale={locale} disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={20} className="cancle">
                  <Form.Item
                    name="address"
                    label="详细地址"
                    labelCol={{
                      span: 4,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    rules={[{ max: 255, message: '最多255个字符' }]}
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="个人简介"
                    name="user_introduction"
                    labelCol={{
                      span: 4,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    rules={[
                      // { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <TextArea rows={4} placeholder="请输入" maxLength={200} disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="个人标签"
                    name="tags"
                    labelCol={{
                      span: 4,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    rules={[
                      // { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Row type="flex" justify="space-between">
              <Col>
                <h3>专业信息</h3>
              </Col>
              <Col></Col>
            </Row>
            <Card style={{ marginBottom: '20px' }}>
              <Row gutter={24} style={{ marginRight: '16px' }}>
                <Col span={12}>
                  <Form.Item
                    label="公司"
                    {...formItemLayout}
                    name="companyName"
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="研究方向" {...formItemLayout} name="study_direction">
                    <Input disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="职位"
                    {...formItemLayout}
                    name="job"
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 32, message: '最多32个字符' },
                    ]}
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="业务领域"
                    {...formItemLayout}
                    name="businessArea"
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="擅长专业"
                    {...formItemLayout}
                    name="goodAt"
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </div>
      </Spin>
    );
  }
}
