import React from 'react';
import { Layout, Menu, Card, Tabs,Row,Col } from 'antd';
import {
  BankOutlined,
  UserOutlined,
  LoadingOutlined,
  PlusOutlined,
  SendOutlined,
  PoundOutlined,
  SafetyOutlined,
  ProfileOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import XqnFooter from '@/component/Footer/xqnFooter';
import XqnTop from '@/component/Header/xqnTop';
import { Outlet } from 'react-router-dom';
import { history } from '@/router/history';
import { getLocalUserInfo, getPersonalCenterTabIndex, cleanTabIndex, clearLocalUserInfo,formatPhone } from '@/utils/util';
import logoSrc from '@/assets/images/browserLogo.png';

const { TabPane } = Tabs;
const { Content, Sider } = Layout;

const workPlatformMenu = [
  {
    label: '我发布的需求',
    key: 1,
    icon: <SendOutlined />,
  },
  {
    label: '我的项目',
    key: 2,
    icon: <BankOutlined />,
    children: [
      { key: 21, label: `我报名的项目` },
      { key: 22, label: `我参与的项目` },
      { key: 23, label: `我收藏的项目` },
    ],
  },
  {
    label: '服务商',
    key: 3,
    icon: <UserOutlined />,
    children: [{ key: 31, label: `选择入驻` }],
  },
  {
    label: '交易订单',
    key: 4,
    icon: <PoundOutlined />,
    children: [
      // { key: 31, label: `我的交易` },
      { key: 41, label: `我的合同` },
      // { key: 33, label: `我的发票` },
    ],
  },
];

const settingMenu = [
  {
    label: '我的信息',
    key: 5,
    icon: <ProfileOutlined />,
    children: [
      { key: 51, label: `基本信息` },
      // { key: 52, label: `资历信息` },
    ],
  },
  {
    label: '认证管理',
    key: 6,
    icon: <IdcardOutlined />,
    children: [{ key: 61, label: `实名认证` }],
  },
  {
    label: '帐号安全',
    key: 7,
    icon: <SafetyOutlined />,
    children: [{ key: 71, label: `帐号安全` }],
  },
];

export default class XqnPersonalCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuData: workPlatformMenu,
      activeTabIndex: '1',
      currentSelectedMenuKeys: '0',
      loading: false,
      isLogin: false,
    };
  }

  componentDidMount() {
    this.initBrowserTitle();
    const user = getLocalUserInfo();
	console.log(user)
    if (user && user.id && user.id !== '') {
		var mobile=''
		if(user.mobile){
			mobile=formatPhone(user.mobile)
		}
      this.setState({ isLogin: true, userAvatar: user.avatar, userName: user.nickname || mobile });
    }
    /**
     * Tab跳转处理
     */
    const currentSelectedMenuKeys = getPersonalCenterTabIndex();
    if (currentSelectedMenuKeys === '51') {
      this.setState({ menuData: settingMenu, currentSelectedMenuKeys, activeTabIndex: '2' }, () => {
        history.push('/personalCenter/basePersonalInfo');
      });
    } else if (currentSelectedMenuKeys === '52') {
      this.setState({ menuData: settingMenu, currentSelectedMenuKeys, activeTabIndex: '2' }, () => {
        history.push('/personalCenter/seniorityInfo');
      });
    }
  }

  componentWillUnmount() {
    cleanTabIndex();
  }

  initBrowserTitle() {
    document.title = '笑千年';
    const links = document.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      const temp = link.rel.toLowerCase();
      if (temp.indexOf('icon') > -1) {
        link.href = logoSrc;
        break;
      }
    }
  }

  onMenuClick = (e) => {
    switch (e.key) {
      case '1':
        history.push('/personalCenter/myPublish');
        break;
      case '21':
        history.push('/personalCenter/myProject');
        break;
      case '22':
        history.push('/personalCenter/participate');
        break;
      case '23':
        history.push('/personalCenter/attention');
        break;
      case '31':
        history.push('/personalCenter/settled');
        break;
      case '41':
        history.push('/personalCenter/myContract');
        break;
      case '51':
        history.push('/personalCenter/basePersonalInfo');
        break;
      case '52':
        history.push('/personalCenter/seniorityInfo');
        break;
      case '61':
        history.push('/personalCenter/verifiedAuth');
        break;
      case '71':
        history.push('/personalCenter/accountSecurity');
        break;
      default:
    }
    this.setState({ currentSelectedMenuKeys: e.key });
  };

  onTabChanges = (key) => {
    this.setState({ activeTabIndex: key });
    if (key === '1') {
      this.setState({ menuData: workPlatformMenu, currentSelectedMenuKeys: '0' }, () => {
        history.push('/personalCenter');
      });
    } else if (key === '2') {
      this.setState({ menuData: settingMenu, currentSelectedMenuKeys: '41' }, () => {
        history.push('/personalCenter/basePersonalInfo');
      });
    }
  };

  getUploadButton() {
    const { loading } = this.state;
    return (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传</div>
      </div>
    );
  }

  /**
   * 个人中心退出登录
   */
  logout() {
    clearLocalUserInfo();
    this.setState({ isLogin: false, userName: '', userId: '' }, () => {
      history.push('/');
      window.location.reload();
    });
  }

  render() {
    const { userName, userAvatar, isLogin } = this.state;
	console.log(this.state)
    return (
      <Layout>
        <XqnTop
          userName={userName}
          userAvatar={userAvatar}
          hasLogo={true}
          hasLogin={isLogin}
          logout={() => {
            this.logout();
          }}
        />
        <Content className="setwidth"
          style={{
            maxWidth:'83.33333333%',
			margin:'0 auto',
			minWidth:1400,
          }}
        >
		<Row>
		<Col span={24}>
          <Layout
            style={{
              padding: '24px 0',
              height: '800px',
            }}
          >
            <Card bordered={false}>
              <Sider className="site-layout-background" width={200}>
                <Menu
                  mode="inline"
                  selectedKeys={[this.state.currentSelectedMenuKeys]}
                  defaultOpenKeys={['1', '2', '3', '4', '5', '6', '7']}
                  style={{ height: '100%' }}
                  items={this.state.menuData}
                  onClick={this.onMenuClick}
                />
              </Sider>
            </Card>
            <Content className="cancle"
              style={{
                padding: '0 24px',
                minHeight: 280,
                backgroundColor: '#FFFFFF',
              }}
            >
              <Tabs onChange={this.onTabChanges} activeKey={this.state.activeTabIndex}>
                <TabPane tab="控制中心" key="1">
                  <Content className="cancle" style={{ paddingBottom: '24px' }}>
                    <Outlet />
                  </Content>
                </TabPane>
                <TabPane tab="个人设置" key="2">
                  <Content className="cancle" style={{ paddingBottom: '24px' }}>
                    <Outlet />
                  </Content>
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
		  </Col>
		  </Row>
        </Content>
        <XqnFooter />
      </Layout>
    );
  }
}
