import React from 'react';
import { List, Pagination, Typography, Row, Col } from 'antd';
import { projectStatus } from '@/utils/util';

const { Title, Paragraph } = Typography;
/**
 * 项目分布构成
 */
const SubProjectList = (props) => {
  return (
    <div>
      <List
        loading={props.loading}
        bordered={false}
        dataSource={props.data}
        renderItem={(item) => {
          return (
            <div style={{ display: 'flex', borderBottom: '1px solid #F5F5F5', paddingBottom: 20 }}>
              <img src={item.son_img_url} alt="" style={{ width: '240px', height: '150px', objectFit: 'cover' }} />
              <div style={{ marginLeft: 20, flex: 1 }}>
                <span>{`项目分部 ( 项目号 : ${item.son_project_code || '-'} )`}</span>
                <Row style={{ marginTop: 10 }}>
                  <Col span={8}>{`昵称 : ${item.nickname || ''}`}</Col>
                  <Col span={8}>{`项目状态 : ${projectStatus[item.is_status] || ''}`}</Col>
                  <Col span={8}>{`创建时间 : ${item.created_time || ''}`}</Col>
                </Row>
                <Typography>
                  <Title>{item.project_name}</Title>
                  <Paragraph>{item.son_project_information}</Paragraph>
                </Typography>
              </div>
            </div>
          );
        }}
      />
      {props.totalCount > 10 && (
        <Pagination
          defaultPageSize={10}
          style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}
          total={props.totalCount}
          onChange={(curPage, count) => {
            this.props.onPageChanged({ page: curPage, limit: count });
          }}
        />
      )}
    </div>
  );
};
// eslint-disable-next-line
export default SubProjectList;
