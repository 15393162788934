import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';

export default class AttentionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, search: props.search || '' };
  }

  columns = [
    {
      title: '项目名称',
      dataIndex: 'project_name',
      key: 'project_name',
    },
    {
      title: '项目编号',
      dataIndex: 'project_code',
      key: 'project_code',
    },
    {
      title: '操作',
      dataIndex: '1',
      fixed: 'right',
      width: 100,
      render: (text, item) => {
        return (
          <Link
            to={`/acceptDetail?id=${item.project_id}&project_status=${item.project_status}`}
            state={{ curNavIndex: 'accept' }}
          >
            查看
          </Link>
        );
      },
    },
  ];

  render() {
    const { dataSource, loading, pagination, maxHeight } = this.props;
    return (
      <Table
        style={{ maxHeight: maxHeight, overflow: 'auto' }}
        dataSource={dataSource}
        columns={this.columns}
        bordered={true}
        pagination={pagination}
        loading={loading}
        locale={{ emptyText: dataSource && dataSource.length === 0 && '暂无数据' }}
      />
    );
  }
}
