import React from 'react';
import { Upload, Modal, message } from 'antd';
import { uploadApi } from '@/services/api';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getLocalUserInfo } from '@/utils/util';

export default class UploadComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: props.picUrl
        ? [
            {
              uid: '-1',
              name: '',
              status: 'done',
              url: props.picUrl,
            },
          ]
        : [],
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
    };
  }

  componentDidMount() {}

  beforeUpload = (file) => {
    let isPNG =
      file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp';
    if (!isPNG) {
      message.destroy();
      message.error('此文件格式不支持上传');
      isPNG = false;
    }
    return isPNG || Upload.LIST_IGNORE;
  };

  handlePreview = (file) => {
    // if (!file.url && !file.preview) {
    //   file.preview = this.getBase64(file.preview);
    // }
    this.setState({
      // previewImage: file.url || file.preview,
      previewImage: file.thumbUrl,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ fileList }) => {
    this.setState({ fileList }, () => {
      const file = fileList[0];
      if (file) {
        if (file.status !== 'uploading') {
          // console.log(info.file, info.fileList);
        }
        if (file.status === 'done') {
          message.success('上传成功');
          const res = file.response.data || {};
          this.props.callback(res.fileUrl);
        } else if (file.status === 'error') {
          message.error(`${file.name} 上传失败`);
        }
      }
    });
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  getUploadButton() {
    const { loading } = this.state;
    return (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传</div>
      </div>
    );
  }

  render() {
    const { fileList, previewVisible, previewImage, previewTitle } = this.state;
    return (
      <div>
        <Upload
          disabled={this.props.disabled}
          action={uploadApi}
          listType="picture-card"
          fileList={fileList}
          headers={{ 'X-Requested-With': null, token: getLocalUserInfo().token }}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          beforeUpload={this.beforeUpload}
        >
          {fileList.length >= 1 ? null : this.getUploadButton()}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => {
            this.setState({ previewVisible: false });
          }}
        >
          <img alt="example" style={{ width: '100%', height: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}
