import React from 'react';
import { Row, Col, Form, Button, Input, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getLocalUserInfo } from '@/utils/util';
import { userAuth } from '@/services/api';
import { history } from '@/router/history';
import UploadComponent from '@/pages/components/upload';

const { TextArea } = Input;
export default class ServiceProviderAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, file_url: '' };
  }
  getUploadButton() {
    const { loading } = this.state;
    return (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传</div>
      </div>
    );
  }

  render() {
    return (
      <Form
        onFinish={(values) => {
          const user = getLocalUserInfo();
          const { file_url } = this.state;
          if (file_url === '' || file_url === null) {
            message.destroy();
            return message.warning('请上传文件后再提交');
          }
          const params = {
            ...values,
            type: 1,
            user_id: user.id,
            file_url: JSON.stringify([{ file_url }]),
          };
          userAuth(params).then(
            (res) => {
              if (res.code === 200) {
                message.destroy();
                message.success(res.msg);
                history.push('/personalCenter/settled');
              } else {
                message.destroy();
                message.warning(res.msg);
              }
            },
            (error) => {}
          );
        }}
      >
        <Row gutter={24} style={{ marginRight: '16px' }}>
          <Col span={20}>
            <Row
              style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '30px' }}
              gutter={20}
            >
              <h2>服务商入驻</h2>
            </Row>

            <Form.Item
              label="名称"
              name="name"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 16,
              }}
              rules={[
                { required: true, message: '请输入名称' },
                { max: 32, message: '最多输入32位' },
                { min: 1, message: '名称不能少于1位' },
              ]}
            >
              <Input placeholder="单行输入" />
            </Form.Item>
            <Form.Item
              label="联系方式"
              name="contact"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 16,
              }}
              rules={[
                { required: true, message: '请输入联系方式' },
                { max: 16, message: '最多输入16位' },
                { min: 1, message: '不能少于1个字符' },
              ]}
            >
              <Input placeholder="单行输入" />
            </Form.Item>
            <Form.Item
              label="能力描述"
              name="user_description"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 16,
              }}
              rules={[
                { required: true, message: '请输入能力描述' },
                { max: 500, message: '最多输入500个字符' },
                { min: 1, message: '不能少于1个字符' },
              ]}
            >
              <TextArea rows={5} placeholder="请输入描述信息" maxLength={500} />
            </Form.Item>
            <Form.Item
              label="证明文件"
              name="file_url"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 16,
              }}
            >
              <UploadComponent
                callback={(uploadUrl) => {
                  this.setState({ file_url: uploadUrl });
                }}
              />
            </Form.Item>
            <Row style={{ display: 'flex', justifyContent: 'center' }} gutter={20}>
              <Col>
                <Button
                  onClick={() => {
                    history.go(-1);
                  }}
                >
                  取消
                </Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}
