import React from 'react';
import { Row, Col, Card, Button, Avatar, Popover } from 'antd';
import { Link } from 'react-router-dom';
import logoSrc from '@/assets/images/logo.png';
import avatarSrc from '@/assets/images/userAvatar.png';

export default class XqnTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  popoverContent() {
    return (
      <div style={{ width: '100%', paddingTop: 20, paddingBottom: 20 }}>
        <Link to="/personalCenter" reloadDocument={true} style={{ width: '50%', float: 'left', textAlign: 'center' }}>
          <span>个人中心</span>
        </Link>
        <Link to="#" onClick={this.props.logout} style={{ width: '50%', textAlign: 'center' }}>
          <span>退出登录</span>
        </Link>
      </div>
    );
  }

  render() {
    const { hasLogo, userAvatar, userName, hasLogin } = this.props;
	const url='/login?preurl='+window.location.href
    return (
      <Card bodyStyle={{ padding:0,paddingTop: 10, paddingBottom: 10 }} bordered={false}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Col span={20} style={{minWidth:1400}}>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {hasLogo && (
                <Col span={12}>
                  <Link to="/">
                    <img src={logoSrc} alt="" style={{ width: '150px' }} />
                  </Link>
                </Col>
              )}
              <Col
                span={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {hasLogin ? (
                  <div>
                    <Popover placement="topRight" title={userName} content={this.popoverContent()} trigger="hover">
                      <Avatar size={60} src={userAvatar || avatarSrc} />
                    </Popover>
                  </div>
                ) : (
                  <div>
                    <Link to={url}>
                      <span style={{ marginRight: '10px' }}>登录</span>
                    </Link>
                    <Link to="/register">
                      <Button type="primary" style={{ width: 100, borderRadius: 50 }}>
                        注册
                      </Button>
                    </Link>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  }
}
