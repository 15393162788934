import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { projectContract } from '@/services/api';
import { getLocalUserInfo } from '@/utils/util';

const PAGE_LIMIT = 10;
const columns = [
  {
    title: '项目名称',
    dataIndex: 'project_name',
    key: 'project_name',
  },
  {
    title: '项目内容',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: '提交日期',
    dataIndex: 'created_time',
    key: 'created_time',
  },
  {
    title: '操作',
    dataIndex: '1',
    fixed: 'right',
    width: 100,
    render: (text, item) => (
      <a target="_blank" href={`${item.contract_url && item.contract_url.length > 0 && item.contract_url[0]}`}>
        查看
      </a>
    ),
  },
];

export default class MyContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, totalCount: 0, list: [] };
  }

  componentDidMount() {
    this.getProjectContract();
  }

  getProjectContract(pagination = { page: 1, limit: PAGE_LIMIT }) {
    this.setState({ loading: true });
    const user = getLocalUserInfo();
    const params = {
      user_id: user.id,
      examine_status: 1,
      ...pagination,
    };
    projectContract(params).then(
      (res) => {
        this.setState({ list: res.data.project_contract, totalCount: res.total_count, loading: false });
      },
      (error) => {
        this.setState({ list: [], loading: false });
      }
    );
  }

  callback(key) {
    console.log(key);
  }

  render() {
    const { list, loading, totalCount } = this.state;
    return (
      <Table
        dataSource={list}
        columns={columns}
        bordered={true}
        loading={loading}
        locale={{ emptyText: list.length === 0 && '暂无数据' }}
        pagination={{
          defaultPageSize: PAGE_LIMIT,
          total: totalCount,
          showTotal: (total) => `共 ${total} 条`,
          onChange: (curPage, count) => {
            this.getProjectContract({ page: curPage, limit: count });
          },
        }}
      />
    );
  }
}
