import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { HomeOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { getExampleCategoryList } from '@/services/api';
const Nav = (props) => {
  // const [exampleData, setExampleData] = useState();
  // useEffect(() => {
  //   getExampleCategoryList().then(
  //     (res) => {
  //       setExampleData(res.data);
  //     },
  //     (error) => {
  //       setExampleData([]);
  //     }
  //   );
  // }, []);

  return (
    <Menu mode="horizontal" selectedKeys={[props.current]} onClick={props.menuItemClick}>
      <Menu.Item key="index" style={{ width: '7%', textAlign: 'center' }}>
        <Link to="/">
          <HomeOutlined />
          &nbsp; 首页
        </Link>
      </Menu.Item>
      <Menu.SubMenu
        title="发布 / 承接需求"
        icon={<MailOutlined />}
        key="projectOperation"
        style={{ width: '12%', textAlign: 'center' }}
      >
        <Menu.Item key="publish">
          <Link to="/publish" state={{ curNavIndex: 'projectOperation' }}>
            发布需求
          </Link>
        </Menu.Item>
        <Menu.Item key="accept">
          <Link to="/accept" state={{ curNavIndex: 'projectOperation' }}>
            承接需求
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item title="成功案例" key="projectCase" style={{ width: '9%', textAlign: 'center' }}>
        <Link to={`/resourceList`} state={{ curNavIndex: 'projectCase' }}>
          成功案例
        </Link>
      </Menu.Item>
      {/* <Menu.SubMenu title="成功案例" icon={<MailOutlined />} key="projectCase">
        {exampleData &&
          exampleData.length > 0 &&
          exampleData.map((item, index) => {
            return (
              <Menu.Item key={item.id}>
                <Link to={`/resourceList?id=${item.id}`} state={{ curNavIndex: 'projectCase' }}>
                  {item.name}
                </Link>
              </Menu.Item>
            );
          })}
      </Menu.SubMenu> */}
      <Menu.Item key="cloudPlatform" style={{ width: '9%', textAlign: 'center' }}>
        <Link to="/cloudEntrance" state={{ curNavIndex: 'cloudPlatform' }}>
          云设计平台
        </Link>
      </Menu.Item>
      {/* <Menu.SubMenu
        title="云设计平台"
        icon={<MailOutlined />}
        key="cloudDesignPlatform"
        style={{ width: '11%', textAlign: 'center' }}
      >
        <Menu.Item key="cloudPlatform">
          <Link to={'/cloudEntrance'} state={{ curNavIndex: 'cloudDesignPlatform' }}>
            云设计平台入口
          </Link>
        </Menu.Item>
        <Menu.Item key="cloudPlatformIntroduce">
          <Link to="#" state={{ curNavIndex: 'cloudDesignPlatform' }}>
            什么是云设计平台
          </Link>
        </Menu.Item>
      </Menu.SubMenu> */}
      <Menu.Item key="industryMap" style={{ width: '9%', textAlign: 'center' }}>
        <Link to="/industryMap" state={{ curNavIndex: 'industryMap' }}>
          产业地图
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="communityInfo">
        <Link to="#">社区资讯</Link>
      </Menu.Item>
      <Menu.Item key="partnerRecruit">
        <Link to="#">合伙人招募</Link>
      </Menu.Item> */}
      <Menu.Item key="aboutUs" style={{ width: '9%', textAlign: 'center' }}>
        <Link to="/aboutUs" state={{ curNavIndex: 'aboutUs' }}>
          关于我们
        </Link>
      </Menu.Item>
      {/* <Menu.SubMenu title="关于我们" icon={<UserOutlined />}>
        <Menu.Item key="aboutUs">
          <Link to="#">关于我们</Link>
        </Menu.Item>
        <Menu.Item key="platformAbility">
          <Link to="#">平台能力</Link>
        </Menu.Item>
        <Menu.Item key="platformNews">
          <Link to="#">平台新闻</Link>
        </Menu.Item>
        <Menu.Item key="industryReport">
          <Link to="#">产业报告</Link>
        </Menu.Item>
      </Menu.SubMenu> */}
    </Menu>
  );
};

export default Nav;
