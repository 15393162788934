import React from 'react';
import { Button, Input, message } from 'antd';
import { getIdentifyingCode } from '@/services/api';

export default class SmsCodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 60, // 倒计时
      codeText: '获取验证码',
      isDisabled: false,
      bizid: '',
    };
  }

  render() {
    return (
      <div>
        <Input.Group compact>
          <Input
            style={{ width: 'calc(100% - 136px)' }}
            placeholder="请输入验证码"
            onChange={(e) => {
              this.setState({ smsCode: e.target.value });
              this.props.onSmsChanged(e.target.value);
            }}
          />
          <Button
            type="primary"
            disabled={this.state.isDisabled}
            onClick={() => {
              const { mobile } = this.props;
              if (mobile) {
                getIdentifyingCode({ mobile }).then(
                  (res) => {
                    this.props.callback(res.data.BizId);
                    const timer = setInterval(() => {
                      this.setState(
                        {
                          count: this.state.count - 1,
                          // codeText: `倒计时${this.state.count}s`,
                          codeText: `${this.state.count}s后重新获取`,
                          isDisabled: true,
                        },
                        () => {
                          if (this.state.count === 0) {
                            clearInterval(timer);
                            this.setState({
                              isDisabled: false,
                              codeText: '重新获取',
                              count: 60,
                            });
                          }
                        }
                      );
                    }, 1000);
                  },
                  (error) => {
                    console.log('error = ', error);
                  }
                );
              } else {
                message.error('请输入手机号');
              }
            }}
          >
            {this.state.codeText}
          </Button>
        </Input.Group>
      </div>
    );
  }
}
