import { Descriptions, Card, Spin } from 'antd';
import React from 'react';
import { projectInfo } from '@/services/designPlatformApi';
import { getUrlParams } from '@/utils/util';
import { getDesignLocalUserInfo, getDesignUserRole, enterprisesRole, projectStatus } from '@/utils/util';

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
    };
  }

  componentWillReceiveProps() {
    this.loadData();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true });
    const id = getUrlParams('id');
    const project_status = getUrlParams('project_status');
    const user = getDesignLocalUserInfo();
    const params = {
      id,
      project_status,
      user_id: user.id,
      user_role: getDesignUserRole(),
      page: 1,
      limit: 100,
    };
    projectInfo(params).then(
      (res) => {
        this.setState({ loading: false, data: res.data || {} });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  render() {
    const { data, loading } = this.state;
    return (
      <Spin spinning={loading}>
        <Card style={{ marginBottom: '20px' }}>
          <Descriptions column={2}>
            <Descriptions.Item label="项目编号">{(data && data.project_code) || ''}</Descriptions.Item>
            <Descriptions.Item label="项目名称">{(data && data.project_name) || ''}</Descriptions.Item>
            <Descriptions.Item label="所属企业">{(data && enterprisesRole[data.enterprises]) || ''}</Descriptions.Item>
            <Descriptions.Item label="项目期限">
              {(data && data.start_time) || ''} ～ {(data && data.end_time) || ''}
            </Descriptions.Item>
            <Descriptions.Item label="项目预算(单位:元)">{data && data.ways_cooperation}</Descriptions.Item>
            <Descriptions.Item label="描述">{(data && data.project_desc) || ''}</Descriptions.Item>
            <Descriptions.Item label="项目阶段">{data && projectStatus[data.is_status]}</Descriptions.Item>
            <Descriptions.Item label="持有者(项目经理)">{(data && data.nickname) || ''}</Descriptions.Item>
            <Descriptions.Item label="更新时间">{(data && data.updated_time) || ''}</Descriptions.Item>
            <Descriptions.Item label="创建时间">{(data && data.created_time) || ''}</Descriptions.Item>
          </Descriptions>
        </Card>
      </Spin>
    );
  }
}

export default Detail;
