import React from 'react';
import { Button, Input, message, Form, Row, Col, Card, DatePicker, Space } from 'antd';
import { educationAdd, educationEdit, deleteEducationById } from '@/services/api';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { getLocalUserInfo, setPersonalCenterTabIndexAndFresh } from '@/utils/util';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

export default class EducationTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      isDisabled: Number(props.element.buttonType) === 1 ? false : true,
      buttonText: Number(props.element.buttonType) === 1 ? '保存' : '编辑',
      buttonType: props.element.buttonType || 2,
    };
  }

  componentDidMount() {
    const item = this.props.element;
    if (item) {
      this.ref.current.setFieldsValue({
        schoolName: item.schoolName || '',
        education_date: Number(item.buttonType === 1)
          ? []
          : [
              item.create_time ? moment(item.create_time, 'YYYY-MM-DD') : [],
              item.endTime ? moment(item.endTime, 'YYYY-MM-DD') : [],
            ],
        profession: item.profession || '',
        background: item.background || '',
        remark: item.remark || '',
      });
    }
  }

  render() {
    const { isDisabled, buttonType, buttonText } = this.state;
    return (
      <Form
        style={{ width: '100%' }}
        ref={this.ref}
        onFinish={(values) => {
          const user = getLocalUserInfo();
          const startTime = moment(values.education_date[0]).format('YYYY-MM-DD');
          const endTime = moment(values.education_date[1]).format('YYYY-MM-DD');
          delete values.education_date;
          let params = { ...values, user_id: user.id, startTime, endTime };
          if (buttonText === '保存') {
            if (Number(buttonType) === 2) {
              //编辑
              params = Object.assign({ id: this.props.element.id }, params); //注assign的source(即params)无需展开
              educationEdit(params).then(
                (res) => {
                  message.success('保存成功');
                  setPersonalCenterTabIndexAndFresh('52');
                },
                (error) => {}
              );
            } else if (Number(buttonType) === 1) {
              //新增
              educationAdd(params).then(
                (res) => {
                  message.success('保存成功');
                  setPersonalCenterTabIndexAndFresh('52');
                },
                (error) => {}
              );
            }
          }
          this.setState({ buttonText: '保存', isDisabled: false });
        }}
      >
        <Row type="flex" justify="space-between">
          <Col span={22}>
            <Card style={{ marginBottom: '20px' }}>
              <Row gutter={24} style={{ marginRight: '16px' }}>
                <Col span={20}>
                  <Form.Item
                    label="学校"
                    name="schoolName"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <Input placeholder="请输入学校" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="时间"
                    name="education_date"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[{ required: true, message: '请选择' }]}
                  >
                    <RangePicker locale={locale} disabled={isDisabled} value={null} />
                  </Form.Item>
                  <Form.Item
                    label="专业"
                    name="profession"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <Input placeholder="请输入专业" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="学历"
                    name="background"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <Input placeholder="请输入学历" disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    label="备注"
                    name="remark"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                    rules={[
                      { required: true, message: '请输入' },
                      { max: 255, message: '最多255个字符' },
                    ]}
                  >
                    <TextArea rows={4} placeholder="请输入备注" disabled={isDisabled} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={2}>
            <Space direction="vertical" size={15} style={{ marginTop: 65, marginLeft: 15 }}>
              <Button type="primary" htmlType="submit">
                {this.state.buttonText}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  deleteEducationById({ id: this.props.element.id }).then(
                    (res) => {
                      message.success('删除成功');
                      setPersonalCenterTabIndexAndFresh('52');
                    },
                    (error) => {}
                  );
                }}
              >
                删除
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
