import { Table } from 'antd';
import React from 'react';
import { projectLog } from '@/services/designPlatformApi';
import { getUrlParams, getDesignUserRole } from '@/utils/util';

const PAGE_LIMIT = 10;

const logColumns = [
  {
    title: '操作活动',
    dataIndex: 'operation',
  },
  {
    title: '操作时间',
    dataIndex: 'created_time',
  },
  {
    title: '描述',
    dataIndex: 'describe',
  },
];

class ProjectLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      totalCount: 0,
    };
  }

  componentWillReceiveProps() {
    this.loadData();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(pagination = { page: 1, limit: PAGE_LIMIT }) {
    this.setState({ loading: true });
    const id = getUrlParams('id');
    const project_status = getUrlParams('project_status');
    const params = {
      project_id: id,
      user_role: getDesignUserRole(),
      project_status,
      ...pagination,
    };
    projectLog(params).then(
      (res) => {
        this.setState({ loading: false, data: res.data || [], totalCount: res.total_count });
      },
      (error) => {
        this.setState({ loading: false, data: [] });
      }
    );
  }

  render() {
    const { data, loading, totalCount } = this.state;
    return (
      <Table
        columns={logColumns}
        dataSource={data}
        loading={loading}
        pagination={{
          defaultPageSize: PAGE_LIMIT,
          total: totalCount,
          showTotal: (total) => `共 ${total} 条`,
          onChange: (curPage, count) => {
            this.loadData({ page: curPage, limit: count });
          },
        }}
      />
    );
  }
}

export default ProjectLog;
