import React from 'react';
import { Row, Col, Input, Form, Button, Card, message, Checkbox, Modal, Spin } from 'antd';
import { publish, getIdentifyingCode, getAgreementInfo } from '@/services/api';
import { getLocalUserInfo } from '@/utils/util';
import { history } from '@/router/history';
const { TextArea } = Input;

export default class Publish extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef(null);
    this.state = {
      mobile: '',
      bizid: '',
      count: 60, // 倒计时
      codeText: '获取验证码',
      isDisabled: false,
      contractVisible: false,
      contractData: {},
      loading: false,
      isRead: false,
    };
  }

  componentWillMount() {}

  onFinish = (values) => {
    if (!this.state.isRead) {
      message.destroy();
      return message.info('请勾选已阅读并同意');
    }
    const { bizid } = this.state;
    const user = getLocalUserInfo();
    const params = {
      ...values,
      user_id: (user && user.id) || '',
      bizid,
    };
    publish(params).then(
      (res) => {
        if (res.code === 200) {
          message.success(res.msg);
          this.ref.current.resetFields();
          history.push('/');
        } else {
          message.success(res.msg);
        }
      },
      (error) => {
        console.log('error = ', error);
      }
    );
  };

  getAgreementInfo(type) {
    this.setState({ loading: true, contractData: {} }, () => {
      getAgreementInfo({ id: type }).then(
        (res) => {
          this.setState({ contractData: res.data, loading: false });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    });
  }

  previewContractByLink() {
    const { contractData, loading } = this.state;
    return (
      <Spin spinning={loading}>
        <div dangerouslySetInnerHTML={{ __html: contractData.content }} />
      </Spin>
    );
  }

  render() {
    const { contractVisible } = this.state;
    return (
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col span={20}>
          <Card bordered={false}>
            <h3 style={{ marginLeft: '55px', marginBottom: '20px' }}>提交需求</h3>
            <Form
              ref={this.ref}
              onFinish={this.onFinish}
              name="basic"
              labelCol={{
                span: 2,
              }}
              wrapperCol={{
                span: 20,
              }}
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
            >
              <Form.Item
                label="我需要"
                name="content"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 255, message: '最多255个字符' },
                ]}
              >
                <TextArea showCount style={{ height: 220 }} placeholder="请用一句话表达你的需求" />
              </Form.Item>
              <Form.Item
                label="手机号"
                name="mobile"
                labelCol={{
                  span: 2,
                }}
                wrapperCol={{
                  span: 10,
                }}
                rules={[
                  { required: true, message: '请输入11手机号' },
                  { pattern: /^1\d{10}$/, message: '手机号格式错误！' },
                ]}
                onChange={(e) => {
                  this.setState({ mobile: e.target.value });
                }}
              >
                <Input placeholder="请输入手机号" />
              </Form.Item>
              <Form.Item
                label="验证码"
                labelCol={{
                  span: 2,
                }}
                wrapperCol={{
                  span: 10,
                }}
                rules={[
                  { required: true, message: '请输入验证码' },
                  { pattern: /^[0-9]{4,4}$/, message: '请输入4位有效数字' },
                ]}
              >
                <Input.Group compact>
                  <Form.Item
                    name="sms_code"
                    rules={[
                      {
                        required: true,
                        message: '请输入验证码',
                      },
                      { max: 4, message: '请输入4位有效数字' },
                    ]}
                  >
                    <Input placeholder="请输入验证码" />
                  </Form.Item>
                  <Button
                    disabled={this.state.isDisabled}
                    type="primary"
                    onClick={() => {
                      const { mobile } = this.state;
                      if (mobile) {
                        getIdentifyingCode({ mobile }).then(
                          (res) => {
                            this.setState({
                              bizid: res.data.BizId,
                            });
                            const timer = setInterval(() => {
                              this.setState(
                                {
                                  count: this.state.count - 1,
                                  codeText: `${this.state.count}s后重新获取`,
                                  isDisabled: true,
                                },
                                () => {
                                  if (this.state.count === 0) {
                                    clearInterval(timer);
                                    this.setState({
                                      isDisabled: false,
                                      codeText: '重新获取',
                                      count: 60,
                                    });
                                  }
                                }
                              );
                            }, 1000);
                          },
                          (error) => {
                            console.log('error = ', error);
                          }
                        );
                      } else {
                        message.error('请输入手机号');
                      }
                    }}
                  >
                    {this.state.codeText}
                  </Button>
                </Input.Group>
              </Form.Item>
              <Form.Item
                labelCol={{
                  span: 2,
                }}
                wrapperCol={{
                  offset: 2,
                  span: 10,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({ isRead: e.target.checked });
                  }}
                >
                  已阅读并同意
                  <a
                    onClick={() => {
                      this.setState({ contractVisible: true }, () => {
                        this.getAgreementInfo(6);
                      });
                    }}
                    href="#"
                  >
                    《用户协议》
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ contractVisible: true }, () => {
                        this.getAgreementInfo(3);
                      });
                    }}
                    href="#"
                  >
                    《隐私协议》
                  </a>
                </Checkbox>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 10,
                  span: 10,
                }}
              >
                <Button type="primary" htmlType="submit">
                  立即发布需求
                </Button>
              </Form.Item>
            </Form>
          </Card>
          {contractVisible && (
            <Modal
              width={1200}
              bodyStyle={{ height: 800, overflow: 'auto' }}
              closable={false}
              centered
              maskClosable={true}
              onCancel={() => {
                this.setState({ contractVisible: false });
              }}
              visible={contractVisible}
              footer={null}
              title={null}
            >
              {this.previewContractByLink()}
            </Modal>
          )}
        </Col>
      </Row>
    );
  }
}
