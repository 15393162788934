import React from 'react';
import { Row, Col, Tabs, Carousel, Card, Breadcrumb, Menu, Radio, Typography } from 'antd';
import { withRouter } from '@/router/withRouter';
import { getProjectList, projectCategory, projectClassification } from '@/services/api';
import { getUrlParams } from '@/utils/util';
import { history } from '@/router/history';
import banner from '@/assets/images/banner.png';
import ProjectList from './projectList';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;
const projectStatus = { '1': '主项目', '2': '子项目' };

class Accept extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      currentSubCategoryMap: {},
      classificationList: [],
      currentSubClassificationMap: [],
      projectList: [],
      totalCount: 0,
      loading: false,
      search_key: '',
      accessKey: '',
    };
  }

  /**
   * 路由跳转到同一页面, 数据不同时接收数据变化
   */
  componentWillReceiveProps(nextProps) {
    this.getProjectList();
  }

  componentDidMount() {
    this.projectCategory();
    this.projectClassification();
    this.getProjectList();
  }

  getProjectList(values) {
    this.setState({ loading: true }, () => {
      const params = {
        search_key: getUrlParams('keyWord') || '',
        son_category_one_id: '',
        son_category_two_id: '',
        son_industry_one_id: '',
        son_industry_two_id: '',
        cooperation_status: 0,
        sort_status: 0,
        page: 1,
        limit: 10,
        ...values,
      };
      getProjectList({ ...params }).then(
        (res) => {
          this.setState({
            projectList: res.data.project_info || [],
            totalCount: res.total_count,
            loading: false,
          });
        },
        (error) => {
          this.setState({ projectList: [], loading: false });
        }
      );
    });
  }

  projectCategory() {
    projectCategory().then(
      (res) => {
        const data = [{ id: '0', name: '全部' }].concat(res.data.project_category) || [];
        this.setState({ categoryList: data }, () => {});
      },
      (error) => {}
    );
  }

  requestSubProjectCategory(category_id) {
    projectCategory({ category_id }).then(
      (res) => {
        const data = res.data.project_category || [];
        const arr = [];
        data.forEach((e, i) => {
          arr.push({
            key: String(e.id),
            label: e.name,
          });
        });
        const map = new Map();
        map.set(category_id, arr);
        this.setState({ currentSubCategoryMap: map });
      },
      (error) => {}
    );
  }

  projectClassification() {
    projectClassification().then(
      (res) => {
        this.setState({
          classificationList: [{ id: '0', name: '全部' }].concat(res.data.project_classification) || [],
        });
      },
      (error) => {}
    );
  }

  requestSubClassification(industry_id) {
    projectClassification({ industry_id }).then(
      (res) => {
        const data = res.data.project_classification || [];
        const arr = [];
        data.forEach((e, i) => {
          arr.push({
            key: String(e.id),
            label: e.name,
          });
        });
        const map = new Map();
        map.set(industry_id, arr);
        this.setState({ currentSubClassificationMap: map });
      },
      (error) => {}
    );
  }

  /**
   * 重置搜索条件
   */
  reset() {
    const params = {
      son_category_one_id: '',
      son_category_two_id: '',
      son_industry_one_id: '',
      son_industry_two_id: '',
      cooperation_status: 0,
      sort_status: 0,
    };
    getProjectList({ ...params }).then(
      (res) => {
        this.setState({ projectList: res.data.project_info || [] });
      },
      (error) => {}
    );
  }

  onPageChanged = (pagination) => {
    const {
      son_category_one_id,
      son_category_two_id,
      son_industry_one_id,
      son_industry_two_id,
      cooperation_status,
      sort_status,
    } = this.state;
    const p = {
      ...pagination,
      search_key: getUrlParams('keyWord') || '',
      son_category_one_id: son_category_one_id || '',
      son_category_two_id: son_category_two_id || '',
      son_industry_one_id: son_industry_one_id || '',
      son_industry_two_id: son_industry_two_id || '',
      cooperation_status: cooperation_status || 0,
      sort_status: sort_status || 0,
    };
    this.getProjectList(p);
  };

  getProjectItem = (item) => {
    return (
      <Card
        bodyStyle={{ borderBottom: '1px solid #F5F5F5' }}
        bordered={false}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          this.props.router.navigate(`/acceptDetail?id=${item.id}&project_status=${item.project_status}`, {
            state: { curNavIndex: 'accept' },
          });
        }}
      >
        <div style={{ display: 'flex' }}>
          <img src={item.img_url} alt="" style={{ width: '240px', height: '150px', objectFit: 'cover' }} />
          <div style={{ flex: 1, marginLeft: 20 }}>
            <Typography style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <div style={{ display: 'flex', flexShrink: 0 }}>
                <Title level={3}>{item.project_name}</Title>
                <span
                  style={{
                    marginLeft: 10,
                    backgroundColor: 'red',
                    color: 'white',
                    padding: 5,
                    height: 33,
                    borderRadius: '8px 2px 8px 2px',
                  }}
                >
                  {projectStatus[item.project_status]}
                </span>
              </div>
              <Paragraph style={{ flexGrow: 1 }} ellipsis={{ rows: 5 }}>
                {item.introduction}
              </Paragraph>
              <Row style={{ flexShrink: 0 }}>
                <Col span={8}>
                  招募人数: <span style={{ color: '#1890FF' }}>{item.number_people || ''}</span>
                </Col>
                <Col span={8}>
                  招募金额: <span style={{ color: '#1890FF' }}>{item.ways_cooperation || ''}</span>
                </Col>
                <Col span={8}>
                  截止时间: <span style={{ color: '#1890FF' }}>{item.created_time || ''}</span>
                </Col>
              </Row>
            </Typography>
          </div>
        </div>
      </Card>
    );
  };

  render() {
    const {
      projectList,
      totalCount,
      loading,
      categoryList,
      currentSubCategoryMap,
      classificationList,
      currentSubClassificationMap,
    } = this.state;
    return (
      <Row style={{justifyContent:'center'}}>
        <Col span={20} style={{justifyContent:'center'}}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => {
                history.go(-1);
              }}
            >
              项目
            </Breadcrumb.Item>
            <Breadcrumb.Item>承接</Breadcrumb.Item>
          </Breadcrumb>
          <Row className="top_news">
            <Col span={20}>
              <div className="top_left">
                <Carousel autoplay>
                  <img src={banner} alt="" />
                </Carousel>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={20} style={{ backgroundColor: 'white', paddingLeft: 25, paddingTop: 10 }}>
              <h4>提交需求</h4>
            </Col>
          </Row>
          {categoryList.length > 0 && (
            <Row>
              <Col span={20} style={{ backgroundColor: 'white', paddingLeft: 25 }}>
                <Row>
                  <Col style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span>产业:</span>
                  </Col>
                  <Col>
                    <Menu mode="horizontal" style={{ borderBottom: 'none' }}>
                      {categoryList.map((item, index) => {
                        return (
                          <Menu.SubMenu
                            title={item.name}
                            mode="vertical"
                            onTitleClick={() => {
                              if (Number(item.id) === 0) {
                                this.reset();
                              }
                            }}
                            onTitleMouseEnter={() => {
                              if (Number(item.id) === 0) {
                                return;
                              }
                              this.requestSubProjectCategory(item.id);
                            }}
                          >
                            {currentSubCategoryMap.size > 0 && (
                              <Menu
                                onClick={(e) => {
                                  const { cooperation_status } = this.state;
                                  this.getProjectList({
                                    son_category_one_id: item.id || '',
                                    son_category_two_id: e.key,
                                    cooperation_status: cooperation_status || 0,
                                  });
                                }}
                                items={currentSubCategoryMap.get(item.id)}
                              />
                            )}
                          </Menu.SubMenu>
                        );
                      })}
                    </Menu>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {classificationList.length > 0 && (
            <Row>
              <Col span={20} style={{ backgroundColor: 'white', paddingLeft: 25 }}>
                <Row>
                  <Col style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span>行业:</span>
                  </Col>
                  <Col>
                    <Menu mode="horizontal" onClick={this.props.menuItemClick} style={{ borderBottom: 'none' }}>
                      {classificationList.map((item, index) => {
                        return (
                          <Menu.SubMenu
                            title={item.name}
                            mode="vertical"
                            onTitleClick={() => {
                              if (Number(item.id) === 0) {
                                this.reset();
                              }
                            }}
                            onTitleMouseEnter={() => {
                              if (Number(item.id) === 0) {
                                return;
                              }
                              this.requestSubClassification(item.id);
                            }}
                          >
                            {currentSubClassificationMap.size > 0 && (
                              <Menu
                                onClick={(e) => {
                                  const { cooperation_status } = this.state;
                                  this.getProjectList({
                                    son_industry_one_id: item.id,
                                    son_industry_two_id: e.key,
                                    cooperation_status: cooperation_status || 0,
                                  });
                                }}
                                items={currentSubClassificationMap.get(item.id)}
                              />
                            )}
                          </Menu.SubMenu>
                        );
                      })}
                    </Menu>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={20} style={{ backgroundColor: 'white', paddingLeft: 25 }}>
              <span style={{ marginRight: '20px' }}>交易模式:</span>
              <Radio.Group
                defaultValue={0}
                style={{ marginBottom: 20, marginTop: 10 }}
                onChange={(e) => {
                  this.setState(
                    {
                      cooperation_status: e.target.value,
                    },
                    () => {
                      const { son_category_one_id, son_industry_one_id } = this.state;
                      this.getProjectList({
                        son_category_one_id: son_category_one_id || '',
                        son_industry_one_id: son_industry_one_id || '',
                        cooperation_status: e.target.value,
                      });
                    }
                  );
                }}
              >
                <Radio value={0}>全部</Radio>
                <Radio value={1}>纯佣</Radio>
                <Radio value={2}>佣+红</Radio>
                <Radio value={3}>分红</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row>
            <Col span={20} style={{ backgroundColor: 'white', paddingLeft: 25 }}>
              <Tabs
                accessKey={this.state.accessKey}
                onChange={(e) => {
                  this.setState({ accessKey: e }, () => {
                    this.getProjectList({ sort_status: e });
                  });
                }}
              >
                <TabPane tab="综合排序" key="0" forceRender={true}>
                  <Card bordered={false} style={{ minHeight: 300 }} bodyStyle={{ padding: 0 }}>
                    {projectList.length > 0 && (
                      <ProjectList
                        data={projectList}
                        totalCount={totalCount}
                        loading={loading}
                        onPageChanged={(pagination) => {
                          this.onPageChanged(pagination);
                        }}
                      />
                    )}
                  </Card>
                </TabPane>
                <TabPane tab="最高预算" key="1">
                  <Card bordered={false} style={{ minHeight: 300 }} bodyStyle={{ padding: 0 }}>
                    {this.state.projectList.length > 0 && (
                      <ProjectList
                        data={projectList}
                        totalCount={totalCount}
                        loading={loading}
                        onPageChanged={(pagination) => {
                          this.onPageChanged(pagination);
                        }}
                      />
                    )}
                  </Card>
                </TabPane>
                <TabPane tab="人气最高" key="2">
                  <Card bordered={false} style={{ minHeight: 300 }} bodyStyle={{ padding: 0 }}>
                    {this.state.projectList.length > 0 && (
                      <ProjectList
                        data={projectList}
                        totalCount={totalCount}
                        loading={loading}
                        onPageChanged={(pagination) => {
                          this.onPageChanged(pagination);
                        }}
                      />
                    )}
                  </Card>
                </TabPane>
                <TabPane tab="最新上线" key="3">
                  <Card bordered={false} style={{ minHeight: 300 }} bodyStyle={{ padding: 0 }}>
                    {this.state.projectList.length > 0 && (
                      <ProjectList
                        data={projectList}
                        totalCount={totalCount}
                        loading={loading}
                        onPageChanged={(pagination) => {
                          this.onPageChanged(pagination);
                        }}
                      />
                    )}
                  </Card>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withRouter(Accept);
