import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';

const examineStatusMapping = { '1': '待审核', '2': '审核通过', '3': '拒绝' };

const columns = [
  {
    title: '提交日期',
    dataIndex: 'created_time',
    key: 'created_time',
  },
  {
    title: '项目名称',
    dataIndex: 'project_name',
    key: 'project_name',
  },
  {
    title: '项目编号',
    dataIndex: 'project_code',
    key: 'project_code',
  },
  {
    title: '报名状态',
    dataIndex: 'examine_status',
    key: 'examine_status',
    render: (text, item) => {
      return examineStatusMapping[text];
    },
  },
  {
    title: '操作',
    dataIndex: '1',
    fixed: 'right',
    width: 100,
    render: (text, item) => (
      <Link
        to={`/acceptDetail?id=${item.project_id}&project_status=${item.project_status}`}
        state={{ curNavIndex: 'accept' }}
      >
        查看
      </Link>
    ),
  },
];

export default class MyProjectTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { search: props.search || '' };
  }

  render() {
    const { dataSource, loading, pagination, maxHeight } = this.props;
    return (
      <Table
        style={{ maxHeight: maxHeight, overflow: 'auto' }}
        dataSource={dataSource}
        columns={columns}
        bordered={true}
        pagination={pagination}
        loading={loading}
        locale={{ emptyText: dataSource && dataSource.length === 0 && '暂无数据' }}
      />
    );
  }
}
