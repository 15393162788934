import React from 'react';
import { Row, Col, Typography, Card, Carousel } from 'antd';
import { withRouter } from '@/router/withRouter';
import { getAboutInfo } from '@/services/api';
import banner from '@/assets/images/aboutBg.jpeg';

const { Title } = Typography;
const { Meta } = Card;
class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    this.getAboutInfo();
  }

  getAboutInfo() {
    getAboutInfo({ id: 9 }).then(
      (res) => {
        this.setState({ data: res.data.single_page });
      },
      (error) => {}
    );
  }

  getAboutUsTemplate() {
    const { data } = this.state;
    return (
      <div>
        <Carousel>
          <img src={banner} alt="" />
        </Carousel>
        <div style={{ marginLeft: 150, marginRight: 150, marginTop: 50, marginBottom: 30 }}>
          <Row gutter={30}>
            <Col span={8}>
              <Card
                bodyStyle={{ paddingBottom: 20 }}
                cover={
                  <div style={{ textAlign: 'center' }}>
                    <img src={require('@/assets/images/vision.png')} alt="" style={{ padding: 10 }} />
                    <h2>愿景</h2>
                    <div style={{ border: '0.5px solid #D0E6FE', marginLeft: 24, marginRight: 24 }} />
                  </div>
                }
              >
                <Meta description="引领协同生态，奉献智慧产品缔造美好生活!" style={{ height: 50 }} />
              </Card>
            </Col>
            <Col span={8}>
              <Card
                bodyStyle={{ paddingBottom: 20 }}
                cover={
                  <div style={{ textAlign: 'center' }}>
                    <img src={require('@/assets/images/mission.png')} alt="" style={{ padding: 10 }} />
                    <h2>使命</h2>
                    <div style={{ border: '0.5px solid #D0E6FE', marginLeft: 24, marginRight: 24 }} />
                  </div>
                }
              >
                <Meta description="让天下没有难研发的产品实现人们的创造梦想!" style={{ height: 50 }} />
              </Card>
            </Col>
            <Col span={8}>
              <Card
                bodyStyle={{ paddingBottom: 20 }}
                cover={
                  <div style={{ textAlign: 'center' }}>
                    <img src={require('@/assets/images/worth.png')} alt="" style={{ padding: 10 }} />
                    <h2>核心价值观</h2>
                    <div style={{ border: '0.5px solid #D0E6FE', marginLeft: 24, marginRight: 24 }} />
                  </div>
                }
              >
                <Meta description="以客户为中心，以奋斗者为本我，自我批判，勇争第一!" style={{ height: 50 }} />
              </Card>
            </Col>
          </Row>
        </div>
        <div
          style={{
            backgroundColor: '#1890FF',
            marginLeft: 150,
            marginRight: 150,
            marginBottom: 80,
            paddingBottom: 50,
          }}
        >
          <Title style={{ textAlign: 'center', paddingTop: 20, fontSize: 20, color: 'white' }}>{'笑千年简介'}</Title>
          <div
            dangerouslySetInnerHTML={{ __html: data.content }}
            style={{ paddingLeft: 100, paddingRight: 100, color: 'white' }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <Row style={{justifyContent:'center'}}>
        <Col span={20} style={{ backgroundColor: 'white', maxHeight: 1200, overflow: 'auto' }}>
          {this.getAboutUsTemplate()}
        </Col>
      </Row>
    );
  }
}

export default withRouter(AboutUs);
