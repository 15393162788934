import React from 'react';
import { Row, Col, Card, Space, message } from 'antd';
import { history } from '@/router/history';
import { checkUser, settledDetailInfo } from '@/services/api';
import { getLocalUserInfo } from '@/utils/util';
import { UserOutlined } from '@ant-design/icons';

const ENTER_ROLE = { DESIGNER: 0, PROVIDER: 1 };
export default class Settled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      designerStatusText: '',
      serviceProviderStatusText: '',
    };
  }

  componentDidMount() {
    this.fetchProviderStatus();
    this.fetchDesignerStatus();
  }

  fetchProviderStatus() {
    settledDetailInfo({ type: ENTER_ROLE.PROVIDER }).then(
      (res) => {
        if (res.data) {
			this.setState({ serviceProviderStatusNum: res.data.enter_status});
          if (Number(res.data.enter_status) === 0) {
            this.setState({ serviceProviderStatusText: '当前处于待审核状态' });
          } else if (Number(res.data.enter_status) === 1) {
            this.setState({ serviceProviderStatusText: '入驻已通过，无需再入驻' });
          }
        }
      },
      (error) => {}
    );
  }

  fetchDesignerStatus() {
    settledDetailInfo({ type: ENTER_ROLE.DESIGNER }).then(
      (res) => {
        if (res.data) {
			this.setState({ designerStatusNum: res.data.enter_status});
          if (Number(res.data.enter_status) === 0) {
            this.setState({ designerStatusText: '当前处于待审核状态' });
          } else if (res.data.enter_status === 1) {
            this.setState({ designerStatusText: '入驻已通过，无需再入驻' });
          }
        }
      },
      (error) => {}
    );
  }

  handleClick(type,num) {
    const user = getLocalUserInfo();
    const params = {
      user_id: user.id,
    };
    checkUser(params).then(
      (res) => {
        if (res.data && Number(res.data.is_auth) === 1) {
          if (type === ENTER_ROLE.PROVIDER&&num!==0&&num!==1) {
            history.push('/personalCenter/serviceProviderAuth');
          } else if (type === ENTER_ROLE.DESIGNER&&num!==0&&num!==1) {
            history.push('/personalCenter/designerAuth');
          }
        } else {
          message.destroy();
          message.info('请先认证');
        }
      },
      (error) => {}
    );
  }

  render() {
    const { designerStatusText, serviceProviderStatusText,serviceProviderStatusNum,designerStatusNum } = this.state;
    return (
      <Space direction={'vertical'} size={25} style={{ width: '100%' }}>
        <h4>选择入驻类型</h4>
        <Card
          style={{ height: 200, textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            this.handleClick(ENTER_ROLE.PROVIDER,serviceProviderStatusNum);
          }}
        >
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col>
              <div style={{ display: 'flex', marginTop: 30 }}>
                <UserOutlined style={{ color: '#1890FF', fontSize: 60 }} />
                <div style={{ marginLeft: 20 }}>
                  {serviceProviderStatusText && (
                    <span style={{ fontSize: 20, color: 'red', marginBottom: 10, fontWeight: 'bold' }}>
                      {serviceProviderStatusText}
                    </span>
                  )}
                  <h2>服务商入驻</h2>
                  <div>获得服务商能力入驻标识,提高您的可信度</div>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
        <Card
          style={{ height: 200, textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            this.handleClick(ENTER_ROLE.DESIGNER,designerStatusNum);
          }}
        >
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col>
              <div style={{ display: 'flex', marginTop: 30 }}>
                <UserOutlined style={{ color: '#1890FF', fontSize: 60 }} />
                <div style={{ marginLeft: 20 }}>
                  {designerStatusText && (
                    <span style={{ fontSize: 20, color: 'red', marginBottom: 10, fontWeight: 'bold' }}>
                      {designerStatusText}
                    </span>
                  )}
                  <h2>设计师入驻</h2>
                  <div>获得设计师能力入驻标识,提高您的可信度</div>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Space>
    );
  }
}
