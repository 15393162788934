import React from 'react';
import XqnHeader from '@/component/Header/xqnHeader';
import XqnFooter from '@/component/Footer/xqnFooter';
import XqnSideBar from '@/component/SideBar/xqnSideBar';
import { Layout } from 'antd';
import logoSrc from '@/assets/images/browserLogo.png';
import { withRouter } from '@/router/withRouter';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

class XqnRoot extends React.Component {
  componentDidMount() {
    this.initBrowserTitle();
  }

  initBrowserTitle() {
    document.title = '笑千年';
    const links = document.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      const temp = link.rel.toLowerCase();
      if (temp.indexOf('icon') > -1) {
        link.href = logoSrc;
        break;
      }
    }
  }
  render() {
    return (
      <Layout className="layout">
        <XqnHeader />
        <Content style={{ paddingBottom: '24px' }}>
          {/* {this.props.children} */}
          <Outlet />
        </Content>
        <XqnSideBar />
        <XqnFooter />
      </Layout>
    );
  }
}

export default withRouter(XqnRoot);
